export function strpLifeHabitsAndBehavior(life_habits_and_behavior = null) {
    return {
        _id: life_habits_and_behavior?._id,
        person: life_habits_and_behavior?.person,
        attendance: life_habits_and_behavior?.attendance,
        smokes: life_habits_and_behavior?.smokes || 'NOT_EVALUATED',
        exposure_to_secondhand_smoke: life_habits_and_behavior?.exposure_to_secondhand_smoke || 'NOT_EVALUATED',
        use_of_illicit_drugs: life_habits_and_behavior?.use_of_illicit_drugs || 'NOT_EVALUATED',
        physical_activity: life_habits_and_behavior?.physical_activity || 'NOT_EVALUATED',
        vaccinations_up_to_date: life_habits_and_behavior?.vaccinations_up_to_date || 'NOT_EVALUATED',
        consumption_of_alcoholic_beverages_frequency: life_habits_and_behavior?.consumption_of_alcoholic_beverages_frequency || 'NOT_EVALUATED',
        active: true,
    }
}

export function parseLifeHabitsAndBehavior(life_habits_and_behavior) {
    return {
        _id: life_habits_and_behavior?._id,
        person: life_habits_and_behavior?.person?.id,
        attendance: life_habits_and_behavior?.attendance,
        smokes: life_habits_and_behavior?.smokes || 'NOT_EVALUATED',
        exposure_to_secondhand_smoke: life_habits_and_behavior?.exposure_to_secondhand_smoke || 'NOT_EVALUATED',
        use_of_illicit_drugs: life_habits_and_behavior?.use_of_illicit_drugs || 'NOT_EVALUATED',
        physical_activity: life_habits_and_behavior?.physical_activity || 'NOT_EVALUATED',
        vaccinations_up_to_date: life_habits_and_behavior?.vaccinations_up_to_date || 'NOT_EVALUATED',
        consumption_of_alcoholic_beverages_frequency: life_habits_and_behavior?.consumption_of_alcoholic_beverages_frequency || 'NOT_EVALUATED',
        active: true
    }
}