import './PersonalDataAddress.css'
import React, { useState } from 'react'
import InfoLine from '../../../../../../../component/info-line/info-line'
import {MaskItemCep,} from '../../../../../../../component'
import NewButton, {ButtonModel} from '../../../../../../../newcomponents/button/button'

import { useConfiguration } from '../../../../../configuration'
import PersonalDataAddressModal from './PersonalDataAddressModal/PersonalDataAddressModal'
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField'
import InfoOutlinedIcon from '../../../../../../../component/icon/info-outlined'

export default function PersonalDataAddress() {
    const { setModalProps, addressData } = useConfiguration()
    const [showTooltip, setShowTooltip] = useState(false);

	return (
		<section>
            <div className='PersonalDataAddress-Header'>
                <span className='PersonalDataAddress-Header-Icon'>E</span>
                <p className='PersonalDataAddress-Header-Title'>Endereço</p>
            </div>
			<div className='PersonalDataAddress-info-content'>
                <HorizontalField
                    label='CEP'
                    content={MaskItemCep(addressData?.zip || '')}
                />
                <HorizontalField
                    label='Endereço'
                    content={ addressData.address || '-' }
                />
                <HorizontalField
                    label='Número'
                    content={addressData.number || '-'}
                />
                <HorizontalField
                    label='Bairro'
                    content={addressData.district || '-'}
                />
                <HorizontalField
                    label='Complemento'
                    content={addressData.complement || '-'}
                />
                <HorizontalField
                    label='Cidade'
                    content={addressData.city?.name || '-'}
                />
                <HorizontalField
                    label='Estado'
                    content={addressData.city?.state || '-'}
                />
			</div>
			<div className='PersonalDataAddress-Edit'>
				<div
                className='PersonalDataAddress-Edit-Btn'
					onClick={() =>
						setModalProps(prev => ({
							...prev,
							open: true,
							title: 'Endereço',
							content: <PersonalDataAddressModal />,
							dismissText: '',
						}))
					}
				>
                <b>Editar dados</b>
                </div>
                <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} className='info-toolTip-box'>
                        <InfoOutlinedIcon style={{'width': 20, 'height': 20}}/>
                        {showTooltip && (
                            <div className='info-toolTip'>
                                O cadastro do endereço é uma informação necessária para acesso aos documentos de pacientes e receituário digital. 
                            </div>
                        )}
                </span>
			</div>
		</section>
	)
}