import React from 'react'

import './list.css'

export default function List({columns, rows}) {
    return (
        <div className='list'>
            <div className='list-headers'>
                {columns.map((column, i) => {
                    return <div key={`header-${i}`}
                        className='header'
                        style={{width: column.size ? column.size : '100%'}}
                    >
                        {column.header}
                    </div>
                })}
            </div>
            <div className='list-rows'>
                {
                    rows.map((row, i) => {
                        return <div className='list-row' key={`line-${i}`}>
                        {
                            row.map((value, j) => {
                                return <div key={`cell-${i}${j}`}
                                    className='list-value'
                                    style={{width: columns[j].size ? columns[j].size : '100%'}}
                                >
                                    {value}
                                </div>
                            })
                        }
                    </div>})
                }
            </div>
        </div>
    )
}