import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, IcoSearch, Input, MaskItemCep, Select } from "../../../../../../../../../../../../component"
import { listaCidades, loaded, loading } from '../../../../../../../../../../../../layout/redux/AppActions'
import ActionForm from '../../../../../../../../../../../../component/form/actions/actions'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../newcomponents/modal/modal'
import api from "../../../../../../../../../../../../helpers/api"
import Button from "../../../../../../../../../../../../newcomponents/button/button"

function strpPerson(person={}) {

    return {
        "id": person?.id || null,
        "address": {
            id: person?.address?.id || null, 
            zip: person?.address?.zip || '', 
            address: person?.address?.address || '', 
            number: person?.address?.number || '', 
            complement: person?.address?.complement || '', 
            district: person?.address?.district || '',
            city: person?.address?.city || {}
        }
    }
}

export default function EditDoctorAddress({ setModalInfo=() => null, doctorId=null, fetchExtraAction=async () => null }) {
    const dispatch = useDispatch()
    const [editingDoctorAddress, setEditingDoctorAddress] = useState(strpPerson(doctorId))
    const [dataErrors, setDataErrors] = useState({})
    const { listas } = useSelector(state => state.appState)

    useEffect(()=> {
        dispatch(listaCidades())
        // eslint-disable-next-line
    }, [])

    const getCEP = zipcode => {
        dispatch(loading())
        api.get(`address/cep/${zipcode}/`)
        .then(res => {
            dispatch(loaded())
            setEditingDoctorAddress(prev => ({ ...prev, "address": { ...res.data, number: '', complement: '' } }))
            validateData({ ...res.data, number: '', complement: '' })
        })
        .catch(() => {
            dispatch([loaded(), AddAlert('Consulta de CEP', 'CEP não encontrado', AlertType.ERROR)])
            setEditingDoctorAddress(prev => ({ ...prev, "address": { "id": "", "zip": "", "address": "", "number": "", "complement": "", "district": "", "city": {}} }))
        })
    }

    const validateData = (data) => {
        const errors = {}
        const keys = ['zip', 'address', 'number', 'district', 'city']
        keys.forEach(key => {
            let isEmptyObject = Object.keys(data[key]).length === 0
            if (data[key] === '' || data[key] === undefined || isEmptyObject) errors[key] = 'vazio'
            if (key === 'city') {
                for (var prop in data['city']) {
                    if (data['city'][prop] === '' || data['city'][prop] ===  undefined) {
                        errors['city'] = 'vazio'
                        return
                    }
                }
            }
        });
        setDataErrors(errors)
        return Object.keys(errors).length > 0
    }

    const handleChangeInfo = (event) => {
        validateData({...editingDoctorAddress?.address, [event.target.name]: event.target.value})
        setEditingDoctorAddress(prev => ({ ...prev, address: { ...prev.address, [event.target.name]: event.target.value } }))
    }
    
    const handleSubmit = async () => {
        const thereIsError = validateData(editingDoctorAddress?.address)
        if (thereIsError) return null

        const payload = {...editingDoctorAddress?.address, city: editingDoctorAddress?.address.city.id, zip: editingDoctorAddress?.address?.zip.replace(/\D/g, '')}

        console.log(payload)
        try {
            dispatch(loading())
            await api.post('health_place_user/registration/user/create_address/', payload)
                .then(() => dispatch(AddAlert('Endereço profissional', 'Endereço adicionado com sucesso', AlertType.SUCCESS)))
                .catch(() => AddAlert('Endereço profissional', 'Ocorreu um erro ao adicionar o endereço, tente novamente em instantes', AlertType.ERROR))

            dispatch(loaded())
            await fetchExtraAction()                
            setModalInfo(MODAL_INIT_PROPS)

        } catch (err) {
            console.error(err)
            dispatch([loaded(), AddAlert('Endereço profissional', 'Ocorreu um erro ao tentar salvar o endereço, tente novamente em instantes', AlertType.ERROR)])
        }
    }

    return <div className='EditAddress'>
        <div className='EditAddress-PersonalInfo'>
            <div className='EditAddress-Subtitle-Header'>
                <div className='EditAddress-Subtitle-Header-Icon EditAddress-Subtitle-Header-Icon-Blue'>E</div>
                <div className='EditAddress-Subtitle-Header-Title'>Endereço</div>
            </div>
        </div>
        <div className='EditPatient-AddressInfo-4FR'>
            <div className='EditAddress-Input'>
                <span><b className='EditAddress-Asterisk'>*</b> CEP</span>
                <Input 
                    name='zip' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={MaskItemCep(editingDoctorAddress?.address?.zip)}
                    maxLength='9'
                    required={{erro: dataErrors, message: ''}}
                >
                    <ActionForm action={() => getCEP(editingDoctorAddress?.address?.zip)} title='Pesquisar'><IcoSearch /></ActionForm>
                </Input>
            </div>
            <div className='EditAddress-Select'>
                <span><b className='EditAddress-Asterisk'>*</b> Cidade</span>
                <Select
                    name='city'
                    action={handleChangeInfo}
                    options={listas.cidades}
                    selected={editingDoctorAddress?.address?.city}
                    required={{erro: dataErrors, message: ''}}
                />
            </div>
            <div className='EditAddress-Input'>
                <span>UF</span>
                <Input 
                    name='uf' 
                    disabled={true} 
                    value={editingDoctorAddress?.address?.city?.state}
                    required={{erro: dataErrors, message: ''}}
                />
            </div>
            <div className='EditAddress-Input'>
                <span><b className='EditAddress-Asterisk'>*</b> Bairro</span>
                <Input 
                    name='district' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={editingDoctorAddress?.address?.district}
                    required={{erro: dataErrors, message: ''}}
                />
            </div>
        </div>
        <div className='EditPatient-AddressInfo-2FR'>
            <div className='EditAddress-Input'>
                <span><b className='EditAddress-Asterisk'>*</b> Endereço</span>
                <Input 
                    name='address' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={editingDoctorAddress?.address?.address}
                    required={{erro: dataErrors, message: ''}}
                />
            </div>
            <div className='EditAddress-Input'>
                <span><b className='EditAddress-Asterisk'>*</b> Número</span>
                <Input 
                    name='number' 
                    action={handleChangeInfo} 
                    actionBlur={handleChangeInfo}
                    value={editingDoctorAddress?.address?.number}
                    maxLength={15}
                    required={{erro: dataErrors, message: ''}} 
                />
            </div>
        </div>
        <div className='EditAddress-Input'>
            <span>Complemento</span>
            <Input 
                name='complement' 
                action={handleChangeInfo}
                actionBlur={handleChangeInfo}
                value={editingDoctorAddress?.address?.complement} 
            />
        </div>
        
        <div className='EditAddress-Btn-Box'>
            <div>
                <p><span className='EditAddress-Btn-Box-SpanError'><b className='NewPatinet-Asterisk'>*</b> Preenchimento Obrigatório</span></p>
            </div>
            <div className='EditAddress-Btn-Box-Right-box'>
                {
                    Object.keys(dataErrors).length > 0 ?
                    <div>
                        <p><span className='EditAddress-Btn-Box-SpanError'>Para poder salvar, favor corrigir os campos em vermelho!</span></p>
                    </div>
                    : null
                }

                <Button
                    onClick={handleSubmit}
                    label='Salvar'
                    disabled={Object.keys(dataErrors).length === 0 ? false : true}
                />
            </div>
        </div>
    </div>
}
