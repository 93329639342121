export const strpCouncilData = (data) => {
	return {
		"id": data?.id || null,
		"council": data?.council || "",
		"nu_crm": data?.nu_crm || "",
		"uf_crm": data?.uf_crm || "",
		"person": data?.person || "",
		"registration_date": data?.registration_date || "",
		"associated_specialties": data?.associated_specialties || [],
	}
}