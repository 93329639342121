import './ShowHospitalizations.css'
import React from "react";

export default function ShowHospitalizations({ hospitalizationsHistory=[], fetchHospitalizations= async () => null, setModalInfo= () => null }) {
    
    const conditions = [
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(255, 99, 132)' },
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(54, 162, 235)' },
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(255, 206, 86)' },
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(75, 192, 192)' },
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(153, 102, 255)' },
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(255, 159, 64)' },
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(199, 199, 199)' },
    ];

    return <div className='ShowHospitalizations-Container'>
        {conditions.map(condition => {
            const data = hospitalizationsHistory.map(appointment => ({ "date": appointment?.updated_at, "value": appointment[condition.id] }))
            return (null
                //<ChronicConditionChart 
                //    key={condition.id} 
                //    condition={condition} 
                //    data={data}
                //    setModalInfo={setModalInfo}
                ///>
            );
        })}
    </div>
}