import './ShowPhysicalAssessment.css';
import React from 'react';
import PhysicalAssessmentChart from '../PhysicalAssessmentChart/PhysicalAssessmentChart';

export default function ShowPhysicalAssessment({
  physicalAssessmentHistory = [],
  fetchPhysicalAssessmentHistory = async () => null,
  setModalInfo = () => null,
}) {
  const conditions = [
    {
      id: 'physical_mobility_mmss',
      label: 'MMSS',
      color: 'rgb(255, 99, 132)',
      mappingType: 'PHYSICAL_MOBILITY_MMSS_CHOICES',
    },
    {
      id: 'physical_mobility_mmii',
      label: 'MMII',
      color: 'rgb(54, 162, 235)',
      mappingType: 'PHYSICAL_MOBILITY_MMII_CHOICES',
    },
    {
      id: 'skin',
      label: 'Pele',
      color: 'rgb(255, 206, 86)',
      mappingType: 'SKIN_CHOICES',
    },
    {
      id: 'turgor_skin',
      label: 'Turgor',
      color: 'rgb(75, 192, 192)',
      mappingType: 'TURGOR_CHOICES',
    },
    {
      id: 'mucosa_skin',
      label: 'Mucosa',
      color: 'rgb(153, 102, 255)',
      mappingType: 'MUCOSA_CHOICES',
    },
  ];

  return (
    <div className='ShowPhysicalAssessment-Container'>
      {conditions.map((condition) => {
        const data = physicalAssessmentHistory.map((appointment) => ({
          date: appointment?.updated_at,
          value: appointment[condition.id],
        }));
        return (
          <PhysicalAssessmentChart
            key={condition.id}
            condition={condition}
            data={data}
            setModalInfo={setModalInfo}
          />
        );
      })}
    </div>
  );
}
