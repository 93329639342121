import React from "react";
import Button, { ButtonModel } from '../../newcomponents/button/button'

export default function FormButtons({ onClose, onSave, saveText, disabled }) {
    return <div className='finance-form-buttons'>
        <Button
            label='Voltar'
            onClick={onClose}
            model={ButtonModel.SECONDARY}
        ></Button>
        <Button
            label={saveText ? saveText : 'Salvar'}
            onClick={onSave}
            disabled={disabled}
        ></Button>
    </div>
}