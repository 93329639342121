import './PersonalDataMenu.css'
import React from "react";
import PersonalDataLegalInformation from './subcomponents/PersonalDataLegalInformation/PersonalDataLegalInformation';
import PersonalDataAddress from './subcomponents/PersonalDataAddress/PersonalDataAddress';
import PersonalDataCouncilManager from './subcomponents/PersonalDataCouncilManager/PersonalDataCouncilManager';
import PersonalDataProfileImage from './subcomponents/PersonalDataProfileImage/PersonalDataProfileImage';
import PersonalDataConfig from './subcomponents/PersonalDataConfig/PersonalDataConfig';
import { useApp } from '../../../../../layout/App';

export default function PersonalDataMenu() {
    const { isHealthProfessional } = useApp()

    return (
        <div>
            <div className='PersonalDataMenu'>
		        <div className='PersonalDataMenu-Col'>
                    <div className='PersonalDataMenu-Container'>
                        <PersonalDataLegalInformation />
                    </div>
                    <div className='PersonalDataMenu-Container'>
                        <PersonalDataAddress />
                    </div>
                    {
                        isHealthProfessional ?
                        <div className='PersonalDataMenu-Container'>
                            <PersonalDataCouncilManager />
                            {/* currentHealthPlaceUser?.profile === 'DOCTOR'
                            ? (<PersonalDataCRMManager />)
                            : (<PersonalDataCouncilManager />) */}
                        </div> : null
                    }
		        </div>
		        <div className='PersonalDataMenu-Col'>
                    <div className='PersonalDataMenu-Container'>
                        <PersonalDataProfileImage />
                    </div>
                    {
                        isHealthProfessional ?
                        <div className='PersonalDataMenu-Container'>
                            <PersonalDataConfig />
                        </div>
                        : null
                    }
		        </div>
            </div>
        </div>
    )
}