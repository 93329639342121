import './DeleteHospitalizations.css'
import React from 'react'
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../../../component'
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions'
import { patchHospitalization } from '../../../../../../../../http'

export default function DeleteHospitalizations({ hospitalization={}, extraAction=() => null }) {
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            await patchHospitalization(hospitalization?._id, { "active": false })
            extraAction()
        } catch (err) {
            console.error('DeleteHospitalizations ~ handleSubmit ~ ', err)
            dispatch(AddAlert('Hospitalização', 'Falha ao deletar registro de hospitalização!', AlertType.ERROR))
        }
        dispatch(loaded())
    }

    return <div className='DeleteHospitalizations'>
        <p>Tem certeza que deseja exluir essa <b>Hospitalização</b>?</p>
        <div className='DeleteHospitalizations-BtnBox'>
            <NewButton 
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => extraAction()}
            />
            <NewButton 
                label='Deletar'
                model={ButtonModel.DANGER}
                onClick={handleSubmit}
            />
        </div>
    </div>
}