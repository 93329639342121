import './PreventiveExams.css'
import React, { useState } from "react";
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import CalendarDateModal from '../../../../../../../../component/calendar/CalendarDateModal';
import { Input, adicionaZero } from '../../../../../../../../component';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { formatDateWithTimezoneFromDateObj, formatISO8601Str } from '../../../../../../../../utils/convertDate';


const defaultOptions = require('../../../../../../../../component/form/select/options.json')

export default function PreventiveExams() {
	const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)
    
    const handleSelectDateObj = (name, {yearSearchCursor, monthSearchCursor, daySearchCursor}) => {
		let nextDate = new Date(yearSearchCursor, parseInt(monthSearchCursor, 10) - 1, daySearchCursor)
		//setFilters(prev => ({ ...prev, [name]: nextDate }))

		let formattedNextDate = name.endsWith('__gte') ? formatDateWithTimezoneFromDateObj(nextDate, '00', '00') :  formatDateWithTimezoneFromDateObj(nextDate, '23', '59')
		if (name.startsWith('person__birthday__')) {
			formattedNextDate = formattedNextDate.substring(0, 10)
		}

	}

    const openCalendarDateModal = (filterKey, modalTitle) => {
		const date_cursor = /*filters?.[filterKey];*/ null
		const currentDate = date_cursor ? new Date(date_cursor) : new Date();
	
		const currentDay = String(adicionaZero(currentDate.getDate()));
		const currentMonth = String(adicionaZero(currentDate.getMonth() + 1)); // Months are zero-indexed
		const currentYear = String(currentDate.getFullYear());
		
		setSecondaryModal(prev => ({
			...prev,
			open: true,
			title: modalTitle,
			content: (
				<CalendarDateModal
					name={filterKey}
					currentDay={currentDay}
					currentMonth={currentMonth}
					currentYear={currentYear}
					renderNextSelectedDay={(dateEvent) => {
						handleSelectDateObj(filterKey, dateEvent);
						setSecondaryModal(MODAL_INIT_PROPS);
					}}
				/>
			)
		}));
	};

    const openCalendarCervicalExamDateModal = () => {
		openCalendarDateModal('cervical_examination_date', 'Exame de colo do útero');
	};

    const openCalendarMammogramExamDateModal = () => {
		openCalendarDateModal('mammogram_date', 'Mamografia');
	};

    const openCalendarFecalOccultBloodTestExamDateModal = () => {
        openCalendarDateModal('fecal_occult_blood_test_date', 'Exame de sangue oculto nas fezes')
    }

    return <div>
        <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />

        <div className='PreventiveExams-Grid'>
            <div className='PreventiveExams-Select'>
                <b>Exame de colo do útero</b>
                <div >
                    <NewSelect
                        id='cervical_examination'
                        options={defaultOptions.ONLY_CARRY_OUT_DIDNT_CARRY_OUT_OR_NOT_EVALUATED}
                        onSelect={() => null}
                        selected={{}}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='PreventiveExams-Input'>
                <b>Data de Realização</b>
                <div>
                    <Input
                        label=''
                        name='cervical_examination_date'
                        placeholder='DD/MM/YYYY'
                        actionFocus={openCalendarCervicalExamDateModal}
                        value={formatISO8601Str(/* FIXME cervical_examination_date */)}
                    />
                </div>
            </div>
            <div className='PreventiveExams-Select'>
                <b>Mamografia</b>
                <div >
                    <NewSelect
                        id='mammogram'
                        options={defaultOptions.ONLY_CARRY_OUT_DIDNT_CARRY_OUT_OR_NOT_EVALUATED}
                        onSelect={() => null}
                        selected={{}}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='PreventiveExams-Input'>
                <b>Data de Realização</b>
                <div>
                    <Input
                        label=''
                        name='mammogram_date'
                        placeholder='DD/MM/YYYY'
                        actionFocus={openCalendarMammogramExamDateModal}
                        value={formatISO8601Str(/* FIXME mammogram_date */)}
                    />
                </div>
            </div>
            <div className='PreventiveExams-Select'>
                <b>Exame de sangue oculto nas fezes</b>
                <div>
                    <NewSelect
                        id='fecal_occult_blood_test'
                        options={defaultOptions.ONLY_CARRY_OUT_DIDNT_CARRY_OUT_OR_NOT_EVALUATED}
                        onSelect={() => null}
                        selected={{}}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='PreventiveExams-Input'>
                <b>Data de Realização</b>
                <div>
                    <Input
                        label=''
                        name='fecal_occult_blood_test_date'
                        placeholder='DD/MM/YYYY'
                        actionFocus={openCalendarFecalOccultBloodTestExamDateModal}
                        value={formatISO8601Str(/* FIXME fecal_occult_blood_test_date */)}
                    />
                </div>
            </div>
        </div>
    </div>
}