
export function strpElimination(elimination = null) {
    return {
        _id: elimination?._id,
        person: elimination?.person,
        attendance: elimination?.attendance,
        urinary_elimination: elimination?.urinary_elimination || 'NOT_EVALUATED',
        intestinal_elimination: elimination?.intestinal_elimination || 'NOT_EVALUATED',
        elimination_observations: elimination?.elimination_observations || '',
        active: true,
    }
}

export function parseElimination(elimination) {
    return {
        _id: elimination?._id,
        person: elimination?.person?.id,
        attendance: elimination?.attendance,
        urinary_elimination: elimination?.urinary_elimination || 'NOT_EVALUATED',
        intestinal_elimination: elimination?.intestinal_elimination || 'NOT_EVALUATED',
        elimination_observations: elimination?.elimination_observations || '',
        active: true
    }
}