import './NewEditPhysicalAssessment.css'
import React, { useState } from "react";
import NewSelect from "../../../../../../../../../../newcomponents/form/select/select";
import ReactQuill from "react-quill";
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button';
import { putPhysicalAssessment } from '../../../../../../../../http';
import { strpPhysicalAssessment } from '../../utils';
import { AddAlert, AlertType } from '../../../../../../../../../../component';
import { useDispatch } from 'react-redux';
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function NewEditPhysicalAssessment({ person, attendanceID=null, initPhysicalAssessment=null, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const [holdingPhysicalAssessment, setHoldingPhysicalAssessment] = useState(strpPhysicalAssessment(initPhysicalAssessment))
    
    const savePhysicalAssessment= async () => {
        const payload = { ...holdingPhysicalAssessment, "attendance": attendanceID, "person": person }
        dispatch(loading())
        try {
            await putPhysicalAssessment(payload)
            dispatch(AddAlert('Avaliação Física', 'Avaliação Física salva com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('savePhysicalAssessment', err)
        }
        dispatch(loaded())
    }

    const handleSelect = (event) => {
        setHoldingPhysicalAssessment(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleTextSkinInjures = (text) => {
        setHoldingPhysicalAssessment(prev => ({ ...prev, "skin_injures": text }))
    }

    return <div className="NewEditPhysicalAssessment">
        <div>
            <p><b>Mobilidade Física</b></p>
            <div className='NewEditPhysicalAssessment-PhysicalMobilityBox'>
                <div className='NewEditPhysicalAssessment-Select'>
                    <span>MMSS</span>
                    <div> 
                        <NewSelect
                            id='physical_mobility_mmss'
                            options={defaultOptions.PHYSICAL_MOBILITY_MMSS_CHOICES}
                            onSelect={handleSelect}
                            selected={holdingPhysicalAssessment.physical_mobility_mmss}
                            canBeEmpty={false}
                        />
                    </div>
                </div>
                <div className='NewEditPhysicalAssessment-Select'>
                    <span>MMII</span>
                    <div> 
                        <NewSelect
                            id='physical_mobility_mmii'
                            options={defaultOptions.PHYSICAL_MOBILITY_MMII_CHOICES}
                            onSelect={handleSelect}
                            selected={holdingPhysicalAssessment.physical_mobility_mmii}
                            canBeEmpty={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='NewEditPhysicalAssessment-SkinBox'>
            <div className='NewEditPhysicalAssessment-Select'>
                <b>Pele</b>
                <div>
                    <NewSelect
                        id='skin'
                        options={defaultOptions.SKIN_CHOICES}
                        onSelect={handleSelect}
                        selected={holdingPhysicalAssessment.skin}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditPhysicalAssessment-SkinBox-Grid'>
                <div className='NewEditPhysicalAssessment-Select'>
                    <span>Turgor</span>
                    <div>
                        <NewSelect
                            id='turgor_skin'
                            options={defaultOptions.TURGOR_CHOICES}
                            onSelect={handleSelect}
                            selected={holdingPhysicalAssessment.turgor_skin}
                            canBeEmpty={false}
                        />
                    </div>
                </div>
                <div className='NewEditPhysicalAssessment-Select'>
                    <span>Mucosa</span>
                    <div>
                        <NewSelect
                            id='mucosa_skin'
                            options={defaultOptions.MUCOSA_CHOICES}
                            onSelect={handleSelect}
                            selected={holdingPhysicalAssessment.mucosa_skin}
                            canBeEmpty={false}
                        />
                    </div>
                </div>
            </div>

        </div>
        <div>
            <p><b>Lesões</b></p>
            <ReactQuill
                id='skin_injures'
                theme="snow"
                onChange={handleTextSkinInjures}
                //onBlur={(previousRange, source, editor) => handleBlurAppointmentTextField(field_type, editor.getHTML())}
            />
        </div>

        <div className='NewEditPhysicalAssessment-BtnBox'>
            <NewButton 
                label='Salvar Avaliação Física'
                onClick={savePhysicalAssessment}
            />
        </div>
    </div>
}