import './PersonalDataCouncilManagerModal.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IcoBin, IcoPencil, List } from '../../../../../../../../component'
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { useApp } from '../../../../../../../../layout/App'
import NewEditCouncilModal from './subcomponents/NewEditCouncilModal/NewEditCouncilModal'
import RemoveCouncilModal from './subcomponents/RemoveCouncilModal/RemoveCouncilModal'

const councilHead = [
	{ colunm: 'h_nu_crm', text: 'Número' },
	{ colunm: 'h_uf_crm', text: 'UF' },
	{ colunm: 'h_associated_specialties', text: 'Especialidades' },
	{ colunm: 'h_actions', text: '', width: '100px'  },
]



export default function PersonalDataCouncilManagerModal() {
	const dispatch = useDispatch()
	const { councils } = useApp()
	const [secondaryModalProps, setSecondaryModalProps] = useState(MODAL_INIT_PROPS)

	return (
		<div className='PersonalDataCouncilManagerModal'>
			<Modal dismissFn={() => setSecondaryModalProps(MODAL_INIT_PROPS)} {...secondaryModalProps} />
			<div className='PersonalDataCouncilManagerModal-Header-ActionRow'>
				<div 
					title='Novo conselho'
					onClick={() => setSecondaryModalProps(prev => ({
						...prev,
						open: true,
						title: 'Novo conselho',
						content: <NewEditCouncilModal setSecondaryModalProps={setSecondaryModalProps}/>,
						dismissText: '',
					}))}
				>
					<NewTaskIcon />
				</div>
			</div>
			<List
				head={councilHead}
				data={councils.map(instance => ({ ...instance }))}
				noData={''}
				maxHeight='200px'
				listCustom={council => {
					const custom = council
					custom['h_nu_crm'] = council.nu_crm
					custom['h_uf_crm'] = council.uf_crm
					custom['h_associated_specialties'] = council.associated_specialties.map(instance => instance?.name).join(', ')
					custom['h_actions'] = (
						<div className='PersonalDataCouncilManagerModal-ActionRow'>
							<div
								title='Editar'
								onClick={() => setSecondaryModalProps(prev => ({
									...prev,
									open: true,
									title: 'Editar conselho',
									content: <NewEditCouncilModal initCouncilData={council} setSecondaryModalProps={setSecondaryModalProps}/>,
									dismissText: '',
								}))}
							>
								<IcoPencil />
							</div>
							<div
								title='Remover'
								onClick={() => setSecondaryModalProps(prev => ({
									...prev,
									open: true,
									title: 'Remover conselho',
									content: <RemoveCouncilModal council={council} setSecondaryModalProps={setSecondaryModalProps} />,
									dismissText: '',
								}))}
							>
								<IcoBin />
							</div>
						</div>
					)
					return custom
				}}
			/>
		</div>
	)
}
