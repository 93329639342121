import { renderSessinWithoutHtml, renderSession } from '../helpers'
import './OphthalmologyUnit.css'
import React from 'react'


export default function OphthalmologyUnit({ attendance={} }) {
    const [{
        right_eye_visual_acuity_sc,
        right_eye_visual_acuity_cc,
        left_eye_visual_acuity_sc,
        left_eye_visual_acuity_cc,
        right_eye_dynamic_refraction_e_degree,
        right_eye_dynamic_refraction_c_degree,
        right_eye_dynamic_refraction_axis,
        right_eye_dynamic_refraction_vision,
        left_eye_dynamic_refraction_e_degree,
        left_eye_dynamic_refraction_c_degree,
        left_eye_dynamic_refraction_axis,
        left_eye_dynamic_refraction_vision,
        right_eye_static_refraction_e_degree,
        right_eye_static_refraction_c_degree,
        right_eye_static_refraction_axis,
        right_eye_static_refraction_vision,
        left_eye_static_refraction_e_degree,
        left_eye_static_refraction_c_degree,
        left_eye_static_refraction_axis,
        left_eye_static_refraction_vision,
        right_eye_pressure,
        left_eye_pressure,
        biomicroscopy_is_normal_display,
        right_eye_biomicroscopy_anterior_chamber,
        right_eye_biomicroscopy_crystalline,
        right_eye_biomicroscopy_cornea,
        right_eye_biomicroscopy_conjunctiva,
        left_eye_biomicroscopy_anterior_chamber,
        left_eye_biomicroscopy_crystalline,
        left_eye_biomicroscopy_cornea,
        left_eye_biomicroscopy_conjunctiva,
        funduscopy_is_normal_display,
        right_eye_funduscopy_macular_region,
        right_eye_funduscopy_periphery,
        right_eye_funduscopy_vitreous,
        right_eye_funduscopy_excavation,
        left_eye_funduscopy_macular_region,
        left_eye_funduscopy_periphery,
        left_eye_funduscopy_vitreous,
        left_eye_funduscopy_excavation,
        notes
    }] = attendance?.ophthalmology_fields || [{}]

    return <div className='OphthalmologyUnit'>
        <div className='OphthalmologyUnit-Subtitle'><b>Oftalmologia</b></div>
        <div className='OphthalmologyUnit-Content' style={{ color: '#aaa' }}>
            <div>
                {
                    renderSessinWithoutHtml('Acuidade Visual', <div style={{ paddingLeft: '0.5rem' }}>
                        <b>OD SC</b>: {right_eye_visual_acuity_sc} <br />
                        <b>OE SC</b>: {left_eye_visual_acuity_sc}<br />
                        <br />
                        <b>OD CC</b>: {right_eye_visual_acuity_cc}<br />
                        <b>OE CC</b>: {left_eye_visual_acuity_cc}
                    </div>)
                }
            </div>
            <div>
                {
                    renderSessinWithoutHtml('Pressão Intra-ocular', <div style={{ paddingLeft: '0.5rem' }}>
                        <b>OD</b>:  {right_eye_pressure}<br />
                        <b>OE</b>:  {left_eye_pressure}
                    </div>)
                }
            </div>
            <div>
                {
                    renderSessinWithoutHtml('Refração Dinâmica', <div style={{ paddingLeft: '0.5rem' }}>
                        <b>OD Grau E</b>:  {right_eye_dynamic_refraction_e_degree}<br />
                        <b>OE Grau E</b>:  {left_eye_dynamic_refraction_e_degree}<br /><br />
                        <b>OD Grau C</b>:  {right_eye_dynamic_refraction_c_degree}<br />
                        <b>OE Grau C</b>:  {left_eye_dynamic_refraction_c_degree}<br /><br />
                        <b>OD Eixo</b>:  {right_eye_dynamic_refraction_axis}<br />
                        <b>OE Eixo</b>:  {left_eye_dynamic_refraction_axis}<br /><br />
                        <b>OD Visão</b>:  {right_eye_dynamic_refraction_vision}<br />
                        <b>OE Visão</b>:  {left_eye_dynamic_refraction_vision}<br />
                    </div>)
                }
            </div>
            <div>
                {
                    renderSessinWithoutHtml('Refração Estática', <div style={{ paddingLeft: '0.5rem' }}>
                        <b>OD Grau E</b>: {right_eye_static_refraction_e_degree}<br />
                        <b>OE Grau E</b>: {left_eye_static_refraction_e_degree}<br /><br />
                        <b>OD Grau C</b>: {right_eye_static_refraction_c_degree}<br />
                        <b>OE Grau C</b>: {left_eye_static_refraction_c_degree}<br /><br />
                        <b>OD Eixo</b>: {right_eye_static_refraction_axis}<br />
                        <b>OE Eixo</b>: {left_eye_static_refraction_axis}<br /><br />
                        <b>OD Visão</b>: {right_eye_static_refraction_vision}<br />
                        <b>OE Visão</b>: {left_eye_static_refraction_vision}<br />
                    </div>)
                }
            </div>
            <div>
                {
                    renderSessinWithoutHtml('Biomicroscopia', <span>
                        {biomicroscopy_is_normal_display}<br/><br />
                        <div style={{ paddingLeft: '0.5rem' }}>
                            <b>OD Câmara anterior</b>: {right_eye_biomicroscopy_anterior_chamber}<br />
                            <b>OE Câmara anterior</b>: {left_eye_biomicroscopy_anterior_chamber}<br /><br />
                            <b>OD Cristalino</b>: {right_eye_biomicroscopy_crystalline}<br />
                            <b>OE Cristalino</b>: {left_eye_biomicroscopy_crystalline}<br /><br />
                            <b>OD Córnea</b>: {right_eye_biomicroscopy_cornea}<br />
                            <b>OE Córnea</b>: {left_eye_biomicroscopy_cornea}<br /><br />
                            <b>OD Conjuntiva</b>: {right_eye_biomicroscopy_conjunctiva}<br />
                            <b>OE Conjuntiva</b>: {left_eye_biomicroscopy_conjunctiva}<br />
                        </div>
                    </span>)
                }
            </div>
            <div>
                {
                    renderSessinWithoutHtml('Fundoscopia', <span >
                        {funduscopy_is_normal_display}<br/><br />
                        <div style={{ paddingLeft: '0.5rem' }}>
                            <b>OD Câmara anterior</b>: {right_eye_funduscopy_macular_region}<br />
                            <b>OE Câmara anterior</b>: {left_eye_funduscopy_macular_region}<br /><br />
                            <b>OD Cristalino</b>: {right_eye_funduscopy_periphery}<br />
                            <b>OE Cristalino</b>: {left_eye_funduscopy_periphery}<br /><br />
                            <b>OD Córnea</b>: {right_eye_funduscopy_vitreous}<br />
                            <b>OE Córnea</b>: {left_eye_funduscopy_vitreous}<br /><br />
                            <b>OD Conjuntiva</b>: {right_eye_funduscopy_excavation}<br />
                            <b>OE Conjuntiva</b>: {left_eye_funduscopy_excavation}<br />
                        </div>
                    </span>)
                }
            </div>
            <div>
                {
                    renderSession('Observações', notes)
                }
            </div>
        </div>
    </div>
}
