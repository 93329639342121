import './MedicalRecordUnit.css'
import React from "react";
import { useHistory } from 'react-router-dom';
import { IcoEstetoscopio, MaskDataHora } from '../../../../../../component';
import InfoLine from '../../../../../../component/info-line/info-line';
import { ClipboardIcon } from '../../../../../../component/icon/clipboard';
import SubjectiveUnit from './SubjectiveUnit/SubjectiveUnit';
import ObjectiveUnit from './ObjectiveUnit/ObjectiveUnit';
import AssessmentUnit from './AssessmentUnit/AssessmentUnit';
import PlanUnit from './PlanUnit/PlanUnit';
import NewAppointmentModal from '../../../../../AttendanceManager/subcomponents/AttendanceHistory/subcomponents/NewAppointmentModal/NewAppointmentModal';
import { useApp } from '../../../../../../layout/App';
import OphthalmologyUnit from './OphthalmologyUnit/OphthalmologyUnit';

const { DECISION_CHOICES, CLASSIFICATION_CHOICES, RESULT_CHOICES, PRIORITY_CHOICES } = require('../../../../../../component/form/select/options.json')

export default function MedicalRecordUnit({ attendance=null, setModalProps=()=> null, fetchAttendancesHistory=() => null }) {
    const { isHealthProfessional } = useApp()
    const history = useHistory()
    const RENDER_OPHTHALMOLOGY = attendance?.ophthalmology_fields?.length
    const RENDER_SUBJECTIVE = attendance?.template_type?.subjective?.length
    const RENDER_OBJECTIVE = attendance?.template_type?.objective?.length
    const RENDER_ASSESSMENT = attendance?.template_type?.assessment?.length
    const RENDER_PLAN = attendance?.template_type?.plan?.length
    const RENDER_EMPTY_MEDICAL_RECORD = !(RENDER_SUBJECTIVE || RENDER_OBJECTIVE || RENDER_ASSESSMENT || RENDER_PLAN)

    const openConsultation = (id) => {
        window.open(`/consultation/${id}`)
    }
    
    const attendancePriority = PRIORITY_CHOICES.find(({ id }) => id === attendance?.priority)
    const attendanceDecision = DECISION_CHOICES?.find(({ id }) => id === attendance?.decision)

    return <div className='MedicalRecordUnit'>
        <div>
            <div className='MedicalRecordUnit-AppointmentBox-Subtitle'>
                <ClipboardIcon style={{ height: '21px', width: '21px' }} /> 
                <b>Resumo</b>
                {
                    attendanceDecision?.name
                    ? <div 
                        className='MedicalRecordUnit-AppointmentBox-Subtitle-DecisionBox'
                        style={{ 
                            'border': `1px solid ${attendanceDecision.color}`,
                            'backgroundColor': attendanceDecision.secondColor,
                            'color': attendanceDecision.color,
                        }}    
                    >
                        <b>{attendanceDecision?.name}</b>
                    </div>
                    : null
                }
                {
                    CLASSIFICATION_CHOICES?.find(ob => ob?.id === attendance?.classification)?.name
                    ? <div className='MedicalRecordUnit-AppointmentBox-Subtitle-DecisionBox'>
                        <b>{CLASSIFICATION_CHOICES?.find(ob => ob?.id === attendance?.classification)?.name}</b>
                    </div>
                    : null
                }
                {
                    RESULT_CHOICES?.find(ob => ob?.id === attendance?.result)?.name
                    ? <div className='MedicalRecordUnit-AppointmentBox-Subtitle-DecisionBox'>
                        <b>{RESULT_CHOICES?.find(ob => ob?.id === attendance?.result)?.name}</b>
                    </div>
                    : null
                }
                {
                    attendance?.specialty?.name 
                    ? <div className='MedicalRecordUnit-AppointmentBox-Subtitle-DecisionBox'>
                        <b>{attendance?.specialty?.name}</b>
                    </div>
                    : null
                }
            </div>
            <div className='MedicalRecordUnit-AppointmentBox-Grid' style={{ color: '#aaa'}}>
                <InfoLine 
                    label='Profissional'
                    value={
                        attendance?.extra_kwargs?.external_doctor_name
                        ? <span className='MedicalRecordUnit-AppointmentBox-Grid-AltText'>{attendance?.extra_kwargs?.external_doctor_name}</span>
                        : <span className='MedicalRecordUnit-AppointmentBox-Grid-AltText'>{`${attendance?.doctor?.sex === 'F' ? 'Dra. ' : 'Dr. '} ${attendance?.doctor?.name}`}</span>
                    }
                />
                {
                    attendance?.health_insurance?.name
                    ? <InfoLine 
                        label='Convênio'
                        value={<span className='MedicalRecordUnit-AppointmentBox-Grid-AltText'>{attendance?.health_insurance?.name}</span>}
                    />
                    : null
                }
            </div>
        </div>
        <div>
            <div className='MedicalRecordUnit-AppointmentBox-Subtitle'>
                <IcoEstetoscopio style={{ height: '21px', width: '21px' }} /> 
                <b>Prontuário</b>
            </div>
        </div>
        { RENDER_OPHTHALMOLOGY ? <OphthalmologyUnit attendance={attendance} /> : null }
        { RENDER_SUBJECTIVE ? <SubjectiveUnit attendance={attendance} /> : null }
        { RENDER_OBJECTIVE ? <ObjectiveUnit attendance={attendance} /> : null }
        { RENDER_ASSESSMENT ? <AssessmentUnit attendance={attendance} /> : null }
        { RENDER_PLAN ? <PlanUnit attendance={attendance} /> : null }
        { RENDER_EMPTY_MEDICAL_RECORD ? <div className='MedicalRecordUnit-EmptyBox'><span>- Prontuário vazio</span></div> : null }
        <div className='MedicalRecordUnit-ActionRow'>
            {
                isHealthProfessional
                ? <div 
                    className='MedicalRecordUnit-ActionRow-Btn' 
                    onClick={() => openConsultation(attendance?.id)}
                >
                    <b>Ver atendimento</b>
                </div>
                : null
            }
            <div 
                className='MedicalRecordUnit-ActionRow-Btn' 
                onClick={() => setModalProps({
                    open: true,
                    title: `Editar atendimento | ${MaskDataHora(attendance.start)?.data} | ${MaskDataHora(attendance.start)?.hora}`,
                    content: <NewAppointmentModal
                        initAppointment={attendance}
                        fetchAppointments={fetchAttendancesHistory}
                        setModalInfo={setModalProps}
                    />
                })}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}