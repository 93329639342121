import './NewEditLifeHabitsAndBehavior.css'
import React, { useState } from "react";
import NewSelect from "../../../../../../../../../../newcomponents/form/select/select";
import NewButton, { } from '../../../../../../../../../../newcomponents/button/button';
import { strpLifeHabitsAndBehavior } from '../../utils';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions';
import { putLifeHabitsAndBehavior } from '../../../../../../../../http';
import { AddAlert, AlertType } from '../../../../../../../../../../component';
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal';


const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')


export default function NewEditLifeHabitsAndBehavior({ person, attendanceID=null, initLifeHabitsAndBehavior=null, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const [holdingLifeHabitsAndBehavior, setholdingLifeHabitsAndBehavior] = useState(strpLifeHabitsAndBehavior(initLifeHabitsAndBehavior))

    const saveLifeHabitsAndBehavior = async () => {
        const payload = { ...holdingLifeHabitsAndBehavior, "attendance": attendanceID, "person": person }
        dispatch(loading())
        try {
            await putLifeHabitsAndBehavior(payload)
            dispatch(AddAlert('Hábitos de vida e comportamento', 'Hábitos de vida e comportamento salvos com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveLifeHabitsAndBehavior', err)
        }
        dispatch(loaded())
    }

    const handleSelect = (event) => {
        setholdingLifeHabitsAndBehavior(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    return <div className='NewEditLifeHabitsAndBehavior'>
        <div className='NewEditLifeHabitsAndBehavior-Grid'>
            <div className='NewEditLifeHabitsAndBehavior-Select'>
                <b>Fuma</b>
                <div >
                    <NewSelect
                        id='smokes'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingLifeHabitsAndBehavior.smokes}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditLifeHabitsAndBehavior-Select'>
                <b>Exposição ao fumo passivo</b>
                <div >
                    <NewSelect
                        id='exposure_to_secondhand_smoke'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingLifeHabitsAndBehavior.exposure_to_secondhand_smoke}
                        canBeEmpty={false}
                    />
                </div>
            </div>

            <div className='NewEditLifeHabitsAndBehavior-Select'>
                <b>Uso de drogas ilícitas</b>
                <div>
                    <NewSelect
                        id='use_of_illicit_drugs'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingLifeHabitsAndBehavior.use_of_illicit_drugs}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditLifeHabitsAndBehavior-Select'>
                <b>Atividade física</b>
                <div>
                    <NewSelect
                        id='physical_activity'
                        options={defaultOptions.PHYSICAL_ACTIVITY_FREQUENCY_CHOICES}
                        onSelect={handleSelect}
                        selected={holdingLifeHabitsAndBehavior.physical_activity}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditLifeHabitsAndBehavior-Select'>
                <b>Vacinas em dia</b>
                <div>
                    <NewSelect
                        id='vaccinations_up_to_date'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingLifeHabitsAndBehavior.vaccinations_up_to_date}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditLifeHabitsAndBehavior-Select'>
                <b>Consumo de bebidas alcoólicas</b>
                <div>
                    <NewSelect
                        id='consumption_of_alcoholic_beverages_frequency'
                        options={defaultOptions.CONSUMPTION_OF_ALCOHOLIC_FREQUENCY_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingLifeHabitsAndBehavior.consumption_of_alcoholic_beverages_frequency}
                        canBeEmpty={false}
                    />
                </div>
            </div>
        </div>
        <div className='NewEditLifeHabitsAndBehavior-BtnBox'>
            <NewButton 
                onClick={saveLifeHabitsAndBehavior}
                label='Salvar Hábitos de vida e Comportamento'
            />
        </div>
    </div>
}