import { formatDateWithTimezoneFromDateObj } from "../../../../../../../../utils/convertDate"

export function strpHospitalization(hospitalization = null) {
    return {
        _id: hospitalization?._id,
        person: hospitalization?.person,
        attendance: hospitalization?.attendance,
        number_of_days_in_hospital: hospitalization?.number_of_days_in_hospital || 0,
        reason_for_hospitalization: hospitalization?.reason_for_hospitalization || '',
        hospitalization_date: hospitalization?.hospitalization_date ? new Date(hospitalization?.hospitalization_date) : new Date(),
        active: true,
    }
}

export function parseHospitalization(hospitalization) {
    return {
        _id: hospitalization?._id,
        person: hospitalization?.person?.id,
        attendance: hospitalization?.attendance,
        number_of_days_in_hospital: hospitalization?.number_of_days_in_hospital || 0,
        reason_for_hospitalization: hospitalization?.reason_for_hospitalization || '',
        hospitalization_date: hospitalization?.hospitalization_date
            ? formatDateWithTimezoneFromDateObj(hospitalization.hospitalization_date, '12', '12') 
            : null,
        active: true
    }
}