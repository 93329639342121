import LifeHabitsAndBehaviorChart from '../LifeHabitsAndBehaviorChart/LifeHabitsAndBehaviorChart';
import './ShowLifeHabitsAndBehavior.css';
import React from 'react';

export default function ShowLifeHabitsAndBehavior({
  lifeHabitsAndBehaviorHistory = [],
  fetchLifeHabitsAndBehaviorHistory = async () => null,
  setModalInfo = () => null,
}) {
  const conditions = [
    {
      id: 'smokes',
      label: 'Fuma',
      color: 'rgb(255, 99, 132)',
      mappingType: 'ONLY_YES_NO_OR_NOT_EVALUATED',
    },
    {
      id: 'exposure_to_secondhand_smoke',
      label: 'Exposição ao fumo passivo',
      color: 'rgb(54, 162, 235)',
      mappingType: 'ONLY_YES_NO_OR_NOT_EVALUATED',
    },
    {
      id: 'use_of_illicit_drugs',
      label: 'Uso de drogas ilícitas',
      color: 'rgb(255, 206, 86)',
      mappingType: 'ONLY_YES_NO_OR_NOT_EVALUATED',
    },
    {
      id: 'physical_activity',
      label: 'Atividade física',
      color: 'rgb(75, 192, 192)',
      mappingType: 'PHYSICAL_ACTIVITY_FREQUENCY_CHOICES',
    },
    {
      id: 'vaccinations_up_to_date',
      label: 'Vacinas em dia',
      color: 'rgb(153, 102, 255)',
      mappingType: 'ONLY_YES_NO_OR_NOT_EVALUATED',
    },
    {
      id: 'consumption_of_alcoholic_beverages_frequency',
      label: 'Consumo de bebidas alcoólicas',
      color: 'rgb(255, 159, 64)',
      mappingType: 'CONSUMPTION_OF_ALCOHOLIC_FREQUENCY_OR_NOT_EVALUATED',
    },
  ];

  return (
    <div className='ShowLifeHabitsAndBehavior-Container'>
      {conditions.map((condition) => {
        const data = lifeHabitsAndBehaviorHistory.map((appointment) => ({
          date: appointment?.updated_at,
          value: appointment[condition.id],
        }));
        return (
          <LifeHabitsAndBehaviorChart
            key={condition.id}
            condition={condition}
            data={data}
            setModalInfo={setModalInfo}
          />
        );
      })}
    </div>
  );
}