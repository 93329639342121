import './PhysicalAssessmentChart.css';
import React from 'react';
import {
    Chart,
    LinearScale,
    CategoryScale,
    LineController,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { MaskDataHora } from '../../../../../../../../../../component';

Chart.register(
    LinearScale,
    CategoryScale,
    LineController,
    PointElement,
    LineElement,
    Title
);

export default function PhysicalAssessmentChart({ condition, data, setModalInfo = () => null }) {
  const categoryMappings = {
    SKIN_CHOICES: {
      "NOT_EVALUATED": 0,
      "COMPLETE": 1,
      NO_COMPLETE: 2,
    },
    TURGOR_CHOICES: {
      NOT_EVALUATED: 0,
      PRESENT: 1,
      ABSENT: 2,
      DECREASED: 3,
    },
    MUCOSA_CHOICES: {
      NOT_EVALUATED: 0,
      BLUSHED: 1,
      HIPOCORATED: 2,
      HYDRATED: 3,
      DEHYDRATED: 4,
      ACIANOTIC: 5,
      CYANOTIC: 6,
      ANICTERIC: 7,
      ICTERIC: 8,
    },
    PHYSICAL_MOBILITY_MMSS_CHOICES: {
      NOT_EVALUATED: 0,
      PRESERVED: 1,
      PARESIS: 2,
      PLEGIA: 3,
      ECCHYMOSIS: 4,
    },
    PHYSICAL_MOBILITY_MMII_CHOICES: {
      NOT_EVALUATED: 0,
      PRESERVED: 1,
      PARESIS: 2,
      PLEGIA: 3,
      ECCHYMOSIS: 4,
      VARICOSE_VEINS: 5,
      VARICOSE_ULCERS: 6,
    },
  };

  const reverseCategoryMappings = {
    SKIN_CHOICES: {
      0: 'Não avaliado',
      1: 'Íntegra',
      2: 'Não íntegra',
    },
    TURGOR_CHOICES: {
      0: 'Não avaliado',
      1: 'Presente',
      2: 'Ausente',
      3: 'Diminuído',
    },
    MUCOSA_CHOICES: {
      0: 'Não avaliado',
      1: 'Corado',
      2: 'Hipocorado',
      3: 'Hidratado',
      4: 'Desidratado',
      5: 'Acianótico',
      6: 'Cianótico',
      7: 'Anictérico',
      8: 'Ictérico',
    },
    PHYSICAL_MOBILITY_MMSS_CHOICES: {
      0: 'Não avaliado',
      1: 'Preservada',
      2: 'Paresia',
      3: 'Plegia',
      4: 'Equimose',
    },
    PHYSICAL_MOBILITY_MMII_CHOICES: {
      0: 'Não avaliado',
      1: 'Preservada',
      2: 'Paresia',
      3: 'Plegia',
      4: 'Equimose',
      5: 'Varizes',
      6: 'Úlceras varicosas',
    },
  };

  const mappingType = condition.mappingType;
  const categoryMapping = categoryMappings[mappingType];
  const reverseCategoryMapping = reverseCategoryMappings[mappingType];

  const labels = data.map((entry) => MaskDataHora(entry?.date)?.data || entry?.date);
  const numericData = data.map((entry) => categoryMapping[entry.value]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: condition.label,
        data: numericData,
        fill: false,
        borderColor: condition.color,
        tension: 0.1,
        pointBackgroundColor: condition.color,
        spanGaps: true,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return reverseCategoryMapping[value];
          },
        },
        title: {
          display: true,
          text: condition.label,
        },
        min: Math.min(...Object.values(categoryMapping)),
        max: Math.max(...Object.values(categoryMapping)),
      },
      x: {
        title: {
          display: true,
          text: 'Data',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            return `${condition.label}: ${reverseCategoryMapping[value]}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const handleShowDetailsModal = (chartConfig) => {
    setModalInfo((prev) => ({
      ...prev,
      open: true,
      title: 'Mostrar Detalhes',
      content: (
        <div className='PhysicalAssessmentChart-Modal'>
          <Line data={chartConfig} options={options} />
        </div>
      ),
    }));
  };

  return (
    <div
      className='PhysicalAssessmentChart-Grid-Chart-Container'
      onClick={() => handleShowDetailsModal(chartData)}
    >
      <div className='PhysicalAssessmentChart-ChartOverlay'>
        <p>Mostrar Detalhes</p>
      </div>
      <div className='PhysicalAssessmentChart'>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
}
