import './Eliminations.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChartIcon from '../../../../../../../../component/icon/chart';
import ListIcon from '../../../../../../../../component/icon/list';
import NewTaskIcon from '../../../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { AddAlert, AlertType } from '../../../../../../../../component';
import TableElimination from './subcomponents/TableElimination/TableElimination';
import { getElimination } from '../../../../../../http';
import NewEditElimination from './subcomponents/NewEditElimination/NewEditElimination';
import DeleteElimination from './subcomponents/DeleteElimination/DeleteElimination';


export default function Eliminations({ person, attendanceID=null, }) {
  const dispatch = useDispatch()
  const [eliminationsHistory, setEliminationsHistory] = useState([])
  //const [openSection, setOpenSection] = useState('TableElimination')
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

  useEffect(() => {
    fetchEliminationsHistory({ offset: 0 })
  }, [])

  const fetchEliminationsHistory = async (params={}) => {
    params = { ...params, "active": true, "person__id": person?.id }
    
    try {
      let res = await getElimination(params)
      setEliminationsHistory(res.data.results)
      setPagination({
        totalElements: res.data.count,
        totalPages: Math.ceil(res.data.count / 10),
        pageNumber: params.offset / 10,
      })
    } catch (err) {
      dispatch(AddAlert('Eliminações', 'Falha ao obter  eliminações', AlertType.ERROR))
      console.error('Eliminações ~ fetchEliminationsHistory ~ ', err)
    }
  }

  const extraAction = async () => {
    await fetchEliminationsHistory({ offset: 0, active: true })
    setModalInfo(MODAL_INIT_PROPS)
  }
  
  const openNewEditEliminationsModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Eliminações',
      content: <NewEditElimination
        initElimination={cursor}
        person={person}
        attendanceID={attendanceID}
        extraAction={extraAction} 
      />
    }))
  }
  
  const openDeleteEliminationsModal = (cursor) => {
    setModalInfo(prev => ({
      ...prev,
      open: true,
      title: 'Deleção de Eliminações',
      content: <DeleteElimination
        elimination={cursor} 
        extraAction={extraAction}
      />
    }))
  }

  const sections = {
    'ShowEliminations': /*<ShowEliminations 
      eliminationsHistory={eliminationsHistory}
      fetchEliminationsHistory={fetchEliminationsHistory}
      setModalInfo={setModalInfo} 
    />,*/ null,
    'TableElimination': <TableElimination 
      eliminationsHistory={eliminationsHistory}
      fetchEliminationsHistory={fetchEliminationsHistory} 
      openNewEditEliminationsModal={openNewEditEliminationsModal}
      openDeleteEliminationsModal={openDeleteEliminationsModal}
      pagination={pagination}
    />
  }

  return (
    <div>
      <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
      <div className='Eliminations-ActionRow'>
        <div
          onClick={() => openNewEditEliminationsModal()}
          title='Nova eliminação'
        >
          <NewTaskIcon />
        </div>
        {/*openSection === 'ShowEliminations' ? (
          <div onClick={() => setOpenSection('TableElimination')} title='Ver Dados'>
            <ListIcon />
          </div>
        ) : (
          <div onClick={() => setOpenSection('ShowEliminations')} title='Ver Gráficos'>
            <ChartIcon />
          </div>
        )*/}
      </div>
      <div>
        { sections['TableElimination'] }
      </div>
    </div>
  );
}
