import './GeneralInformation.css'
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import { strpGeneralInfo } from './utils';
import { getGeneralInfo, putGeneralInfo } from '../../../../../../http';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { AddAlert, AlertType } from '../../../../../../../../component';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';


export default function GeneralInformation({ person, attendanceID=null, }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [holdingGeneralInfo, setHoldingGeneralInfo] = useState(strpGeneralInfo())
    
    useEffect(() => {
        fetchHoldingGeneralInfo()
    }, [person])

    const fetchHoldingGeneralInfo = async () => {
        try {
            let res = await getGeneralInfo(person?.id)
            setHoldingGeneralInfo(strpGeneralInfo(res.data))
        } catch (err) {
            console.error('')
        }
    }
    const saveGeneralInfo = async (extraPayload={}) => {
        const payload = { ...holdingGeneralInfo, "attendance": attendanceID, "person": person, ...extraPayload }
        dispatch(loading())
        try {
            await putGeneralInfo(payload)
            dispatch(AddAlert('Informações gerais', 'Informações gerais salvas com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            console.error('saveGeneralInfo', err)
        }
        dispatch(loaded())
    }


    const handleSelect = (event) => {
        setHoldingGeneralInfo(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            saveGeneralInfo({ [event.target.id]: event.target.selected })
        }, 500)
    }


    const handleChangeUseOfControlledMedicationsTextField = (fieldType, value) => {
        setHoldingGeneralInfo(prev => ({ ...prev, [fieldType]: value }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            saveGeneralInfo({ [fieldType]: value })
        }, 500)
    };

    return <div className='GeneralInformation'>
        <div className="GeneralInformation-RHFactor">
            <div className='GeneralInformation-Select'>
                <b>Grupo ABO</b>
                <NewSelect
                    id='abo_group'
                    options={[
                        { id: 'NOT_EVALUATED', name: 'Não avaliado' },
                        { id: 'A', name: 'A' },
                        { id: 'B', name: 'B' },
                        { id: 'AB', name: 'AB' },
                        { id: 'O', name: 'O' },
                    ]}
                    onSelect={handleSelect}
                    selected={holdingGeneralInfo.abo_group}
                    canBeEmpty={false}
                />
            </div>
            <div className='GeneralInformation-Select'>
                <b>Fator RH</b>
                <NewSelect 
                    id='rh_factor'
                    options={[
                        { id: 'NOT_EVALUATED', name: 'Não avaliado' },
                        { id: 'POSITIVE', name: '+' },
                        { id: 'NEGATIVE', name: '-' },
                    ]}
                    onSelect={handleSelect}
                    selected={holdingGeneralInfo.rh_factor}
                    canBeEmpty={false}
                />
            </div>
        </div>
        <div>
            <p><b>Doenças familiares</b></p>
            <ReactQuill
                id='family_illnesses'
                theme="snow"
                value={holdingGeneralInfo.family_illnesses}
                onChange={(text) => handleChangeUseOfControlledMedicationsTextField('family_illnesses', text)}
                //onBlur={(previousRange, source, editor) => handleBlurAppointmentTextField(field_type, editor.getHTML())}
            />
        </div>
        <div>
            <p><b>Uso de medicamentos controlados</b></p>
            <ReactQuill
                id='use_of_controlled_medications'
                theme="snow"
                value={holdingGeneralInfo.use_of_controlled_medications}
                onChange={(text) =>  handleChangeUseOfControlledMedicationsTextField('use_of_controlled_medications', text)}
                //onBlur={(previousRange, source, editor) => handleBlurAppointmentTextField(field_type, editor.getHTML())}
            />
        </div>
    </div>
}
