import './VisualAcuity.css'
import React from 'react'
import { Input, maskSnellenChart } from '../../../../../../../../../../component'

export default function VisualAcuity({ 
    handleChange, 
    holdingOphthalmology, 
    holdingOphthalmologyErr,
    isEditable=true
}) {
    
    return <div>
        <p><b>Acuidade Visual</b></p>
        <div className='VisualAcuity-Header'>
            <span>Olho direito</span>
            <span>Olho esquerdo</span>
        </div>
        <div className='VisualAcuity-Grid'>

            <Input
                label='SC'
                name='right_eye_visual_acuity_sc'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.right_eye_visual_acuity_sc)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                label='SC'
                name='left_eye_visual_acuity_sc'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.left_eye_visual_acuity_sc)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                label='CC'
                name='right_eye_visual_acuity_cc'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.right_eye_visual_acuity_cc)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                label='CC'
                name='left_eye_visual_acuity_cc'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.left_eye_visual_acuity_cc)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
        </div>
    </div>
}