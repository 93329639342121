import React, { useEffect, useState } from 'react'

import './component-menu.css'

export default function ComponentMenu ({items, setComponent}) {

    const [selectedItem, setSelectedItem] = useState(items[0])

    const handleMenuSelection = (item) => {
        setSelectedItem(item)
        setComponent(item.component)
    }

    const itemClasses = (item) => "component-menu-item" +
        `${selectedItem.label == item.label ? ' selected' : ""}`

    return <div className='component-menu'>
        {
            items.map(item => {
                return <div 
                    className={itemClasses(item)}
                    onClick={() => handleMenuSelection(item)}
                >
                    {item.label}
                </div>
            })
        }
    </div>
}