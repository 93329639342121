import './NewEditChronicConditions.css'
import React, { useState } from 'react'
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import { AddAlert, AlertType } from '../../../../../../../../../../component'
import { putChronicCondition } from '../../../../../../../../http'
import NewButton from '../../../../../../../../../../newcomponents/button/button'
import { strpChronicCondition } from '../../utils'

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')


export default function NewEditChronicConditions({ person, attendanceID=null, initChronicCondition=null, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const [holdingChronicCondition, setHoldingChronicCondition] = useState(strpChronicCondition(initChronicCondition))

    const saveChronicCondition = async () => {
        const payload = { ...holdingChronicCondition, "attendance": attendanceID, "person": person }
        dispatch(loading())
        try {
            await putChronicCondition(payload)
            dispatch(AddAlert('Condições Crônicas', 'Condições Crônicas salvos com sucesso!', AlertType.SUCCESS))
            extraAction(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveChronicCondition', err)
        }
        dispatch(loaded())
    }

    const handleSelect = (event) => {
        setHoldingChronicCondition(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    return <div className='NewEditChronicConditions'>
        <div className='NewEditChronicConditions-Grid'>
            <div className='NewEditChronicConditions-Select'>
                <b>Diabetes</b>
                <div >
                    <NewSelect
                        id='diabetes'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.diabetes}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditChronicConditions-Select'>
                <b>Uso de insulina</b>
                <div >
                    <NewSelect
                        id='diabetes_insulin'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.diabetes_insulin}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditChronicConditions-Select'>
                <b>Hipertensão</b>
                <div>
                    <NewSelect
                        id='hypertension'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.hypertension}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditChronicConditions-Select'>
                <b>Doenças cardíacas</b>
                <div>
                    <NewSelect
                        id='heart_disease'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.heart_disease}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditChronicConditions-Select'>
                <b>Colesterol alto</b>
                <div>
                    <NewSelect
                        id='high_total_cholesterol'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.high_total_cholesterol}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditChronicConditions-Select'>
                <b>Tiglicerídeos altos</b>
                <div>
                    <NewSelect
                        id='high_triglycerides'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.high_triglycerides}
                        canBeEmpty={false}
                    />
                </div>
            </div>
            <div className='NewEditChronicConditions-Select'>
                <b>Problemas musculoesqueléticos</b>
                <div>
                    <NewSelect
                        id='musculoskeletal'
                        options={defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED}
                        onSelect={handleSelect}
                        selected={holdingChronicCondition.musculoskeletal}
                        canBeEmpty={false}
                    />
                </div>
            </div>
        </div>
        <div className='NewEditChronicConditions-BtnBox'>
            <NewButton 
                onClick={saveChronicCondition}
                label='Salvar Condição Crônica'
            />
        </div>
    </div>
}