import './LifeHabitsAndBehaviorChart.css';
import React from 'react';
import {
  Chart,
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { MaskDataHora } from '../../../../../../../../../../component';

Chart.register(
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  Title
);

export default function LifeHabitsAndBehaviorChart({ condition, data, setModalInfo = () => null }) {
  const categoryMappings = {
    ONLY_YES_NO_OR_NOT_EVALUATED: {
      'NOT_EVALUATED': 0,
      'NO': 1,
      'YES': 2,
    },
    PHYSICAL_ACTIVITY_FREQUENCY_CHOICES: {
      'NOT_EVALUATED': 0,
      'NOT_APPLY': 1,
      'LESS_THAN_ONCE_A_WEEK': 2,
      'WEEKNDS': 3,
      '3_TIMES_A_WEEK': 4,
      '30_MINUTES_A_DAY': 5,
    },
    CONSUMPTION_OF_ALCOHOLIC_FREQUENCY_OR_NOT_EVALUATED: {
      'NOT_EVALUATED': 0,
      'NEVER': 1,
      'LESS_THAN_ONCE_A_WEEK': 2,
      '1_TIME_A_WEEK': 3,
      '2_TIMES_A_WEEK': 4,
      '3_TIMES_A_WEEK': 5,
      '4_TIMES_A_WEEK': 6,
      '5_TIMES_A_WEEK': 7,
      '6_TIMES_A_WEEK': 8,
      'DAILY': 9,
    },
  };

  const reverseCategoryMappings = {
    ONLY_YES_NO_OR_NOT_EVALUATED: {
      0: 'Não avaliado',
      1: 'Não',
      2: 'Sim',
    },
    PHYSICAL_ACTIVITY_FREQUENCY_CHOICES: {
      0: 'Não avaliado',
      1: 'Não se aplica',
      2: 'Menos de uma vez/semana',
      3: 'Fins de semana',
      4: '1 hora - 3 vezes/semana',
      5: '30 minutos/dia',
    },
    CONSUMPTION_OF_ALCOHOLIC_FREQUENCY_OR_NOT_EVALUATED: {
      0: 'Não avaliado',
      1: 'Nunca',
      2: 'Menos de 1 vez/semana',
      3: '1 vez/semana',
      4: '2 vezes/semana',
      5: '3 vezes/semana',
      6: '4 vezes/semana',
      7: '5 vezes/semana',
      8: '6 vezes/semana',
      9: 'Diariamente',
    },
  };

  const mappingType = condition.mappingType;

  const categoryMapping = categoryMappings[mappingType];
  const reverseCategoryMapping = reverseCategoryMappings[mappingType];

  const labels = data.map((entry) => MaskDataHora(entry?.date)?.data || entry?.date);
  const numericData = data.map((entry) => categoryMapping[entry.value]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: condition.label,
        data: numericData,
        fill: false,
        borderColor: condition.color,
        tension: 0.1,
        pointBackgroundColor: condition.color,
        spanGaps: true,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
          callback: function (value) {
            return reverseCategoryMapping[value];
          },
        },
        title: {
          display: true,
          text: condition.label,
        },
        min: Math.min(...Object.values(categoryMapping)),
        max: Math.max(...Object.values(categoryMapping)),
      },
      x: {
        title: {
          display: true,
          text: 'Data',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            return `${condition.label}: ${reverseCategoryMapping[value]}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const handleShowDetailsModal = (chartConfig) => {
    setModalInfo((prev) => ({
      ...prev,
      open: true,
      title: 'Mostrar Detalhes',
      content: (
        <div className='LifeHabitsAndBehaviorChart-Modal'>
          <Line data={chartConfig} options={options} />
        </div>
      ),
    }));
  };

  return (
    <div
      className='LifeHabitsAndBehaviorChart-Grid-Chart-Container'
      onClick={() => handleShowDetailsModal(chartData)}
    >
      <div className='LifeHabitsAndBehaviorChart-ChartOverlay'>
        <p>Mostrar Detalhes</p>
      </div>
      <div className='LifeHabitsAndBehaviorChart'>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
}
