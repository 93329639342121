export const handleSignal = (number) => {
    if (number > 0 && !number.includes('+'))  {
        return `+${number}`
    }
    return number
}

export const handleCDegree = (value) => {
    if (value > 0) return value * -1
    return value
}

export const handleAxis = (value) => {
    if (value < 0) return 0
    if (value > 180) return 180
    return value
}

export const strpOphthalmology = (holdingOphthalmology=null) => {

    return {
        id: holdingOphthalmology?.id || null,
        appointment: holdingOphthalmology?.appointment || null,
        
        right_eye_visual_acuity_sc: holdingOphthalmology?.right_eye_visual_acuity_sc || '',
        right_eye_visual_acuity_cc: holdingOphthalmology?.right_eye_visual_acuity_cc || '',
        left_eye_visual_acuity_sc: holdingOphthalmology?.left_eye_visual_acuity_sc || '',
        left_eye_visual_acuity_cc: holdingOphthalmology?.left_eye_visual_acuity_cc || '',
        
        right_eye_dynamic_refraction_e_degree: holdingOphthalmology?.right_eye_dynamic_refraction_e_degree || '',
        right_eye_dynamic_refraction_c_degree: holdingOphthalmology?.right_eye_dynamic_refraction_c_degree || '',
        right_eye_dynamic_refraction_axis: holdingOphthalmology?.right_eye_dynamic_refraction_axis || '',
        right_eye_dynamic_refraction_vision: holdingOphthalmology?.right_eye_dynamic_refraction_vision || '',
        left_eye_dynamic_refraction_e_degree: holdingOphthalmology?.left_eye_dynamic_refraction_e_degree || '',
        left_eye_dynamic_refraction_c_degree: holdingOphthalmology?.left_eye_dynamic_refraction_c_degree || '',
        left_eye_dynamic_refraction_axis: holdingOphthalmology?.left_eye_dynamic_refraction_axis || '',
        left_eye_dynamic_refraction_vision: holdingOphthalmology?.left_eye_dynamic_refraction_vision || '',

        right_eye_static_refraction_e_degree: holdingOphthalmology?.right_eye_static_refraction_e_degree || '',
        right_eye_static_refraction_c_degree: holdingOphthalmology?.right_eye_static_refraction_c_degree || '',
        right_eye_static_refraction_axis: holdingOphthalmology?.right_eye_static_refraction_axis || '',
        right_eye_static_refraction_vision: holdingOphthalmology?.right_eye_static_refraction_vision || '',
        left_eye_static_refraction_e_degree: holdingOphthalmology?.left_eye_static_refraction_e_degree || '',
        left_eye_static_refraction_c_degree: holdingOphthalmology?.left_eye_static_refraction_c_degree || '',
        left_eye_static_refraction_axis: holdingOphthalmology?.left_eye_static_refraction_axis || '',
        left_eye_static_refraction_vision: holdingOphthalmology?. left_eye_static_refraction_vision || '',
    
        right_eye_pressure: holdingOphthalmology?.right_eye_pressure || '',
        left_eye_pressure: holdingOphthalmology?.left_eye_pressure || '',

        biomicroscopy_is_normal: holdingOphthalmology?.biomicroscopy_is_normal || 'IS_NORMAL',
        right_eye_biomicroscopy_anterior_chamber: holdingOphthalmology?.right_eye_biomicroscopy_anterior_chamber || '',
        right_eye_biomicroscopy_crystalline: holdingOphthalmology?.right_eye_biomicroscopy_crystalline || '',
        right_eye_biomicroscopy_cornea: holdingOphthalmology?.right_eye_biomicroscopy_cornea || '',
        right_eye_biomicroscopy_conjunctiva: holdingOphthalmology?.right_eye_biomicroscopy_conjunctiva || '',
        left_eye_biomicroscopy_anterior_chamber: holdingOphthalmology?.left_eye_biomicroscopy_anterior_chamber || '',
        left_eye_biomicroscopy_crystalline: holdingOphthalmology?.left_eye_biomicroscopy_crystalline || '',
        left_eye_biomicroscopy_cornea: holdingOphthalmology?.left_eye_biomicroscopy_cornea || '',
        left_eye_biomicroscopy_conjunctiva: holdingOphthalmology?.left_eye_biomicroscopy_conjunctiva || '',

        funduscopy_is_normal: holdingOphthalmology?.funduscopy_is_normal || 'IS_NORMAL',
        right_eye_funduscopy_macular_region: holdingOphthalmology?.right_eye_funduscopy_macular_region || '',
        right_eye_funduscopy_periphery: holdingOphthalmology?.right_eye_funduscopy_periphery || '',
        right_eye_funduscopy_vitreous: holdingOphthalmology?.right_eye_funduscopy_vitreous || '',
        right_eye_funduscopy_excavation: holdingOphthalmology?.right_eye_funduscopy_excavation || '',
        left_eye_funduscopy_macular_region: holdingOphthalmology?.left_eye_funduscopy_macular_region || '',
        left_eye_funduscopy_periphery: holdingOphthalmology?.left_eye_funduscopy_periphery || '',
        left_eye_funduscopy_vitreous: holdingOphthalmology?.left_eye_funduscopy_vitreous || '',
        left_eye_funduscopy_excavation: holdingOphthalmology?.left_eye_funduscopy_excavation || '',        
        notes: holdingOphthalmology?.notes || '',
    }
}

export const parseOphthalmology = (holdingOphthalmology=null) => {

    return {
        id: holdingOphthalmology?.id || null,
        appointment: holdingOphthalmology?.appointment || null,
        
        right_eye_visual_acuity_sc: holdingOphthalmology?.right_eye_visual_acuity_sc || '',
        right_eye_visual_acuity_cc: holdingOphthalmology?.right_eye_visual_acuity_cc || '',
        left_eye_visual_acuity_sc: holdingOphthalmology?.left_eye_visual_acuity_sc || '',
        left_eye_visual_acuity_cc: holdingOphthalmology?.left_eye_visual_acuity_cc || '',
        
        right_eye_dynamic_refraction_e_degree: holdingOphthalmology?.right_eye_dynamic_refraction_e_degree || '',
        right_eye_dynamic_refraction_c_degree: holdingOphthalmology?.right_eye_dynamic_refraction_c_degree || '',
        right_eye_dynamic_refraction_axis: holdingOphthalmology?.right_eye_dynamic_refraction_axis || '',
        right_eye_dynamic_refraction_vision: holdingOphthalmology?.right_eye_dynamic_refraction_vision || '',
        left_eye_dynamic_refraction_e_degree: holdingOphthalmology?.left_eye_dynamic_refraction_e_degree || '',
        left_eye_dynamic_refraction_c_degree: holdingOphthalmology?.left_eye_dynamic_refraction_c_degree || '',
        left_eye_dynamic_refraction_axis: holdingOphthalmology?.left_eye_dynamic_refraction_axis || '',
        left_eye_dynamic_refraction_vision: holdingOphthalmology?.left_eye_dynamic_refraction_vision || '',

        right_eye_static_refraction_e_degree: holdingOphthalmology?.right_eye_static_refraction_e_degree || '',
        right_eye_static_refraction_c_degree: holdingOphthalmology?.right_eye_static_refraction_c_degree || '',
        right_eye_static_refraction_axis: holdingOphthalmology?.right_eye_static_refraction_axis || '',
        right_eye_static_refraction_vision: holdingOphthalmology?.right_eye_static_refraction_vision || '',
        left_eye_static_refraction_e_degree: holdingOphthalmology?.left_eye_static_refraction_e_degree || '',
        left_eye_static_refraction_c_degree: holdingOphthalmology?.left_eye_static_refraction_c_degree || '',
        left_eye_static_refraction_axis: holdingOphthalmology?.left_eye_static_refraction_axis || '',
        left_eye_static_refraction_vision: holdingOphthalmology?. left_eye_static_refraction_vision || '',
    
        right_eye_pressure: holdingOphthalmology?.right_eye_pressure || '',
        left_eye_pressure: holdingOphthalmology?.left_eye_pressure || '',

        biomicroscopy_is_normal: holdingOphthalmology?.biomicroscopy_is_normal?.id || 'IS_NORMAL',
        right_eye_biomicroscopy_anterior_chamber: holdingOphthalmology?.right_eye_biomicroscopy_anterior_chamber || '',
        right_eye_biomicroscopy_crystalline: holdingOphthalmology?.right_eye_biomicroscopy_crystalline || '',
        right_eye_biomicroscopy_cornea: holdingOphthalmology?.right_eye_biomicroscopy_cornea || '',
        right_eye_biomicroscopy_conjunctiva: holdingOphthalmology?.right_eye_biomicroscopy_conjunctiva || '',
        left_eye_biomicroscopy_anterior_chamber: holdingOphthalmology?.left_eye_biomicroscopy_anterior_chamber || '',
        left_eye_biomicroscopy_crystalline: holdingOphthalmology?.left_eye_biomicroscopy_crystalline || '',
        left_eye_biomicroscopy_cornea: holdingOphthalmology?.left_eye_biomicroscopy_cornea || '',
        left_eye_biomicroscopy_conjunctiva: holdingOphthalmology?.left_eye_biomicroscopy_conjunctiva || '',

        funduscopy_is_normal: holdingOphthalmology?.funduscopy_is_normal?.id || 'IS_NORMAL',
        right_eye_funduscopy_macular_region: holdingOphthalmology?.right_eye_funduscopy_macular_region || '',
        right_eye_funduscopy_periphery: holdingOphthalmology?.right_eye_funduscopy_periphery || '',
        right_eye_funduscopy_vitreous: holdingOphthalmology?.right_eye_funduscopy_vitreous || '',
        right_eye_funduscopy_excavation: holdingOphthalmology?.right_eye_funduscopy_excavation || '',
        left_eye_funduscopy_macular_region: holdingOphthalmology?.left_eye_funduscopy_macular_region || '',
        left_eye_funduscopy_periphery: holdingOphthalmology?.left_eye_funduscopy_periphery || '',
        left_eye_funduscopy_vitreous: holdingOphthalmology?.left_eye_funduscopy_vitreous || '',
        left_eye_funduscopy_excavation: holdingOphthalmology?.left_eye_funduscopy_excavation || '',        
        notes: holdingOphthalmology?.notes || '',
    }
}