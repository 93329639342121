import './Conversation.css'
import CancelIcon from '../../../../../component/icon/cancel'
import SendIcon from '../../../../../component/icon/send'
import { useEffect, useRef, useState } from 'react'
import api from '../../../../../helpers/api'
import { useApp } from '../../../../../layout/App'
import InfoOutlinedIcon from '../../../../../component/icon/info-outlined'

export default function Conversation ({ chat=null, handleSelectChat=() => null, messagesInfo=null, updateLastMessageRead=()=> null, sendNotification=()=> null }) {
    const [newMessage, setNewMessage] = useState('')
    const [messages, setMessages] = useState([]);
    const [chatInfoDialog, setChatInfoDialog] = useState(false)
    const messageQueue = useRef([]);
    const pendingReadMessages = useRef(null)
    const chatContentRef = useRef(null);
    const { currentHealthPlaceUser } = useApp()    
    const ws = useRef(null)

    const [page, setPage] = useState(2);
    const [hasMore, setHasMore] = useState(true)
    
    useEffect(() => {
        if (chat.id && localStorage.token) {
            connectWebSocket()
            fetchMessages();
            
            return () => {
                if (ws.current) {
                    ws.current.close();
                }
            };
        }
    }, [chat.id]);

    useEffect(() => {
        if (messagesInfo && messagesInfo.unread_count !== 0) {
            updateLastMessageRead(chat.id, messagesInfo)
        }
    }, [messagesInfo])

    useEffect(() => {
        if (messages && messages.length > 0) {
            const lastMessageId = messages[messages.length - 1].id;
            markMessagesAsRead(lastMessageId);
        }
    }, [messages]);
    
    const getParticipantNameOtherThanMe = () => {
        const otherParticipant = chat.participants?.find(participant => participant.id !== currentHealthPlaceUser.user.id)
        return otherParticipant?.person?.name
    }
    
    const connectWebSocket = () => {
        const wsScheme = process.env.NODE_ENV === "production" ? "wss" : "ws";
        const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
          ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
          : window.location.hostname;
        
        if (ws.current) {
          ws.current.close();
        }
        
        if (localStorage.token) {

        ws.current = new WebSocket(`${wsScheme}://${wsHost}/ws/chat/connection/${chat.id}/?authorization=Token ${localStorage.token}`)
    
        ws.current.onopen = () => {
            console.log('WebSocket connected')

            while (messageQueue.current.length > 0) {
            const messageData = messageQueue.current.shift()
            ws.current.send(JSON.stringify(messageData))
            }

            const pingInterval = setInterval(() => {
                if (ws.current.readyState === WebSocket.OPEN) {
                    ws.current.send(JSON.stringify({ type: 'ping' }));
                }
            }, 10000)

            if (pendingReadMessages.current) {
                const lastMessageId = pendingReadMessages.current
                markMessagesAsRead(lastMessageId);

                pendingReadMessages.current = null
            }

            ws.current.onclose = () => clearInterval(pingInterval);
        }
        
        ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === "chat_file_message") {
            fetchMessages()
            } else if (data.type === "chat_message") {
            handleNewMessage(data.message);
            } else if (data.type === 'last_read_update') {
                updateLastMessageRead(chat.id, data)
            } else if (data.type === 'pong') {
                console.log('Received pong - Conversation', chat.id)
            }
        }
    
        ws.current.onclose = () => {
            console.log('WebSocket disconnected, reconnecting...')
            setTimeout(connectWebSocket, 5000)
        }
        }
    }

    const markMessagesAsRead = (lastReadMessageId) => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({
                type: 'mark_as_read',
                last_read_message_id: lastReadMessageId,
            }));
        } else {
            console.log("WebSocket não está aberto. Adicionando à fila de leitura pendente:", lastReadMessageId);
            pendingReadMessages.current = lastReadMessageId
        }
    }

      const groupMessagesByDate = (messages) => {
        return messages.reduce((groupedMessages, message) => {
        
            const date = new Date(message.timestamp)

            const messageDate = date.toLocaleDateString();
            const messageTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            const formattedMessage = {...message, time: messageTime}
    
            if (!groupedMessages[messageDate]) {
                groupedMessages[messageDate] = [];
            }

            groupedMessages[messageDate].push(formattedMessage);
            return groupedMessages;
        }, {});
      }

      const fetchMessages = async () => {
        try {
            const response = await api.get(`chat/messages/?chat_id=${chat?.id}&page=1`);
            setMessages(response.data.results.reverse())

            console.log(response)
            console.log(!response?.data?.next)
            if (!response?.data?.next) {
                setHasMore(false)
            }

            scrollToBottom();
        } catch (error) {
            console.error("Failed to fetch messages:", error);
        }
      };

      const handleNewMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
        scrollToBottom();
      }

      const sendMessage = () => {
          if (newMessage.trim()) {
          const messageData = {
            type: "CHAT_MESSAGE",
            message: newMessage.replace(/^\n+|\n+$/g, ''),
            sender: currentHealthPlaceUser.user.id,
            chat_room: chat.id,
          }
      
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(messageData));
          } else {
            messageQueue.current.push(messageData)
          }
          setNewMessage("")
          sendNotification({chat_id: chat.id})
        }
      }

      const scrollToBottom = () => {
        if (chatContentRef.current) {
          chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }

    const handleLoadMessages = async (e) => {
        const currentScrollTop = chatContentRef.current.scrollTop;
        const currentScrollHeight = chatContentRef.current.scrollHeight;
    
        await loadOlderMessages();
    

        const newScrollHeight = chatContentRef.current.scrollHeight;
        const scrollOffset = newScrollHeight - currentScrollHeight;
    
        chatContentRef.current.scrollTop = currentScrollTop + scrollOffset;

    }

    const loadOlderMessages = async () => {
        try {
            const response = await api.get(`chat/messages/?chat_id=${chat?.id}&page=${page}`)
    
            if (response.status === 200) {

                setMessages((prevMessages) => {
                    const messagesUpdated = [...response.data.results.reverse(), ...prevMessages]

                    return messagesUpdated 
                })

                setPage((prevPage) => prevPage + 1);
    
                if (!response?.data?.next) {
                    setHasMore(false)
                }
            }
        } catch (error) {
            console.error("Erro ao carregar mensagens mais antigas:", error);
        }
    }

    return(
        <div className="Conversation">
            <div className='Conversation-header'>
                <div className='Conversation-Title-Box'>
                    <h1 className='Conversation-Title'>{chat.is_group ? chat.name : getParticipantNameOtherThanMe() }</h1>
                    {chat.is_group 
                        ? <div onMouseOver={() => setChatInfoDialog(true)} onMouseOut={() => setChatInfoDialog(false)}>
                            <InfoOutlinedIcon style={{ width: '15px', height: '15px', fill: 'white' }} />
                        </div>
                        : null
                    }

                    {chatInfoDialog && chat.is_group && (
                        <div className='Conversation-Dialog-Info'>
                            <p>Participantes:</p>
                            {chat.participants.map(participant => <span>{participant.person.name}</span>)}
                        </div>
                    )}

                </div>
                <button onClick={() => handleSelectChat()} className='Conversation-CloseBtn'>
                    <CancelIcon width={15} height={15} color={'white'}/>
                </button>
            </div>
            <div className='Conversation-Chat' ref={chatContentRef} onScroll={() => {}}>
                {hasMore && messages.length > 0 && (
                    <button onClick={handleLoadMessages} className='Conversation-LoadConversationBtn'>Ver mensagens anteriores ⬆</button>
                )}
  
                {Object.entries(groupMessagesByDate(messages)).map(([date, messagesForDate], index) => (
                    <div key={index}>

                        <div className="Conversation-Chat-Date-Separator">{date}</div>

                        <div className='Conversation-Date-Messages'>
                            {messagesForDate.map((message, messageIndex) => (
                                message?.sender === currentHealthPlaceUser.user.id
                                ? <div key={messageIndex} className='Conversation-Chat-MessageBox'>
                                    <div className="Conversation-Chat-Message MyMessage">{message.content}</div>
                                    <div className='Conversation-Chat-InfoMessage MyInfoMessage'>{message.sender_name} - {message.time}</div>
                                </div>
                                : <div key={messageIndex} className='Conversation-Chat-MessageBox '>
                                    <div className="Conversation-Chat-Message OthersMessage">{message.content}</div>
                                    <div className='Conversation-Chat-InfoMessage OthersInfoMessage'>{message.time} - {message.sender_name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

            </div>
            <div className='Conversation-Input-Box'>
                <div className='Conversation-Input'>
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault()
                                sendMessage();
                            }
                        }}
                        placeholder='Escreva uma mensagem'
                    />
                </div>
                <button className='Conversation-SendBtn' onClick={() => sendMessage()}>
                    <SendIcon style={{'width': 20, 'height': 20}}/>
                </button>
            </div>
        </div>
    )
}