import './TimeSchedule.css'
import React, { useEffect, useRef, useState } from 'react'
import { useBookingAppointmentCursor } from '../../BookingAppointmentCursor'
import RenderCurrentTimeLine from './subcomponents/RenderCurrentTimeLine/RenderCurrentTimeLine'
import RenderAppointments from './subcomponents/RenderAppointments/RenderAppointments'
import RenderAppointmentsCheckbox from './subcomponents/RenderAppointmentsCheckbox/RenderAppointmentsCheckbox'
import { NewCheckbox } from '../../../../../../../../newcomponents'
import IcoSchedule from '../../../../../../../../component/icon/schedule'
import CreateSchedulesModal from '../CreateSchedulesModal/CreateSchedulesModal'
import PrinterIcon from '../../../../../../../../component/icon/newPrinter'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { buildDate, formatDateWithTimezone } from '../../../../../../../../utils/convertDate'
import { postQuickEmission } from '../../../../../../http'
import IcoCalendarLoop from '../../../../../../../../component/icon/calendar_loop'
import RecurringPatientModal from './subcomponents/RecurringPatientModal/RecurringPatientModal'
import { parsePrintReportParams } from '../../../../../../helpers'
import AllowPopUpRedictModal from '../../../../../AttendanceHistory/subcomponents/AllowPopUpRedictModal/AllowPopUpRedictModal'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import GlobalCheckbox from '../../../../../../../../newcomponents/GlobalCheckbox/GlobalCheckbox'
import { IcoArrowBack } from '../../../../../../../../component'
import { IcoArrowForward } from '../../../../../../../../component'

const HOUR_LABELS = [
  '01:00', '02:00', '03:00', '04:00', '05:00', '06:00',
  '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
  '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
  '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'
]

const getDayOfWeek = (day) => {
  const daysOfWeek = {
    'dom.': 'DOM',
    'seg.': 'SEG',
    'ter.': 'TER',
    'qua.': 'QUA',
    'qui.': 'QUI',
    'sex.': 'SEX',
    'sáb.': 'SAB'
  };
  const dayAbbreviation = day.toLocaleDateString('pt-BR', { weekday: 'short' }).toLowerCase();
  return daysOfWeek[dayAbbreviation] || dayAbbreviation;
}

const wsScheme = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST : window.location.hostname

export default function TimeSchedule({ displayMode = 'daily', setCalendarCursor }) {
  const dispatch = useDispatch()
  const timeScheduleGridRef = useRef(null)
  const {
    selectedHealthProfessional,
    calendarCursor,
    openAvailableScheduleModal,
    setModalInfo,
    selectedTimesCursor, setSelectedTimesCursor, filledTimesOfTheDay //checkAll 
  } = useBookingAppointmentCursor()

  // checkAll
  const isAllSelected = filledTimesOfTheDay.horarios.every((horario) =>
    selectedTimesCursor.some((selected) => selected.pk === horario.pk)
  );

  const isDisabled = filledTimesOfTheDay.horarios.length === 0;

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedTimesCursor((prev) => {
        const newTimes = filledTimesOfTheDay.horarios.filter(
          (horario) => !prev.some((selected) => selected.pk === horario.pk)
        );
        return [...prev, ...newTimes];
      });
    } else {
      setSelectedTimesCursor((prev) => {
        return prev.filter(
          (selected) => !filledTimesOfTheDay.horarios.some((horario) => horario.pk === selected.pk)
        );
      });
    }
  };
  //checkAll - end


  useEffect(() => {
    const calculateScrollPosition = () => {
      const now = new Date();
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      const diffInMilliseconds = new Date() - startOfDay;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      const hourBlockHeight = 72;
      return diffInHours * hourBlockHeight;
    };

    if (timeScheduleGridRef.current) {
      const scrollPosition = calculateScrollPosition();

      timeScheduleGridRef.current.scrollTop = scrollPosition - (window.innerHeight / 2);
    }
  }, []);



  const isActiveDay = (day) => {
    const selected = calendarCursor.selectedDay;
    return day.getDate() === selected.getDate() &&
      day.getMonth() === selected.getMonth() &&
      day.getFullYear() === selected.getFullYear();
  }

  const getStartOfWeek = (date) => {
    const diff = date.getDate() - date.getDay();
    return new Date(date.setDate(diff));
  }

  const generateWeekDays = () => {
    let startDate = getStartOfWeek(new Date(calendarCursor.selectedDay));
    let weekDays = [];
    for (let i = 0; i < 7; i++) {
      let day = new Date(startDate);
      day.setDate(day.getDate() + i);
      weekDays.push(day);
    }
    return weekDays;
  }

  const weekDays = generateWeekDays();

  const openCreateSchedulesModal = () => {
    setModalInfo({
      open: true,
      title: 'Criar Horário(s)',
      content: <CreateSchedulesModal selectedDate={calendarCursor.selectedDay} />
    })
  }

  const openRecurringPatientModal = () => {
    setModalInfo({
      open: true,
      title: 'Paciente Recorrente',
      content: <RecurringPatientModal />
    })
  }

  const handleEmission = async () => {
    setModalInfo(MODAL_INIT_PROPS)
    let params = parsePrintReportParams({
      "doctor__id__in": [selectedHealthProfessional],
      "classification__in": [{ id: "ROUTINE", name: "Primeira Consulta" }, { id: "RETURN", name: "Consulta de Retorno" }],
      "start__gte": calendarCursor.selectedDay,
      "start__lte": calendarCursor.selectedDay
    })

    dispatch(loading());
    try {
      let response = await postQuickEmission({ params })
      const taskId = response.data.task_id
      const client = new WebSocket(`${wsScheme}://${wsHost}/ws/reports/dowload_pdf/${taskId}/`)

      client.onopen = () => {
        console.log('PDF Status WebSocket Client Connected');
      }

      client.onmessage = (message) => {
        dispatch(loaded())
        const dataFromServer = JSON.parse(message.data);
        const newWindow = window.open(dataFromServer.download_url)
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          console.log('teste1')
          setModalInfo(prev => ({
            ...prev,
            open: true,
            title: 'Pop-up e redirecionamento bloqueado',
            content: <AllowPopUpRedictModal onClick={handleEmission} download_url={dataFromServer.download_url} />
          }))
          console.log('teste2')
        } else {
          dispatch(AddAlert('Agenda', 'Agenda impressa com sucesso', AlertType.SUCCESS))
        }
        client.close()
      }
      dispatch(AddAlert('Agenda', 'Gerando PDF, aguarde um instante.', AlertType.INFO))
    } catch (err) {
      dispatch(AddAlert('Agenda', 'Não foi possível gerar a agenda', AlertType.ERROR))
    }
    dispatch(loaded())
  }

  // controlls selectedDay changes on the timeSchedule chart
  const selectDayOfTheWeek = (newSelectedDay) => {
    setCalendarCursor((prevCursor) => {
      const newMonth = newSelectedDay.getMonth();
      const newYear = newSelectedDay.getFullYear();

      return {
        ...prevCursor,
        selectedDay: newSelectedDay,
        monthSearchCursor: newMonth,
        yearSearchCursor: newYear,
      };
    });
  };

  const selectPreviousWeek = () => {
    const currentSelectedDay = calendarCursor.selectedDay;

    const previousWeekDate = new Date(currentSelectedDay);
    previousWeekDate.setDate(previousWeekDate.getDate() - 7);

    selectDayOfTheWeek(previousWeekDate);
  };

  const selectNextWeek = () => {
    const currentSelectedDay = calendarCursor.selectedDay;

    const nextWeekDate = new Date(currentSelectedDay);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);

    selectDayOfTheWeek(nextWeekDate);
  };

  return (
    <div>
      <div className='TimeSchedule-Header'>
        <div
          title='Criar horário(s)'
          className='TimeSchedule-CreateHour-Btn'
          onClick={openCreateSchedulesModal}
        >
          <IcoSchedule />
        </div>
        <div
          title='Paciente recorrente'
          className='TimeSchedule-CreateHour-Btn'
          onClick={openRecurringPatientModal}
        >
          <IcoCalendarLoop />
        </div>
        <div
          title='Imprimir agenda'
          className='TimeSchedule-CreateHour-Btn'
          onClick={handleEmission}
        >
          <PrinterIcon />
        </div>
      </div>

      <div className='TimeSchedule-Body'>
        <div className='TimeSchedule-Body-Header'>
          <div className='TimeSchedule-Body-Header-Cursor'>
            <div className="TimeSchedule-Body-Header-Cursor-AllCheckBox">
              <GlobalCheckbox
                isAllSelected={isAllSelected}
                isDisabled={isDisabled}
                onToggle={handleSelectAll}
              />
            </div>
          </div>
          <div className='TimeSchedule-Body-Header-CalendarRow'>
            <div
              className='TimeSchedule-Body-Header-Arrow'
              onClick={() => {
                selectPreviousWeek()
              }}
            >
              <IcoArrowBack />
            </div>

            {weekDays.map((day, index) => (
              <div
                key={index}
                className={`TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek ${isActiveDay(day) ? 'active' : ''}`}
                onClick={() => {
                  selectDayOfTheWeek(day);
                }}
              >
                <div className='TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek-Inner'>
                  <div>{getDayOfWeek(day)}</div>
                  <div>{day.getDate()}</div>
                </div>
              </div>
            ))}

            <div
              className='TimeSchedule-Body-Header-Arrow'
              onClick={() => {
                selectNextWeek();
              }}
            >
              <IcoArrowForward />
            </div>
          </div>
        </div>
        <div className="TimeSchedule-Body-Content" ref={timeScheduleGridRef}>
          <div className='TimeSchedule-Body-CheckBoxGrid' style={{ position: 'relative', height: '100%' }}>
            {
              HOUR_LABELS.map((num, idx) => <div
                style={{ cursor: 'pointer' }}
                onClick={() => null}
                key={num}
                id={`timeschedule-body-hoursgrid-${num}`}
              >
              </div>)
            }
            {
              displayMode === 'daily' && (
                <RenderAppointmentsCheckbox />
              )
            }
          </div>
          <div className='TimeSchedule-Body-HourLabels'>
            {
              HOUR_LABELS.map(num => num != '23:59' ? <div>{num}</div> : <div></div>)
            }
          </div>
          <div className='TimeSchedule-Body-Spliter'>
            {
              HOUR_LABELS.map(() => <div></div>)
            }
          </div>
          <div className='TimeSchedule-Body-HoursGrid' style={{ position: 'relative', height: '100%' }}>
            {
              HOUR_LABELS.map(num => <div
                style={{ cursor: 'pointer' }}
                onClick={openAvailableScheduleModal}
                key={num}
                id={`timeschedule-body-hoursgrid-${num}`}
              >

              </div>)
            }
            <RenderCurrentTimeLine />
            <RenderAppointments displayMode={displayMode} />
          </div>
        </div>
      </div>
    </div>
  )
}