import React, { useEffect } from 'react'
import { Router, Route, Switch, Redirect,  } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { history } from './helpers'
import login from './pages/login/login'
import RegisterHealthPlaceUserPage from './pages/register/RegisterHealthPlaceUserPage/RegisterHealthPlaceUserPage'
import RecoverPassword from './pages/login/RecoverPassword'
import AppLayout from './layout/App'
import Home from './pages/home/home'
import SelectScheduleHealthPlaceUserManager from './pages/agenda/SelectScheduleHealthPlaceUserManager/SelectScheduleHealthPlaceUserManager'
import Collaborators from './pages/collaborators/Collaborators'
import EmailVerifiedPage from './pages/register/EmailVerified/EmailVerified'
import PatientsManager from './pages/pacientes/PatientsManager'
import HoldingPatientInfoView from './pages/datailPatient/HoldingPatientInfoView'
import ConfigurationPage from './pages/configuration/configuration'
import ConsultationPage from './pages/consultation/consultation'
import DentistAppointmentPage from './pages/thousandDays/DentistAppointment/DentistAppointment'
import { LoadingPage } from './newcomponents/loading/loading'
import { AddAlert, Alert, AlertType } from './component'
import PreceptorshipManageRoom from './pages/preceptorship/PreceptorshipManageRoom/PreceptorshipManageRoom'
import PreceptorshipDetailRoom from './pages/preceptorship/PreceptorshipDetailRoom/PreceptorshipDetailRoom'
import PreceptorshipEditorPage from './pages/preceptorship/PreceptorshipEditorPage/PreceptorshipEditorPage'
import ThousandDaysPage from './pages/thousandDays/ThousandDaysPage/ThousandDaysPage'
import VideoCallPage from './pages/videocall/VideoCallPage'
import InventoryMenuPage from './pages/inventory/InventoryMenuPage'
import AttendanceManager from './pages/AttendanceManager/AttendanceManager'
import { PrivacyPolicy } from './pages/privacyPolicy/privacyPolicy'
import PatientCallPanel from './pages/PatientCallPanel/PatientCallPanel'
import FinancePage from './pages/finance/finance'

const ACCESS_PERMISSION_8 = ['NURSE']
const ACCESS_PERMISSION_7 = ['NUTRITIONIST']
const ACCESS_PERMISSION_6 = ['PSYCHOLOGIST']
const ACCESS_PERMISSION_5 = ['DENTIST']
const ACCESS_PERMISSION_4 = ['ADMINISTRATOR']
const ACCESS_PERMISSION_3 = ['DOCTOR']
const ACCESS_PERMISSION_2 = ['ASSISTANT']
const ACCESS_PERMISSION_1 = ['LABORATORY']
const ACCESS_PERMISSION_0 = [
  ...ACCESS_PERMISSION_8,
  ...ACCESS_PERMISSION_7,
  ...ACCESS_PERMISSION_6,
  ...ACCESS_PERMISSION_5,
  ...ACCESS_PERMISSION_4,
  ...ACCESS_PERMISSION_3,
  ...ACCESS_PERMISSION_2,
  ...ACCESS_PERMISSION_1,
]
const ACCESS_PERMISSION_HEALTH_PROFESSIONAL = [
  ...ACCESS_PERMISSION_8,
  ...ACCESS_PERMISSION_7,
  ...ACCESS_PERMISSION_6,
  ...ACCESS_PERMISSION_5,
  ...ACCESS_PERMISSION_3,
]

const PrivateRoute = ({ component: Component, path, access = ACCESS_PERMISSION_0 }) => {

  return (
    <Route
      exact
      path={path}
      render={props => {
        if (localStorage?.token) {
          return <AppLayout access={access} path={path}>
            <Component {...props} />
          </AppLayout>
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
      }}
    />
  )
}


export default function MainRouter() {
  const dispatch = useDispatch()
  const { load } = useSelector(state => state.appState)

  useEffect(()=> {
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    
    if (isSafari) { // FIXME RENDER OUTSIDE AGENDA
      dispatch(AddAlert('Salutho', 'Algumas funcionalidades podem apresentar instabilidade neste navegador.Recomendamos o uso do google chrome', AlertType.WARNING))
    }

    // eslint-disable-next-line
  },[])

  return (
  <>
    <Alert />
    {load ? <LoadingPage /> : null}
    <Router history={history}>
      <Switch>
        <Route path='/login' exact={true} component={login} />
        <Route path='/registrar' exact={true} component={RegisterHealthPlaceUserPage} />
        <Route path='/recuperar-senha' exact={true} component={RecoverPassword} />
        <Route path='/recuperar-senha/:idUid' exact={true} component={RecoverPassword} />
        <Route path='/recuperar-senha/:idUid/:idRecuperar' exact={true} component={RecoverPassword} />
        <Route path='/emailValido' exact={false} component={EmailVerifiedPage} />
        <Route path='/consultation/video/:token/:key' exact component={VideoCallPage} />
        <Route path='/privacy-policy' exact component={PrivacyPolicy} />
        <Route path='/painel-de-chamada/:idUid' exact component={PatientCallPanel} />

        <PrivateRoute path='/home' exact component={Home} />
        <PrivateRoute path='/profissionais' exact component={SelectScheduleHealthPlaceUserManager} access={[...ACCESS_PERMISSION_HEALTH_PROFESSIONAL, ...ACCESS_PERMISSION_2, ...ACCESS_PERMISSION_4]} />
        <PrivateRoute path='/atendimentos/:tab?' exact component={AttendanceManager} access={[...ACCESS_PERMISSION_HEALTH_PROFESSIONAL, ...ACCESS_PERMISSION_2, ...ACCESS_PERMISSION_4]} />
        <PrivateRoute path='/colaboradores' exact component={Collaborators} access={ACCESS_PERMISSION_4} />
        
        <PrivateRoute path='/pacientes' exact component={PatientsManager} access={ACCESS_PERMISSION_0} />
        <PrivateRoute path='/pacientes/:idPaciente' exact component={HoldingPatientInfoView} access={ACCESS_PERMISSION_0} />
        <PrivateRoute path='/pacientes/:idPaciente/detalhar' exact component={HoldingPatientInfoView} access={ACCESS_PERMISSION_0} />
        <PrivateRoute path='/pacientes/:idPaciente/detalhar/:idStatusTab' exact component={HoldingPatientInfoView} access={ACCESS_PERMISSION_0} />
        <PrivateRoute path='/configuration/:tab?' exact component={ConfigurationPage} access={ACCESS_PERMISSION_0} />
        
        <PrivateRoute path='/consultation/:id/:initialTab?' exact component={ConsultationPage} access={[...ACCESS_PERMISSION_HEALTH_PROFESSIONAL]}/>
        <PrivateRoute path='/preceptoria' exact component={PreceptorshipManageRoom} access={ACCESS_PERMISSION_HEALTH_PROFESSIONAL} />
        <PrivateRoute path='/preceptoria/grupo/:id' exact component={PreceptorshipDetailRoom} access={ACCESS_PERMISSION_HEALTH_PROFESSIONAL} />
        <PrivateRoute path='/preceptoria/consultation/:room_id/:id' exact component={PreceptorshipEditorPage} access={ACCESS_PERMISSION_HEALTH_PROFESSIONAL}/>

        <PrivateRoute path='/estoque/:tab/:subtab?' exact component={InventoryMenuPage} access={ACCESS_PERMISSION_0} />

        <PrivateRoute path='/1000dias' exact component={ThousandDaysPage} access={[...ACCESS_PERMISSION_HEALTH_PROFESSIONAL, ...ACCESS_PERMISSION_2]}/>
        <Route path='/1000dias/prontuario_odontologico/:project_token' exact component={DentistAppointmentPage} />

        <PrivateRoute path='/financas' exact component={FinancePage}></PrivateRoute>

        <Redirect from='*' to={localStorage.token ? '/home' : '/login'} />
      </Switch>
    </Router>
  </>
)}
