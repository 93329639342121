export function strpPhysicalAssessment(physical_assessment = null) {
    return {
        _id: physical_assessment?._id,
        person: physical_assessment?.person,
        attendance: physical_assessment?.attendance,
        physical_mobility_mmss: physical_assessment?.physical_mobility_mmss || 'NOT_EVALUATED', 
        physical_mobility_mmii: physical_assessment?.physical_mobility_mmii || 'NOT_EVALUATED', 
        skin: physical_assessment?.skin || 'NOT_EVALUATED', 
        turgor_skin: physical_assessment?.turgor_skin || 'NOT_EVALUATED', 
        mucosa_skin: physical_assessment?.mucosa_skin || 'NOT_EVALUATED', 
        skin_injures: physical_assessment?.skin_injures || '', 
        active: true,
    }
}

export function parsePhysicalAssessment(physical_assessment) {
    return {
        _id: physical_assessment?._id,
        person: physical_assessment?.person?.id,
        attendance: physical_assessment?.attendance,
        physical_mobility_mmss: physical_assessment?.physical_mobility_mmss || 'NOT_EVALUATED', 
        physical_mobility_mmii: physical_assessment?.physical_mobility_mmii || 'NOT_EVALUATED', 
        skin: physical_assessment?.skin || 'NOT_EVALUATED', 
        turgor_skin: physical_assessment?.turgor_skin || 'NOT_EVALUATED', 
        mucosa_skin: physical_assessment?.mucosa_skin || 'NOT_EVALUATED', 
        skin_injures: physical_assessment?.skin_injures || '', 
        active: true
    }
}