import './ShowChronicConditions.css'
import React from "react";
import ChronicConditionChart from '../ChronicConditionChart/ChronicConditionChart';


export default function ShowChronicConditions({ chronicConditionsHistory=[], fetchChronicConditions= async () => null, setModalInfo= () => null }) {
    
    const conditions = [
        { id: 'diabetes', label: 'Diabetes', color: 'rgb(255, 99, 132)' },
        { id: 'diabetes_insulin', label: 'Uso de insulina', color: 'rgb(54, 162, 235)' },
        { id: 'hypertension', label: 'Hipertensão', color: 'rgb(255, 206, 86)' },
        { id: 'heart_disease', label: 'Doenças cardíacas', color: 'rgb(75, 192, 192)' },
        { id: 'high_total_cholesterol', label: 'Colesterol alto', color: 'rgb(153, 102, 255)' },
        { id: 'high_triglycerides', label: 'Triglicerídeos altos', color: 'rgb(255, 159, 64)' },
        { id: 'musculoskeletal', label: 'Problemas musculoesqueléticos', color: 'rgb(199, 199, 199)' },
    ];

    return <div className='ShowChronicConditions-Container'>
        {conditions.map(condition => {
            const data = chronicConditionsHistory.map(appointment => ({ "date": appointment?.updated_at, "value": appointment[condition.id] }))
            return (
                <ChronicConditionChart 
                    key={condition.id} 
                    condition={condition} 
                    data={data}
                    setModalInfo={setModalInfo}
                />
            );
        })}
    </div>
}