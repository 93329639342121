import React from 'react'
import { validarCampo } from '../../validation/Validation'

export const Input = React.forwardRef(({
  action,
  actionBlur,
  actionFocus,
  autoComplete = 'off',
  children, 
  className,
  color = '', 
  disabled = false,
  errors = {},
  label, 
  max = 'any',
  maxLength='1000',
  min = 'any',
  name = '', 
  placeholder = '',
  required = {},
  step = 'any',
  type = 'text', 
  value,
  asterisk=false,
}, ref) => {
  const require = Object.keys(required)
  const pattern = () => {
    if (typeof required?.pattern === 'object')
      return JSON.stringify(required.pattern)
    else if (typeof required?.pattern === 'string')
      return required.pattern
    else return null
  }

  const validar = e => {
    const v = (require.length) ? validarCampo(e) : {}
    if (actionBlur) actionBlur(e, v)
  }

  const thereIsError = (require.length && (required.erro[name])) || errors.error?.[name]
  
  return (
    <div id={`box-${name}`} className={`form-box ${color} ${className} ${thereIsError ? 'erro' : ''} `}>
      <label className='label-input' htmlFor={`id-${name}`}>{require.length && asterisk ? <span>*</span> : ''} {label}</label>
      <div>
        <input
          id={`id-${name}`}
          autoComplete={autoComplete}
          disabled={disabled}
          max={max}
          maxLength={maxLength}
          min={min}
          name={name} 
          onBlur={e => validar(e)} 
          onChange={action}
          onFocus={actionFocus}
          pattern={pattern()}
          placeholder={placeholder}
          ref={ref}
          step={step}
          type={type} 
          value={value} 
        />
        <div className='input-actions'>
          {children}
        </div>
      </div>
      {
        required.erro?.[name] || errors.error?.[name]
        ? <span className='campo-obrigatorio'>{required.message || errors.message}</span>
        : null
      }
    </div>
  )
})
