import './NewEditCouncilModal.css'
import { AddAlert, AlertType, Input } from "../../../../../../../../../../component";
import NewSelect from "../../../../../../../../../../newcomponents/form/select/select";
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button'
import { useEffect, useRef, useState } from "react";
import { MODAL_INIT_PROPS } from "../../../../../../../../../../newcomponents/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { useApp } from "../../../../../../../../../../layout/App";
import { loaded, loading } from "../../../../../../../../../../layout/redux/AppActions";
import { buildDate } from "../../../../../../../../../../utils/convertDate";
import { patchCouncil, postCouncil } from "../../../../../../../../http";
import { getSpecialties } from '../../../../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { strpCouncilData } from '../../../helpers';
import NewMultiSelect from '../../../../../../../../../../newcomponents/form/select/multiselect';
import { uniqueIDs } from '../../../../../../../../../../utils/uniqueIDs';


export default function NewEditCouncilModal({ initCouncilData, setSecondaryModalProps=() => null }){
	const searchTimeOut = useRef(null)
    const [holdingCouncil, setHoldingCouncil] = useState(strpCouncilData(initCouncilData))
	const [holdingCouncilErr, setHoldingCouncilErr] = useState({})
	const [specialtiesOptions, setSpecialtiesOptions] = useState([])
    const { listas } = useSelector(state => state.appState)
    const { fetchUserCouncils, currentHealthPlaceUser } = useApp()
    const dispatch = useDispatch()

	useEffect(() => {
		fetchSpecialtiesOptions()
	}, [])

    const profile = currentHealthPlaceUser?.profile

    const councilNames = {
		DENTIST: 'CRO',
		PSYCHOLOGIST: 'CRP',
		NUTRITIONIST: 'CRN',
		NURSE: 'COREN',
		DOCTOR: 'CRM',
	}

	const handleSearchSpecialties = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchSpecialtiesOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

	const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const handleChange = event => {
		setHoldingCouncilErr({})
		setHoldingCouncil(prev => ({
			...prev,
			[event.target.name]:
				event.target.value[0] === 't'
					? event.target.value
					: event.target.value.replace(/\D/g, ''),
		}))
	}

    const handleSelect = event => {
		setHoldingCouncilErr({})
		setHoldingCouncil(prev => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = holdingCouncil?.[loadKey].find(option => option[fieldNameRef] === optionRef);
		
		let nextState = holdingCouncil
		if (isExisting) {
			nextState = { ...holdingCouncil, [loadKey]: holdingCouncil[loadKey].filter(option => option[fieldNameRef] !== optionRef) }
		} else {
			nextState = { ...holdingCouncil, [loadKey]: [...holdingCouncil[loadKey], selectedOption] }
		}
		setHoldingCouncil(nextState)

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchSpecialtiesOptions({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('toggleOption', err)
		}
	}

    const handleSaveCouncil = async () => {
		let errors = {}
		if (!holdingCouncil.nu_crm) errors['nu_crm'] = 'Inscrição obrigatória'
		if (!holdingCouncil.uf_crm) errors['uf_crm'] = 'UF obrigatória'
		if (Object.keys(errors).length) return setHoldingCouncilErr(errors)

		let currentDate = new Date()
		let payload = {
			...holdingCouncil,
			'person': currentHealthPlaceUser?.user?.person?.id,
			'council': councilNames[profile],
			'registration_date': buildDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()),
			'associated_specialties': holdingCouncil?.associated_specialties?.map(instance => instance?.id),
		}

		dispatch(loading())
		try {
			if (holdingCouncil?.id) {
				await patchCouncil(holdingCouncil?.id, payload)
			} else {
				await postCouncil(payload)
			}
			fetchUserCouncils()
			dispatch([
				loaded(),
				AddAlert(`Cadastro de ${councilNames[profile]}`, `${councilNames[profile]} salvo com sucesso`, AlertType.SUCCESS),
			])
			setSecondaryModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error(err)
			dispatch([
				loaded(),
				AddAlert(`Cadastro de ${councilNames[profile]}`, `Falha ao salvar ${councilNames[profile]}, tente novamente!`, AlertType.ERROR),
			])
		}
	}

    return (<div className='NewEditCouncilModal'>
        	<div className='NewEditCouncilModal-Grid'>
				<Input
					label='Inscrição'
					name='nu_crm'
					action={handleChange}
					value={holdingCouncil.nu_crm}
					maxLength='8'
					required={{
						erro: holdingCouncilErr,
						message: holdingCouncilErr.nu_crm,
					}}
					placeholder='ex: 123456'
				/>
				<NewSelect
					id='uf_crm'
					options={listas.estados}
					canBeEmpty={false}
					label='UF'
					optionRefKey='sigla'
					optionStrKey='nome'
					selected={holdingCouncil.uf_crm}
					onSelect={handleSelect}
					error={holdingCouncilErr?.uf_crm}
				/>
			</div>			
			<NewMultiSelect
				label='Especialidades'
				filterNode={
					<div className='NewEditCouncilModal-NewSelect'>
						<Input
							placeholder='Pesquisar por nome'
							action={handleSearchSpecialties}
							actionFocus={() => fetchSpecialtiesOptions({ offset: 0 })}
						/>
					</div>
				}
				defaultText={holdingCouncil.associated_specialties?.length ? `${holdingCouncil.associated_specialties.length} selecionadas` : 'Selecione'}
				onlyDefaultText
				onSelect={({ optionRef }) => {
					toggleOption('associated_specialties', specialtiesOptions, 'id', optionRef)
				}}
				selectedOptions={holdingCouncil.associated_specialties?.map(specialty => specialty.id)}
				optionRefKey='id'
				optionStrKey='strf'
				options={uniqueIDs(holdingCouncil.associated_specialties, specialtiesOptions)}
			/>
			<div className='NewEditCouncilModal-SaveBtn'>
				<NewButton
					label={`Salvar ${councilNames[profile]}`}
					onClick={handleSaveCouncil}
				/>
			</div>
    </div>)
}