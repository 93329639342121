import './EditHealthPlaceInstitutionalScheduleModal.css'
import React, { useRef } from "react";
import replaceEmptyWithNull from "../../../../../../../../utils/replaceEmptyWithNull";
import { useConfiguration } from "../../../../../../configuration";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../../../../../../layout/redux/AppActions";
import { MODAL_INIT_PROPS } from "../../../../../../../../newcomponents/modal/modal";
import { AddAlert, AlertType } from "../../../../../../../../component";
import api from "../../../../../../../../helpers/api";
import Timetable from "../../../../../../../../component/timetable/Timetable";
import NewButton from "../../../../../../../../newcomponents/button/button";

export default function EditHealthPlaceInstitutionalScheduleModal({ institution, setModalProps=() => null }) {
    const dispatch = useDispatch()
    const timetableRef = useRef(institution.timetable)
    const { fetchInstitution } = useConfiguration()

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            await api.patch(`health_place/timetable/${institution.id}/`, replaceEmptyWithNull({ ...timetableRef.current }))
            fetchInstitution()
            dispatch([loaded(), AddAlert('Atualizar horário', 'Horários Atualizado com sucesso', AlertType.SUCCESS)])
        } catch (error) {
            dispatch([loaded(), AddAlert('Atualizar horário', 'Erro ao atualizar horários', AlertType.ERROR)])
            console.log(JSON.parse( error.request.response ), 'atualizarHorarios erro' );
        }
        
        setModalProps(MODAL_INIT_PROPS)
    }

    return <div>
        <div className='EditHealthPlaceInstitutionalScheduleModal'>
            <div className='EditHealthPlaceInstitutionalScheduleModal-PersonalInfo'>
                <div className='EditHealthPlaceInstitutionalScheduleModal-Subtitle-Header'>
                    <div className='EditHealthPlaceInstitutionalScheduleModal-Subtitle-Header-Icon EditHealthPlaceInstitutionalScheduleModal-Subtitle-Header-Icon-Blue'>H</div>
                    <div className='EditHealthPlaceInstitutionalScheduleModal-Subtitle-Header-Title'>Horário de Funcionamento</div>
                </div>
            </div>
            <Timetable timetableRef={timetableRef} />
        </div>
        <div className='EditHealthPlaceInstitutionalScheduleModal-BtnBox'>
            <NewButton 
                label='Atualizar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}