import './Pressure.css'
import React from 'react'
import { Input } from '../../../../../../../../../../component'


export default function Pressure({
    handleChange, 
    holdingOphthalmology, 
    holdingOphthalmologyErr,
    isEditable=true
}) {
    return <div>
        <p><b>Pressão Intra-ocular</b></p>
        <div className='Pressure-Header'>
            <span>OD</span>
            <span>OE</span>
        </div>
        <div className='Pressure-Grid'>
                <Input
                    name='right_eye_pressure'
                    action={event => handleChange(event)}
                    actionBlur={event => handleChange(event)}
                    value={holdingOphthalmology?.right_eye_pressure}
                    errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                    disabled={!isEditable}
                />
                <Input
                    name='left_eye_pressure'
                    action={event => handleChange(event)}
                    actionBlur={event => handleChange(event)}
                    value={holdingOphthalmology?.left_eye_pressure}
                    errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                    disabled={!isEditable}
                />
        </div>
    </div>
}
