import React from "react";
import { useBookingAppointmentCursor } from "../../../../BookingAppointmentCursor";
import { useDispatch } from "react-redux";
import { NewCheckbox } from "../../../../../../../../../../newcomponents";

export default function RenderAppointmentsCheckbox() {
  const dispatch = useDispatch()
  const { filledTimesOfTheDay, selectedTimesCursor, setSelectedTimesCursor } = useBookingAppointmentCursor()

  const calculateGridRow = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours + minutes / 60;
  };

  return <>
    {
      filledTimesOfTheDay.horarios.map((appointment) => {
        const startTime = appointment.fields.start.split('T')[1];
        const endTime = appointment.fields.end.split('T')[1];
        const startRow = calculateGridRow(startTime);
        const endRow = calculateGridRow(endTime);
        const durationInHours = endRow - startRow;

        const height = `${(durationInHours / 24) * 100}%`;
        const top = `${(startRow / 24) * 100}%`;

        const isChecked = !!selectedTimesCursor.find(({ pk }) => pk === appointment.pk)

        return (
          <div
            key={appointment.pk}
            className='RenderAppointments'
            style={{
              top: top,
              height: height,
              width: '80px',
            }}
          >
            <NewCheckbox
              title=''
              state={[{ id: appointment.pk, name: '', checked: isChecked }]}
              setState={() => {
                isChecked
                  ? setSelectedTimesCursor(prev => prev.filter(({ pk }) => appointment.pk != pk))
                  : setSelectedTimesCursor(prev => ([...prev, appointment]))
              }}
              className=''
              defaultHandleCheckbox={false}
            />
          </div>
        )
      })
    }
  </>
}