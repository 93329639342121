import './DeletePhysicalAssessment.css'
import React from 'react'
import NewButton, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../../../component'
import { loaded, loading } from '../../../../../../../../../../layout/redux/AppActions'
import { patchPhysicalAssessment } from '../../../../../../../../http'

export default function DeletePhysicalAssessment({ physical_assessment={}, extraAction=() => null }) {
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            await patchPhysicalAssessment(physical_assessment?._id, { "active": false })
            extraAction()
        } catch (err) {
            console.error('DeletePhysicalAssessment ~ handleSubmit ~ ', err)
            dispatch(AddAlert('Avaliação Física', 'Falha ao deletar registro de avaliação de física!', AlertType.ERROR))
        }
        dispatch(loaded())
    }

    return <div className='DeletePhysicalAssessment'>
        <p>Tem certeza que deseja exluir essa <b>Avaliação Física</b>?</p>
        <div className='DeletePhysicalAssessment-BtnBox'>
            <NewButton 
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => extraAction()}
            />
            <NewButton 
                label='Deletar'
                model={ButtonModel.DANGER}
                onClick={handleSubmit}
            />
        </div>
    </div>
}