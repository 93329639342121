import React from 'react'

export default function ChatIcon({size=22}){

    return <svg version="1.1" id="chat-icon"  x="0px" y="0px"
	 viewBox="0 0 60 60" width={size}>
<g>
	<path d="M19,24.506h11c0.552,0,1-0.447,1-1s-0.448-1-1-1H19c-0.552,0-1,0.447-1,1S18.448,24.506,19,24.506z"/>
	<path d="M35,29.506H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h21c0.552,0,1-0.447,1-1S35.552,29.506,35,29.506z"/>
	<path d="M35,36.506H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h21c0.552,0,1-0.447,1-1S35.552,36.506,35,36.506z"/>
	<path d="M45.224,0.494H25.776c-6.448,0-12.132,4.216-14.062,10.322C5.031,12.231,0,18.172,0,25.27v11.345
		c0,7.732,6.313,14.314,14,14.845v6.48c0,0.676,0.343,1.238,0.894,1.466c0.163,0.067,0.333,0.101,0.503,0.101
		c0.375,0,0.75-0.159,1.05-0.46l1.521-1.521c3.89-3.89,9.151-6.032,14.813-6.032h1.443c6.599,0,12.198-4.38,14.09-10.403
		C55.017,39.541,60,33.438,60,26.614V15.27C60,7.122,53.372,0.494,45.224,0.494z M34.224,49.493h-1.443
		c-6.197,0-11.96,2.351-16.228,6.618L16,56.665v-6.172v-1v-3.154c0-0.553-0.448-1-1-1s-1,0.447-1,1v3.102
		C7.4,48.915,2,43.262,2,36.614V25.27c0-6.137,4.351-11.274,10.129-12.497l0.52-0.087c0.369-0.062,0.72-0.107,1.06-0.138
		c0.279-0.023,0.56-0.038,0.844-0.043c0.073-0.001,0.151-0.01,0.223-0.01h19.448C41.269,12.494,47,18.226,47,25.27v11.345
		c0,0.362-0.025,0.718-0.054,1.072c-0.015,0.171-0.031,0.341-0.053,0.513c-0.013,0.109-0.033,0.215-0.049,0.322
		c-0.076,0.489-0.175,0.98-0.309,1.471l-0.153,0.565C44.725,45.735,39.904,49.493,34.224,49.493z M58,26.614
		c0,5.58-3.855,10.615-9.186,12.285c0.013-0.079,0.017-0.158,0.029-0.237c0.035-0.252,0.07-0.503,0.092-0.759
		C48.975,37.472,49,37.041,49,36.614V25.27c0-1.018-0.104-2.013-0.301-2.974c-1.38-6.727-7.346-11.802-14.475-11.802H14.776
		c-0.019,0-0.037,0.003-0.057,0.003c-0.26,0.001-0.523,0.008-0.792,0.026c1.917-4.794,6.594-8.028,11.849-8.028h19.448
		C52.269,2.494,58,8.226,58,15.27V26.614z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
}