import './ChatListItem.css'
import { PersonIcon } from '../../../../../component/icon/person'
import GroupIco from '../../../../../component/icon/group'
import { useApp } from '../../../../../layout/App'
import PersonIco from '../../../../../component/icon/personIcon'

const defaultUser = require('../../../../../media/DefaultUser.png')

export default function ChatListItem ({ chat, lastReadMessage }) {
    const { currentHealthPlaceUser } = useApp()    

    const getParticipantNameOtherThanMe = () => {
        const otherParticipant = chat.participants?.find(participant => participant.id !== currentHealthPlaceUser.user.id)
        return otherParticipant?.person?.name
    }
    
    return (
        <>
            {chat.is_group
            ? <div className='ChatList-Item'>

                <div className='ChatList-Item-LeftInfo'>
                    <div className='ChatList-Item-IconDiv'>
                        <GroupIco style={{width: '25px', height: '25px'}}/>
                    </div>
                    <p className='ChatList-Item-Name'>{chat.name}</p>
                </div>
                <div>
                    {lastReadMessage?.unread_count > 0 && (
                        <div className='ChatList-Item-UnreadMessages'>
                            <p className='ChatList-Item-UnreadMessages-Number'>{lastReadMessage?.unread_count}</p>
                        </div>
                    )}
                </div>
            </div>
            : <div className='ChatList-Item'>
                <div className='ChatList-Item-LeftInfo'>
                    <div className='ChatList-Item-IconDiv'>
                        <PersonIco width={25} height={25} color={'black'}/>
                    </div>
                    <p className='ChatList-Item-Name'>{getParticipantNameOtherThanMe()}</p>
                </div>
                <div>
                    {lastReadMessage?.unread_count > 0 && (
                        <div className='ChatList-Item-UnreadMessages'>
                            <p className='ChatList-Item-UnreadMessages-Number'>{lastReadMessage?.unread_count}</p>
                        </div>

                    )}
                </div>
            </div>
        }
        </>

    )
}