import React, { useEffect, useState } from 'react'
import { apiV2 } from '../../helpers'
import ProceduresList from './procedures-list'


export default function ConfigurationSection() {



    return <div>
        <ProceduresList></ProceduresList>
    </div>
}