import './TableHospitalizations.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function TableHospitalizations({ hospitalizationsHistory=[], fetchHospitalizations= async () => null, openNewEditHospitalizationsModal=() => null, openDeleteHospitalizationsModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchHospitalizations({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchHospitalizations({ "offset": offset, limit: 10 })
	}
    
    const headDefault = [
        { "colunm": "h_hospitalization_date", "text": "Data de hospitalização" },
        { "colunm": "h_number_of_days_in_hospital", "text": "Número de dias no hospital" },
        { "colunm": "h_reason_for_hospitalization", "text": "Motivo de hospitalização" },
        { "colunm": "h_action", "text": <div className='TableHospitalizations-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableHospitalizations-Body-List'>
            <List
                head={headDefault}
                data={hospitalizationsHistory}
                listCustom={cursor => {
                    const custom = cursor;

                    custom['h_hospitalization_date'] = cursor?.hospitalization_date ? MaskDataHora(cursor?.hospitalization_date)?.data : '-'
                    custom['h_number_of_days_in_hospital'] = typeof cursor?.number_of_days_in_hospital === 'number' ? cursor?.number_of_days_in_hospital : '-'
                    custom['h_reason_for_hospitalization'] = cursor?.reason_for_hospitalization 
                        ? <p dangerouslySetInnerHTML={{ __html: cursor?.reason_for_hospitalization }}></p> 
                        : '-'
                    custom['h_action'] = (
                        <div className='TableHospitalizations-ActionRow'>
                            <div
                                title='Editar Hospitalização'
                            	onClick={() => openNewEditHospitalizationsModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Hospitalização'
                                onClick={() => openDeleteHospitalizationsModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <Paginate data={pagination} action={handleNextPage} />
        </div>

    </div>
}