import {
  INITIAL_STATE_APP,
  LOAD,
  LISTAS
} from './AppActions'


const getSidebarLeft = localStorage.getItem('sidebarLeft')

export const appInitState = {
  sidebarRight: false,
  sidebarLeft: getSidebarLeft === 'true',
  load: false,
  clinica: {},
  credenciais: {},
  modalOpen: '',
  language: 'pt-BR',
  disponibilidade: false,
  codigoValidacao: '',
  medicoSelecionado: {},
  listas: {
    Genero: [
      { id: 'M', name: 'Masculino' },
      { id: 'F', name: 'Feminino' },
      { id: 'U', name: 'Outros' }
    ],
    EstadoCivil: [
      { id: 'M', name: 'Casado (a) / União Estável' },
      { id: 'D', name: 'Separado (a) / Divorciado (a)'},
      { id: 'S', name: 'Solteiro (a)' },
      { id: 'W', name: 'Viúvo (a)' },
      { id: 'L', name: 'Moro junto com o meu parceiro (a)'}
    ],
    tipos: [
      { id: 'CLINIC', name: 'Clínica' },
      { id: 'HOSPITAL', name: 'Hospital' },
      { id: 'LAB', name: 'Laboratório' },
      { id: 'MEDICAL_OFFICE', name: 'Consultório Médico' },
      { id: 'UBS', name: 'Unidade Básica de Saúde' },
      { id: 'UPA', name: 'Unidade de Pronto Atendimento' },
    ],
    profissoes: [],
    cidades: [],
    planosConvenios: [],
    estados: [
      {nome: "Acre", sigla: "AC"},
      {nome: "Alagoas", sigla: "AL"},
      {nome: "Amapá", sigla: "AP"},
      {nome: "Amazonas", sigla: "AM"},
      {nome: "Bahia", sigla: "BA"},
      {nome: "Ceará", sigla: "CE"},
      {nome: "Distrito Federal", sigla: "DF"},
      {nome: "Espírito Santo", sigla: "ES"},
      {nome: "Goiás", sigla: "GO"},
      {nome: "Maranhão", sigla: "MA"},
      {nome: "Mato Grosso", sigla: "MT"},
      {nome: "Mato Grosso do Sul", sigla: "MS"},
      {nome: "Minas Gerais", sigla: "MG"},
      {nome: "Pará", sigla: "PA"},
      {nome: "Paraíba", sigla: "PB"},
      {nome: "Paraná", sigla: "PR"},
      {nome: "Pernambuco", sigla: "PE"},
      {nome: "Piauí", sigla: "PI"},
      {nome: "Rio de Janeiro", sigla: "RJ"},
      {nome: "Rio Grande do Norte", sigla: "RN"},
      {nome: "Rio Grande do Sul", sigla: "RS"},
      {nome: "Rondônia", sigla: "RO"},
      {nome: "Roraima", sigla: "RR"},
      {nome: "Santa Catarina", sigla: "SC"},
      {nome: "São Paulo", sigla: "SP"},
      {nome: "Sergipe", sigla: "SE"},
      {nome: "Tocantins", sigla: "TO"}
    ]
  }
}

export default function AppReducer (state = appInitState, { type, payload }) {
  switch (type) {
    case LOAD:
      return { ...state, load: payload }
    case LISTAS:
      return { ...state, listas: {...state.listas, [payload.name]: payload.value} }
    case INITIAL_STATE_APP:
      return appInitState
    default:
      return state
  }
}