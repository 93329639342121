import './SaveCollaborator.css'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, Input } from '../../../../../component'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import NewSelect from '../../../../../newcomponents/form/select/select'
import { isValidEmail } from '../../../../../validators'
import { loaded, loading } from '../../../../../layout/redux/AppActions'
import api from '../../../../../helpers/api'
import { userRoles } from '../../../../../utils/userRoles'
import { useApp } from '../../../../../layout/App'
import { useCollaborators } from '../../SelectScheduleHealthPlaceUserManager'
import AssociateCollaboratorModal from './AssociateCollaboratorModal/AssociateCollaboratorModal'
import ReactivateCollaboratorModal from './ReactivateCollaboratorModal/ReactivateCollaboratorModal'

const strpHealthPlaceUser = (instance) => {
  return {
    "email": instance?.user?.email || "",
    "profile": instance?.profile || "ADMINISTRATOR",
    "enabled_thousand_days": instance?.enabled_thousand_days || false
  }
}

const INIT_ERROR = { "email": false }

export default function SaveCollaborator({ healthPlaceUser=null, healthPlace=null }) {
  const { setModalInfo, fetchCollaborators } = useCollaborators()
  const dispatch = useDispatch()
  const { currentHealthPlaceUser } = useApp()
  const [collaborator, setCollaborator] = useState(strpHealthPlaceUser(healthPlaceUser))
  const [collaboratorError, setCollaboratorError] = useState(INIT_ERROR)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  useEffect(()=> {
    if (hasSubmitted) {
      const hasError = validateField(collaborator.email);
      setCollaboratorError(hasError);
    }
  }, [collaborator.email, hasSubmitted])
  
  const profileOptions = Object.keys(userRoles).map(key => {
    const item = userRoles[key];
    return { "id": key, "name": item.title };
  });
  
  const handleChange = (event) => {
    setCollaborator(prev => ({ ...prev, [event.target.name]: event.target.value }))

    if (hasSubmitted) {
      const hasError = validateField(event.target.value)
      setCollaboratorError(hasError)
    }
  }

  const handleSelect = (event) => {
    setCollaborator(prev => ({ ...prev, [event.target.id]: event.target.selected }))
  }
  
  const handleCheckbox = () => {
    setCollaborator(prev => ({ ...prev, enabled_thousand_days: !prev.enabled_thousand_days  }))
  }

  const validateField = (value) => {
    if (!value) {
      return {email: true, mensagem: 'Campo obrigatório.'}
    }

    const hasError = !isValidEmail(collaborator.email)
    if (hasError) {
      return {email: true, mensagem: 'Email inválido.'}
    }
    return {email: false, mensagem: ''}
  }

  const handleSubmit = () => {
    setHasSubmitted(true)

    const hasError = validateField(collaborator.email) 
    setCollaboratorError(hasError)
    
    if (hasError.email) {
      return 
    }
    
    dispatch(loading())
    if (!healthPlaceUser?.id) {
      const payload = {
        health_place: healthPlace?.id || currentHealthPlaceUser?.health_place?.id,
        user: {
          email: collaborator.email,
          password_change: true,
        },
        profile: collaborator.profile,
        enabled_thousand_days: collaborator.enabled_thousand_days,
      }

      api.post(`/health_place_user/registration/user/`, payload)
      .then(() => {
        dispatch([loaded(), AddAlert('Salvar colaborador', 'colaborador salvo com sucesso', AlertType.SUCCESS)])
        fetchCollaborators({ offset: 0 })
        setModalInfo(MODAL_INIT_PROPS)
      })
      .catch(err => {
        console.error('handleSubmit ~ post ~ /health_place_user/registration/user/', err)
        console.log(err?.response?.data)
        dispatch(loaded())
        if (err?.response?.data?.includes('E-mail already registered in this health place.')) {
          dispatch(AddAlert('Salvar colaborador', 'Esse e-mail já está cadastrado por um profissional na sua instituição.', AlertType.ERROR))
        } else if (err?.response?.data?.includes('E-mail already registered in the plataform.') || 
                   err?.response?.data?.includes('E-mail already registered in the plataform, but inactivated.')) {
          setModalInfo(prev => ({
            ...prev,
            open: true,
            title: 'Associar profissional',
            content: <AssociateCollaboratorModal
              health_place={healthPlace?.id || currentHealthPlaceUser?.health_place?.id}
              setModalInfo={setModalInfo}
              collaborator={collaborator}
              fetchCollaborators={fetchCollaborators}
              collaboratorInactivated={err.response.data.includes('E-mail already registered in the plataform, but inactivated.') ? true : false}
            />
          }))
        } else if (err.response.data.includes('E-mail already registered in this health place, but inactivated.')) {
                setModalInfo(prev => ({
                  ...prev,
                  open: true,
                  title: 'Associar profissional',
                  content: <ReactivateCollaboratorModal
                    health_place={healthPlace?.id || currentHealthPlaceUser?.health_place?.id}
                    setModalInfo={setModalInfo}
                    collaborator={collaborator}
                    fetchCollaborators={fetchCollaborators}
                  />
                }))
        } else {
          dispatch(AddAlert('Salvar colaborador', 'Erro ao adicionar um colaborador', AlertType.ERROR))
        }
      })
    } else {
      const payload = {
        profile: collaborator.profile,
        enabled_thousand_days: collaborator.enabled_thousand_days,
      }
      api.patch(`/health_place_user/registration/user/${healthPlaceUser.id}/`, payload)
      .then(() => {
        dispatch([loaded(), AddAlert('Salvar colaborador', 'colaborador salvo com sucesso', AlertType.SUCCESS)])
        fetchCollaborators({ offset: 0 })
        setModalInfo(MODAL_INIT_PROPS)
      })
      .catch(err => {
        console.error('handleSubmit ~ patch ~ /health_place_user/registration/user/', err)
        dispatch([loaded(), AddAlert('Salvar colaborador', 'Erro ao adicionar um colaborador', AlertType.ERROR)])
      })
    }
  }

  return (
    <>
      <section>
        <Input 
          label='E-mail' 
          name='email' 
          action={handleChange} 
          value={collaborator?.email}
          disabled={!!healthPlaceUser?.id}
          errors={{
						error: {email: collaboratorError?.mensagem},
						message: collaboratorError.mensagem,
					  }}  
        />
        <NewSelect 
          id='profile'
          label='Perfil'
          selected={collaborator.profile}
          canBeEmpty={false}
          onSelect={handleSelect}
          options={profileOptions}
        />
        
        { /*
          canEnableThousandDays
          ? <NewCheckbox 
            state={[{ id: 'enabled_thousand_days', name: 'Habilitado para 1000 dias', checked: collaborator.enabled_thousand_days }]}
            setState={handleCheckbox}
            defaultHandleCheckbox={false}
            className='SaveContributorModal-NewCheckbox'
          />
          : null
        */}
      </section>
      <div className='btn-box'>
        <Button
          model={ButtonModel.SECONDARY}
          label='Fechar'
          onClick={() => setModalInfo(MODAL_INIT_PROPS)}
        />
        <Button
          color='primary'
          label='Salvar'
          onClick={handleSubmit}
        />
      </div>
    </>
  )
}
