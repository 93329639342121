import './NewChatModal.css'
import { AddAlert, AlertType, Input } from '../../../../../component'
import { useEffect, useRef, useState } from 'react'
import { getCollaborators } from '../../../../collaborators/http'
import { useApp } from '../../../../../layout/App'
import { stringifyCollaboratorOption } from '../../../../../utils/stringifyCollaboratorOption'
import NewMultiSelect from '../../../../../newcomponents/form/select/multiselect'
import Button, { ButtonModel } from '../../../../../newcomponents/button/button'
import { MODAL_INIT_PROPS } from '../../../../../newcomponents/modal/modal'
import { useDispatch } from 'react-redux'
import { PostUserChat } from '../../../http'

const initChat = {name: '', is_group: false, participants: []}

export default function NewChatModal ({setModalInfo=()=> null, getChatsByUser=()=> null}) {
    const [newChat, setNewChat] = useState(initChat)
    const [collaboratorOptions, setCollaboratorOptions] = useState([])
    const [inputError, setInputError] = useState(false)
    const { currentHealthPlaceUser } = useApp()
    const searchCollaboratorsTimeout = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        fetchCollaboratorsOptions({ offset: 0 })
    }, [])

    const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
            has_person: true,
			limit: 500,
		})
			.then(res => {
                setCollaboratorOptions(res.data.results.filter(option => option.id !== currentHealthPlaceUser.id).map(instance => stringifyCollaboratorOption(instance)))
                console.log(res.data.results)
                console.log(res.data.results.filter(option => option.id !== currentHealthPlaceUser.id))
            })
			.catch(err => {
				console.error('fetchCollaboratorsOptions - NewChatModal', err)
				setCollaboratorOptions([])
			})
	}

    const handleSearchCollaboratorsOptions = event => {
        
		if (searchCollaboratorsTimeout.current)
			clearTimeout(searchCollaboratorsTimeout.current)

		searchCollaboratorsTimeout.current = setTimeout(() => {
			fetchCollaboratorsOptions({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

    const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
        if (inputError) {
            setInputError(false)
        }
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = newChat[loadKey].find(option => option[fieldNameRef] === optionRef);
        
		if (isExisting) {
			setNewChat(prev => ({
				...prev,
				[loadKey]: prev[loadKey].filter(option => option[fieldNameRef] !== optionRef)
			}));
		} else {
			setNewChat(prev => ({
				...prev,
				[loadKey]: [...prev[loadKey], selectedOption]
			}));
		}
	};

    const handleChange = (event) => {
        setNewChat(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleRemoveParticipant = (participantId) => {
        const updatedParticipants = newChat?.participants?.filter(participant => participant.id !== participantId);
        setNewChat(prev => ({
            ...prev,
            participants: updatedParticipants
        }));
    };

    const handleSubmit = async () => {
        if (newChat.participants.length < 1) {
            setInputError(true)
            return
        }

        const participantsWithCurrentUser = [...newChat.participants, {user_id: currentHealthPlaceUser?.user?.id}]
        
        const payload = {
            name: newChat.name || null,
            is_group: newChat.participants.length > 1,
            participants: participantsWithCurrentUser?.map(participant => participant.user_id)
        };

        try {
            await PostUserChat({ payload });
            setModalInfo(MODAL_INIT_PROPS)
            getChatsByUser()
            dispatch(AddAlert('Novo Chat', 'Chat criado com sucesso!', AlertType.SUCCESS))    
        } catch (error) {
            console.error("Erro ao criar o chat:", error);
            dispatch(AddAlert('Novo Chat', 'Erro ao criar o chat. Tente novamente.', AlertType.ERROR))
        }
    }

    return (
        <div className='NewChatModal'>
            <span><b className='Label-Asterisk'>*</b> Selecione o(s) participante(s):</span>
            <NewMultiSelect
                filterNode={
                    <div>
                        <Input
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchCollaboratorsOptions}
                            actionBlur={() => fetchCollaboratorsOptions()}
                        />
                    </div>
                }
                defaultText={newChat?.participants?.length ? `${newChat?.participants.length} selecionados` : 'Selecione'}
                onlyDefaultText
                onSelect={({ optionRef }) => toggleOption('participants', collaboratorOptions, 'health_place_user__id', optionRef)}
                selectedOptions={newChat.participants?.map(doctor => doctor.health_place_user__id)}
                optionRefKey='health_place_user__id'
                optionStrKey='str'
                options={collaboratorOptions}
                error={inputError}
            />

            {newChat?.participants.length > 0 && (
                <span>Selecionados:</span>
            )}
            <div className='NewChatModal-ParticipantsList'>
                {newChat?.participants?.map(participant => {
                    return <button onClick={() => handleRemoveParticipant(participant.id)} className='NewChatModal-ParticipantsLis-Item'>{participant.name}</button>
                })}
            </div>

            {newChat.participants.length > 1 && (
                <div>
                    <span>Escolha o nome do grupo:</span>
                    <Input
                        name='name'
                        value={newChat.name}
                        maxLength={100}
                        action={handleChange}
                    />
                </div>
            )}
            
            <div className='NewChatModal-ActionBox'>
            <Button
                label='Cancelar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <Button 
                label={`Criar ${newChat.participants.length > 1 ? 'grupo' : 'chat'}`}
                model={ButtonModel.PRIMARY}
                onClick={handleSubmit}
            />
            </div>

        </div>
    )
}