export function strpGeneralInfo(general_info = null) {
    return {
        person: general_info?.person,
        attendance: general_info?.attendance,
        abo_group: general_info?.abo_group || 'NOT_EVALUATED',
        rh_factor: general_info?.rh_factor || 'NOT_EVALUATED',
        family_illnesses: general_info?.family_illnesses || '',
        use_of_controlled_medications: general_info?.use_of_controlled_medications || '',
        active: true,
    }
}

export function parseGeneralInfo(general_info) {
    return {
        _id: general_info?._id,
        person: general_info?.person?.id,
        attendance: general_info?.attendance,
        abo_group: general_info?.abo_group || 'NOT_EVALUATED',
        rh_factor: general_info?.rh_factor || 'NOT_EVALUATED',
        family_illnesses: general_info?.family_illnesses || '',
        use_of_controlled_medications: general_info?.use_of_controlled_medications || '',
        active: true
    }
}