import './Biomicroscopy.css'
import React from 'react'
import { Input, RadioButton } from '../../../../../../../../../../component'

const IS_NORMAL_FIELD_OPTIONS = [
    {id: 'IS_NORMAL', name: 'Normal' }, 
    {id: 'ARE_OTHERS', name: 'Outros' }
]

export default function Biomicroscopy({ 
    handleChange, 
    holdingOphthalmology, 
    holdingOphthalmologyErr,
    handleChangeRadioButton,
    isEditable=true,
}) {

    return <div>
        <p><b>Biomicroscopia</b></p>
        <RadioButton
            name='biomicroscopy_is_normal' 
            action={handleChangeRadioButton}
            checked={holdingOphthalmology?.biomicroscopy_is_normal}
            options={IS_NORMAL_FIELD_OPTIONS}
        />

        {
            holdingOphthalmology?.biomicroscopy_is_normal === 'IS_NORMAL' ?
            <div>
                <div className='Biomicroscopy-Header'>
                    <span></span>
                    <span>OD</span>
                    <span>OE</span>
                </div>
                <div className='Biomicroscopy-Grid'>
                    <span>Câmara anterior</span>
                    <Input
                        name='right_eye_biomicroscopy_anterior_chamber'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_biomicroscopy_anterior_chamber}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_biomicroscopy_anterior_chamber'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_biomicroscopy_anterior_chamber}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <span>Cristalino</span>
                    <Input
                        name='right_eye_biomicroscopy_crystalline'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_biomicroscopy_crystalline}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_biomicroscopy_crystalline'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_biomicroscopy_crystalline}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <span>Córnea</span>
                    <Input
                        name='right_eye_biomicroscopy_cornea'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_biomicroscopy_cornea}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_biomicroscopy_cornea'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_biomicroscopy_cornea}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <span>Conjuntiva</span>
                    <Input
                        name='right_eye_biomicroscopy_conjunctiva'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.right_eye_biomicroscopy_conjunctiva}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                    <Input
                        name='left_eye_biomicroscopy_conjunctiva'
                        action={event => handleChange(event)}
                        actionBlur={event => handleChange(event)}
                        value={holdingOphthalmology?.left_eye_biomicroscopy_conjunctiva}
                        errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                        disabled={!isEditable}
                    />
                </div>
            </div>
            : null
        }
    </div>
}

