export function strpChronicCondition(chronic_condition = null) {
    return {
        _id: chronic_condition?._id,
        person: chronic_condition?.person,
        attendance: chronic_condition?.attendance,
        diabetes: chronic_condition?.diabetes || 'NOT_EVALUATED',
        diabetes_insulin: chronic_condition?.diabetes_insulin || 'NOT_EVALUATED',
        hypertension: chronic_condition?.hypertension || 'NOT_EVALUATED',
        heart_disease: chronic_condition?.heart_disease || 'NOT_EVALUATED',
        high_total_cholesterol: chronic_condition?.high_total_cholesterol || 'NOT_EVALUATED',
        high_triglycerides: chronic_condition?.high_triglycerides || 'NOT_EVALUATED',
        musculoskeletal: chronic_condition?.musculoskeletal || 'NOT_EVALUATED',
        active: true,
    }
}

export function parseChronicCondition(chronic_condition) {
    return {
        _id: chronic_condition?._id,
        person: chronic_condition?.person?.id,
        attendance: chronic_condition?.attendance,
        diabetes: chronic_condition?.diabetes || 'NOT_EVALUATED',
        diabetes_insulin: chronic_condition?.diabetes_insulin || 'NOT_EVALUATED',
        hypertension: chronic_condition?.hypertension || 'NOT_EVALUATED',
        heart_disease: chronic_condition?.heart_disease || 'NOT_EVALUATED',
        high_total_cholesterol: chronic_condition?.high_total_cholesterol || 'NOT_EVALUATED',
        high_triglycerides: chronic_condition?.high_triglycerides || 'NOT_EVALUATED',
        musculoskeletal: chronic_condition?.musculoskeletal || 'NOT_EVALUATED',
        active: true
    }
}