import './OphthalmologyTemplate.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import api from '../../../../../../../../helpers/api'
import Biomicroscopy from './subcomponents/Biomicroscopy/Biomicroscopy'
import DynamicRefraction from './subcomponents/DynamicRefraction/DynamicRefraction'
import Funduscopy from './subcomponents/Funduscopy/Funduscopy'
import StaticRefraction from './subcomponents/StaticRefraction/StaticRefraction'
import VisualAcuity from './subcomponents/VisualAcuity/VisualAcuity'
import Pressure from './subcomponents/Pressure/Pressure'
import { useAttendance } from '../../../../../../consultation'
import { strpOphthalmology } from './subcomponents/helpers'
import { getOpthalmology, putOpthalmology } from '../../../../../../http'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import ReactQuill from 'react-quill'

export default function OphthalmologyTemplate() {
    const dispatch = useDispatch()
    const searchTimeOut = useRef(null)
    const { attendance, isEditable, setModalInfo } = useAttendance()
    const [holdingOphthalmology, setHoldingOphthalmology] = useState(strpOphthalmology())
    const [holdingOphthalmologyErr, setHoldingOphthalmologyErr] = useState({})
    
    useEffect(() => {
        fetchOphthalmology()
    }, [])

    const saveOphthalmology = async (field_type, value) => {
        const payload = { ...holdingOphthalmology, "appointment": attendance?.id, [field_type]: value }

        try {
            await putOpthalmology(payload)
            //dispatch(AddAlert('Oftalmologia', 'Oftalmologia salva com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveOphthalmology', err)
        }
    }

    const fetchOphthalmology = async (params={}) => {
        params = { ...params, "appointment__id": attendance?.id }

        try {
          let res = await getOpthalmology(params)
          setHoldingOphthalmology(strpOphthalmology(res?.data?.results?.[0]))
        } catch (err) {
          dispatch(AddAlert('Oftalmologia', 'Falha ao obter oftalmologia', AlertType.ERROR))
          console.error('Oftalmologia ~ fetchOphthalmology ~ ', err)
        }
    }

    const handleChange = (event) => {
        if (!isEditable) return 

        setHoldingOphthalmology(prev => ({ ...prev, [event.target.name]: event.target.value }))

        if (searchTimeOut.current) {
            clearTimeout(searchTimeOut.current);
        }

        searchTimeOut.current = setTimeout(() => {
            saveOphthalmology(event.target.name, event.target.value)
        }, 500)
    }

    const handleChangeTextQuill = (value) => {
        if (!isEditable) return 

        setHoldingOphthalmology(prev => ({ ...prev, "notes": value }))
        
        if (searchTimeOut.current) {
            clearTimeout(searchTimeOut.current);
        }

        searchTimeOut.current = setTimeout(() => {
            saveOphthalmology('notes', value)
        }, 500)
    }

    const handleChangeRadioButton = (event) => {
        if (!isEditable) return 

        setHoldingOphthalmology(prev => ({ ...prev, [event.target.name]: event.target.value.id }))

        if (searchTimeOut.current) {
            clearTimeout(searchTimeOut.current);
        }

        searchTimeOut.current = setTimeout(() => {
            saveOphthalmology(event.target.name, event.target.value)
        }, 500)
    }

    return <section>
        <div className='OphthalmologyTemplate'>
            <VisualAcuity 
                handleChange={handleChange} 
                holdingOphthalmology={holdingOphthalmology} 
                holdingOphthalmologyErr={holdingOphthalmologyErr}
                isEditable={isEditable}
            />
            <DynamicRefraction 
                handleChange={handleChange}
                holdingOphthalmology={holdingOphthalmology}
                holdingOphthalmologyErr={holdingOphthalmologyErr}
                isEditable={isEditable}
            />
            <StaticRefraction
                handleChange={handleChange}
                holdingOphthalmology={holdingOphthalmology}
                holdingOphthalmologyErr={holdingOphthalmologyErr}
                isEditable={isEditable}
            />
            <Pressure 
                handleChange={handleChange} 
                holdingOphthalmology={holdingOphthalmology} 
                holdingOphthalmologyErr={holdingOphthalmologyErr}
                isEditable={isEditable}
            />
            <Biomicroscopy
                handleChange={handleChange} 
                holdingOphthalmology={holdingOphthalmology} 
                holdingOphthalmologyErr={holdingOphthalmologyErr}
                handleChangeRadioButton={handleChangeRadioButton}
                isEditable={isEditable}
            />
            <Funduscopy 
                handleChange={handleChange} 
                holdingOphthalmology={holdingOphthalmology} 
                holdingOphthalmologyErr={holdingOphthalmologyErr}
                handleChangeRadioButton={handleChangeRadioButton}
                isEditable={isEditable}
            />
        </div>
        <div className='OphthalmologyTemplate-Notes'>
            <p><b>Observações</b></p>
            <ReactQuill
                id={'notes'}
                theme="snow"
                value={holdingOphthalmology?.notes || ''}
                onChange={(value) => handleChangeTextQuill(value)}
                readOnly={!isEditable}
                onBlur={(previousRange, source, editor) => handleChangeTextQuill(editor.getHTML())}
            />
        </div>
    </section>
}