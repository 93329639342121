import { getParams } from '../../utils';
import api, { apiV2 } from '../../helpers/api';
import { parseVitalSigns } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/VitalSigns/utils';
import { parseAnthropometry } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/Anthropometry/utils';
import { parseChronicCondition } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/ChronicConditions/utils';
import { parseLifeHabitsAndBehavior } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/LifeHabitsAndBehavior/utils';
import { parseHospitalization } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/Hospitalizations/utils';
import { parsePhysicalAssessment } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/PhysicalAssessment/utils';
import { parseElimination } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/Eliminations/utils';
import { parseGeneralInfo } from './subcomponents/ConsultationManager/subcomponents/HealthProfileSection/subcomponents/GeneralInformation/utils';
import { parseOphthalmology } from './subcomponents/ConsultationManager/subcomponents/SOAP/subcomponents/OphthalmologyTemplate/subcomponents/helpers';


export async function putPreceptorshipAssessment(id, payload) {
    const url = `preceptorship/assessment/${id}`
    const response = await apiV2.put(url, payload)
    return response.data
}

export async function getPreceptorshipAssessment(attendance_id) {
    const url = `preceptorship/assessment/${attendance_id}`
    const response = await apiV2.get(url)
    return response.data
}

export async function getVitalSigns(params={}) {
    params = getParams(params)
    const url = `/appointment/vital_sign/${params}`
    const response = api.get(url)
    
    return response
}

export async function putVitalSign(payload={}) {
    payload = parseVitalSigns(payload)
    const result = api.put(`/appointment/save/vital_sign/`, payload)
    return result
}

export async function patchVitalSign(id, payload={}) {
    const response = api.patch(`/appointment/vital_sign/${id}/`, payload)
    return response
}

export async function getChronicCondition(params={}) {
    params = getParams(params)
    const url = `/appointment/chronic_condition/${params}`
    const response = api.get(url)
    
    return response
}

export async function putChronicCondition(payload={}) {
    payload = parseChronicCondition(payload)
    const result = api.put(`/appointment/save/chronic_condition/`, payload)
    return result
}

export async function patchChronicCondition(id, payload={}) {
    const response = api.patch(`/appointment/chronic_condition/${id}/`, payload)
    return response
}

export async function getLifeHabitsAndBehavior(params={}) {
    params = getParams(params)
    const url = `/appointment/life_habits_and_behavior/${params}`
    const response = api.get(url)
    
    return response
}

export async function putLifeHabitsAndBehavior(payload={}) {
    payload = parseLifeHabitsAndBehavior(payload)
    const result = api.put(`/appointment/save/life_habits_and_behavior/`, payload)
    return result
}

export async function patchLifeHabitsAndBehavior(id, payload={}) {
    const response = api.patch(`/appointment/life_habits_and_behavior/${id}/`, payload)
    return response
}

export async function getHospitalization(params={}) {
    params = getParams(params)
    const url = `/appointment/hospitalization/${params}`
    const response = api.get(url)
    
    return response
}

export async function putHospitalization(payload={}) {
    payload = parseHospitalization(payload)
    const result = api.put(`/appointment/save/hospitalization/`, payload)
    return result
}

export async function patchHospitalization(id, payload={}) {
    const response = api.patch(`/appointment/hospitalization/${id}/`, payload)
    return response
}

export async function getElimination(params={}) {
    params = getParams(params)
    const url = `/appointment/elimination/${params}`
    const response = api.get(url)
    
    return response
}

export async function putElimination(payload={}) {
    payload = parseElimination(payload)
    const result = api.put(`/appointment/save/elimination/`, payload)
    return result
}

export async function patchElimination(id, payload={}) {
    const response = api.patch(`/appointment/elimination/${id}/`, payload)
    return response
}

export async function getGeneralInfo(id) {
    const url = `/appointment/health_profile_general_info/${id}/`
    const response = api.get(url)
    
    return response
}

export async function putGeneralInfo(payload={}) {
    payload = parseGeneralInfo(payload)
    const result = api.put(`/appointment/save/health_profile_general_info/`, payload)
    return result
}

export async function patchGeneralInfo(id, payload={}) {
    const response = api.patch(`/appointment/health_profile_general_info/${id}/`, payload)
    return response
}

export async function getPhysicalAssessment(params={}) { 
    params = getParams(params)
    const url = `/appointment/physical_assessment/${params}`
    const response = api.get(url)
    
    return response
}

export async function putPhysicalAssessment(payload={}) {
    payload = parsePhysicalAssessment(payload)
    const result = api.put(`/appointment/save/physical_assessment/`, payload)
    return result
}

export async function patchPhysicalAssessment(id, payload={}) {
    const response = api.patch(`/appointment/physical_assessment/${id}/`, payload)
    return response
}

export async function getOpthalmology(params={}) { 
    params = getParams(params)
    const url = `/appointment/opthalmology/${params}`
    const response = api.get(url)
    
    return response
}

export async function putOpthalmology(payload={}) {
    payload = parseOphthalmology(payload)
    const result = api.put(`/appointment/save/opthalmology/`, payload)
    return result
}

export async function patchOpthalmology(id, payload={}) {
    const response = api.patch(`/appointment/opthalmology/${id}/`, payload)
    return response
}

export async function getAnthropometry(params={}) {
    params = getParams(params)
    const url = `/appointment/anthropometry/${params}`
    const response = api.get(url)
    
    return response
}

export async function putAnthropometry(payload={}) {
    payload = parseAnthropometry(payload)
    const result = api.put(`/appointment/save/anthropometry/`, payload)
    return result
}

export async function patchAntropometry(id, payload={}) {
    const response = api.patch(`/appointment/anthropometry/${id}/`, payload)
    return response
}

export async function getAttendance(id) {

    const result = await api.get(`appointment/health_professional/${id}/`);
    return result.data

}

export async function patchAppointment(attendance_id, payload={}) {
    const response = await api.patch(`appointment/health_professional/${attendance_id}/`, payload)

    return response
}

export async function patchAppointmentCIAPOrCID(attendance_id, payload={}) {
    const response = await api.patch(`appointment/health_professional/update_ciap_or_cid/`, { id: attendance_id, ...payload})

    return response
}

export async function getPatientProjects(attendance) {
    const { person } = attendance
    const url = `/projects/list/?patient__id=${person.id}&category=T&active=True`
    const response = await api.get(url)
    return response.data.results
}

export async function getCIAPTwenty(params={}) {
    params = getParams(params)
    const url = `/appointment/ciaptwenty/${params}`
    const response = await api.get(url)
    return response.data.results
}

export async function getCIDTen(params={}) {
    params = getParams(params)
    const url = `/appointment/cidten/${params}`
    const response = await api.get(url)
    return response.data.results
}

export async function getActivities(params={}) {
    params = getParams(params)
    const url = `/appointment/document/${params}`
    const response = await api.get(url)

    return response
}

export async function putActivity(payload) {
    const response = await api.put('/appointment/save/health_document', payload)

    return response
}

export async function postActivity(payload) {
    const response = await api.post('/appointment/document/', payload)

    return response.data
}

export async function patchActivity(id, payload) {
    const response = await api.patch(`/appointment/document/${id}/`, payload)

    return response.data
}

export async function postVideoCallToken(params) {
    let response = api.post(`/appointment/videocalltoken/${getParams(params)}`)
    return response
}

export async function patchSignatureMevo(attendance_id) { /* FINISH LATER MEVO */
    const response = await api.patch(`appointment/health_document/signature/mevo/${attendance_id}/`)
    return response
}

export async function postSignatureMevo(attendance_id, documents) { /* SAVE MEVO DOCUMENTS */
    await api.post(`appointment/health_document/signature/mevo/${attendance_id}/`, {'pdfURLs': documents })
}

export async function patchPatient(patient_id, payload={}) {
    const response = await api.patch(`/person/${patient_id}/`, payload)
    return response
}

export async function putSaveTemplateType(payload={}) {
    const url = `/appointment/save/template_type`
    const response = await api.put(url, payload)
    return response
}

export async function patchTemplateType(id, payload={}) {
    const url = `/appointment/template_type/${id}/`
    const response = await api.patch(url, payload)
    return response
}

export async function getTemplateTypes(params={}) {
    params = getParams(params)
    const url = `/appointment/template_type/${params}`
    const response = await api.get(url)

    return response
}

export async function putAppointmentTextField(payload={}) {
    const url = `/appointment/save/appointment_field`
    const response = await api.put(url, payload)

    return response
}

export async function putAppointmentCIAPTwoField(payload={}) {
    const url = `/appointment/save/ciap_two_field`
    const response = await api.put(url, payload)

    return response
}

export async function putAppointmentCIDTenField(payload={}) {
    const url = `/appointment/save/cid_ten_field`
    const response = await api.put(url, payload)

    return response
}

export async function getAppointmentTextField(params={}) {
    params = getParams(params)
    const url = `/appointment/appointment_text_field/${params}`
    const response = api.get(url)

    return response
}

export async function getAppointmentCIDTenField(params={}) {
    params = getParams(params)
    const url = `/appointment/appointment_cid_ten_field/${params}` 
    const response = api.get(url)

    return response
}

export async function getAppointmentCIAPTwoField(params={}) {
    params = getParams(params)
    const url = `/appointment/appointment_ciap_two_field/${params}` 
    const response = api.get(url)

    return response
}
