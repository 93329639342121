import React, { useEffect, useRef } from "react";

export default function ProcedureAutoComplete({ items, onSelect, onClickOutside }) {

    const self = useRef()

    useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    },[self])

    const handleClick = e => {

        if (self.current && !self.current.contains(e.target)) {
            onClickOutside()
        }
    }

    if (items.length === 0) return null

    return <div id='autocomplete' ref={self} className="procedure-autocomplete">
        {items.map(item => <div className="item" onClick={() => onSelect(item)} key={item.code}>
            {`${item.code} - ${item.description}`}
        </div>)}
    </div>
}