import React from "react";
import "./GlobalCheckbox.css";

export default function GlobalCheckbox({ isAllSelected, isDisabled, onToggle }) {
    const tooltipText = isDisabled
        ? "Selecionar Todos Desabilitado: Nenhum horário disponível"
        : "Selecionar Todos Nesta Data";

    return (
        <div className="GlobalCheckbox">
            <input
                type="checkbox"
                id="global-checkbox"
                className="GlobalCheckbox-input"
                checked={isAllSelected}
                onChange={(e) => onToggle(e.target.checked)}
                disabled={isDisabled}
            />
            <label
                htmlFor="global-checkbox"
                className="GlobalCheckbox-label"
                title={tooltipText} // Tooltip
            />
        </div>
    );
}
