import './HealthProfileSection.css';
import React, { useState } from 'react';
import { useAttendance } from '../../../../consultation';
import GeneralInformation from './subcomponents/GeneralInformation/GeneralInformation';
import ChronicConditions from './subcomponents/ChronicConditions/ChronicConditions';
import LifeHabitsAndBehavior from './subcomponents/LifeHabitsAndBehavior/LifeHabitsAndBehavior';
import Anthropometry from './subcomponents/Anthropometry/Anthropometry';
import VitalSigns from './subcomponents/VitalSigns/VitalSigns';
import PhysicalAssessment from './subcomponents/PhysicalAssessment/PhysicalAssessment';
import Eliminations from './subcomponents/Eliminations/Eliminations';
import PreventiveExams from './subcomponents/PreventiveExams/PreventiveExams';
import Hospitalizations from './subcomponents/Hospitalizations/Hospitalizations';


const sections = [
  { id: 'generalinformation', label: 'Informações Gerais', component: GeneralInformation },
  { id: 'chronicconditions', label: 'Condições Crônicas', component: ChronicConditions },
  { id: 'lifehabitsandbehavior', label: 'Hábitos de Vida e Comportamento', component: LifeHabitsAndBehavior },
  { id: 'anthropometry', label: 'Antropometria', component: Anthropometry },
  { id: 'vitalsigns', label: 'Funções Vitais', component: VitalSigns },
  { id: 'hospitalizations', label: 'Hospitalizações', component: Hospitalizations },
  { id: 'physicalassessment', label: 'Avaliações Físicas', component: PhysicalAssessment },
  { id: 'eliminations', label: 'Eliminações', component: Eliminations },
  //{ id: 'preventiveexams', label: 'Exames Preventivos', component: PreventiveExams },
];

export default function HealthProfileSection() {
  const { attendance } = useAttendance();
  const [activeTab, setActiveTab] = useState(sections[0].id);

  return (
    <div>
      <div className='HealthProfileSection-TabManager'>
        {sections.map(({ id, label }) => (
          <button
            key={id}
            className={`HealthProfileSection-TabManager-btn ${activeTab === id ? 'active' : ''}`}
            onClick={() => setActiveTab(id)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className='HealthProfileSection-Content'>
        {sections.map(({ id, component: Component, withProps }) => (
          activeTab === id && (
            <div key={id} className='HealthProfileSection-TabContent'>
              <Component person={attendance?.person} attendanceID={attendance?.id} />
            </div>
          )
        ))}
      </div>
    </div>
  );
}
