import './TableLifeHabitsAndBehavior.css'
import React, { useEffect } from 'react'
import { IcoBin, IcoPencil, List, Paginate } from '../../../../../../../../../../component';

const defaultOptions = require('../../../../../../../../../../component/form/select/options.json')

export default function TableLifeHabitsAndBehavior({ lifeHabitsAndBehaviorHistory=[], fetchLifeHabitsAndBehaviorHistory= async () => null, openNewEditShowLifeHabitsAndBehaviorModal=() => null, openDeleteLifeHabitsAndBehaviorModal=() => null, pagination={} }) {
    useEffect(() => {
        fetchLifeHabitsAndBehaviorHistory({ offset: 0, limit: 10 })
    }, [])

    const handleNextPage = async e => {
        let offset = e * 10
		await fetchLifeHabitsAndBehaviorHistory({ "offset": offset, limit: 10 })
	}

    const headDefault = [
        { "colunm": "h_smokes", "text": "Fuma" },
        { "colunm": "h_exposure_to_secondhand_smoke", "text": "Exposição ao fumo passivo" },
        { "colunm": "h_use_of_illicit_drugs", "text": "Uso de drogas ilícitas" },
        { "colunm": "h_physical_activity", "text": "Atividade física" },
        { "colunm": "h_vaccinations_up_to_date", "text": "Vacinas em dia" },
        { "colunm": "h_consumption_of_alcoholic_beverages_frequency", "text": "Consumo de bebidas alcoólicas" },
        { "colunm": "h_action", "text": <div className='TableLifeHabitsAndBehavior-HeaderRow-Action'>Ações</div>, width: '72px' } 
    ]

    return <div>
        <div className='TableLifeHabitsAndBehavior-Body-List'>
            <List
                head={headDefault}
                data={lifeHabitsAndBehaviorHistory}
                listCustom={cursor => {
                    const custom = cursor;

                    const renderTranslatedYesOrNoField = (selected) => {
                        return defaultOptions.ONLY_YES_NO_OR_NOT_EVALUATED.find(({id}) => id === selected)?.name  || '-'
                    }
                    const renderTranslatedPhysicalActivityFrequencyField = (selected) => {
                        return defaultOptions.PHYSICAL_ACTIVITY_FREQUENCY_CHOICES.find(({id}) => id === selected)?.name || '-'
                    }
                    const renderTranslatedConsumptionOfAlcoholicFrequencyField = (selected) => {
                        return defaultOptions.CONSUMPTION_OF_ALCOHOLIC_FREQUENCY_OR_NOT_EVALUATED.find(({id}) => id === selected)?.name || '-'
                    }

                    custom['h_smokes'] = renderTranslatedYesOrNoField(cursor?.smokes)
                    custom['h_exposure_to_secondhand_smoke'] = renderTranslatedYesOrNoField(cursor?.exposure_to_secondhand_smoke)
                    custom['h_use_of_illicit_drugs'] = renderTranslatedYesOrNoField(cursor?.use_of_illicit_drugs)
                    custom['h_physical_activity'] = renderTranslatedPhysicalActivityFrequencyField(cursor?.physical_activity)
                    cursor['h_vaccinations_up_to_date'] = renderTranslatedYesOrNoField(cursor?.vaccinations_up_to_date)
                    cursor['h_consumption_of_alcoholic_beverages_frequency'] = renderTranslatedConsumptionOfAlcoholicFrequencyField(cursor?.consumption_of_alcoholic_beverages_frequency)
                    custom['h_action'] = (
                        <div className='TableLifeHabitsAndBehavior-ActionRow'>
                            <div
                                title='Editar Hábitos de Vida e Comportamento'
                            	onClick={() => openNewEditShowLifeHabitsAndBehaviorModal(cursor)}
                            >
                            	<IcoPencil />
                            </div>
                            <div
                                title='Excluir Hábitos de Vida e Comportamento'
                                onClick={() => openDeleteLifeHabitsAndBehaviorModal(cursor)}
                            >
                                <IcoBin />
                            </div>
                        </div>
                    )
                    return custom
                }}
            />
            <Paginate data={pagination} action={handleNextPage} />
        </div>

    </div>
}