import api from "../../helpers/api"


export async function getAppointmentsStats() {
    let response = await api.get(`appointment/stats/`)

    return response
}

export async function getUserChats() {
    let response = await api.get(`chat/chat-room/`)

    return response
}

export async function PostUserChat({ payload={} }) {
    let response = await api.post(`chat/chat-room/`, payload)

    return response
}

export async function getLastMessageRead(user_id) {
    let response = await api.get(`chat/last-read-messages/?user_id=${user_id}`)

    return response
}