import './NewPatient.css'
import React, { useRef, useState } from "react"
import { AddAlert, AlertType, Input, MaskCpf, MaskSUSCard, MaskTelefone, RadioButton } from "../../../../component"
import { useDispatch } from 'react-redux'
import api from '../../../../helpers/api'
import { loaded, loading } from '../../../../layout/redux/AppActions'
import Button, { ButtonModel } from '../../../../newcomponents/button/button'
import NewSelect from '../../../../newcomponents/form/select/select'
import { getYearsStart1900, getYearsStart1900Plus30 } from '../../../../utils/getYearsStart1900'
import { PatientRequest, validateDataBeforeSubmit } from './helpers'
import { getCareersList } from '../../http'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import EditAddress from '../../../datailPatient/subcomponents/EditAddress/EditAddress'
import { history } from '../../../../helpers'
import isOfLegalAge from '../../../../validators/isOfLegalAge'
import PatientExistentHandler from './PatientExistentHandler/PatientExistentHandler'
import PatientExistentHandlerSameHealthPlace from './PatientExistentHandlerSameHealthPlace/PatientExistentHandlerSameHealthPlace'


const { 
    SEX_CHOICES, 
    MARITAL_STATUS_CHOICES, 
    RACES_CHOICES, 
    KINSHIP_CHOICES,
    GENDER_CHOICES,
    NATIONALITY_CHOICES,
    COUNTRY_CHOICES
} = require('../../../../component/form/select/options.json')

const defaultOptions = require('../../../../component/form/select/options.json')

const INIT_INPUT_VALUES = {
    "name": "",
    "is_it_the_same_social_name": "YES",
    "birthday_day": "",
    "birthday_month": "",
    "birthday_year": "",
    "document": "",
    "document_type": "CPF",
    "social_name": "",
    "sus_card_number": "",
    "mother_name": "",
    "email": "",
    "contact_phone": "",
    "phone_type": "CELL_PHONE",

    "nationality": "",
    "sex": "",
    "race": "",
    "marital_status": "",
    "gender": "",

    "education": "",
    "career": "",

    "passport_naturalization_day": "",
    "passport_naturalization_month": "",
    "passport_naturalization_year": "",
    "passport_country_of_birth": "",
    "passport_number": "",
    "passport_issuing_country": "",
    "passport_issuance_day": "",
    "passport_issuance_month": "",
    "passport_issuance_year": "",
    "passport_expiration_day": "",
    "passport_expiration_month": "",
    "passport_expiration_year": "",

    "person_responsible_name": "",
    "person_responsible_kinship": "",
    "person_responsible_contact_phone": "",
    "person_responsible_cpf": "",
}

export default function NewPatient({ setModalInfo=() => null, fetchPatientsList=() => null }) {
    const dispatch = useDispatch()
    const searchTimeoutCareer = useRef(null)
    const [careerOptions, setCareerOptions] = useState([])
    const [inputValues, setInputValues] = useState(INIT_INPUT_VALUES)
    const [errors, setErrors] = useState({})
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)
    
    //useEffect(() => {
    //    fetchCareerOptions({ offset: 0 })
    //}, [])
    
    async function handlePatientSubmission(inputValues, errors, successCallback, forcedSubmission=false) {
        let payload = new PatientRequest(inputValues);
        let thereArentErrors = Object.keys(errors).length === 0;
        
        if (payload.isValid() && thereArentErrors) {
            dispatch(loading());
            try {
                const res = await api.post(`patient/new_patient/`, payload.formatPayload(forcedSubmission));
                fetchPatientsList({ offset: 0 });
                dispatch(AddAlert('Novo paciente', 'Paciente criado com sucesso', AlertType.SUCCESS));                
                successCallback(res);
            } catch (err) {
                if (['cpf already registered','rg already registered', 'similar patient already registered'].includes(err?.response?.data?.error)) {
                    setSecondaryModalInfo(prev => ({ 
                        ...prev, 
                        open: true,
                        title: 'Paciente já existente',
                        content: <PatientExistentHandler 
                            patientExistentQuestion={err?.response?.data} 
                            document={payload.document} 
                            documentType={payload.document_type}
                            setSecondaryModalInfo={setSecondaryModalInfo}
                        />,
                        confirmText: ''
                    }))
                } else if (['cpf already registered in health_place','rg already registered in health_place'].includes(err?.response?.data?.error)) {
                    setSecondaryModalInfo(prev => ({
                        ...prev,
                        open: true,
                        content: <PatientExistentHandlerSameHealthPlace 
                            patient_name={err?.response?.data?.patient_name} 
                            patient_id={err?.response?.data?.patient_id}
                            document_type={err?.response?.data?.patient_document_type}
                            document={err?.response?.data?.patient_document}
                            patient_birthday={err?.response?.data?.patient_birthday}
                            patient_mother_name={err?.response?.data?.patient_mother_name}
                            setSecondaryModalInfo={setSecondaryModalInfo}
                            handlePatientSubmission={handlePatientSubmission}
                            inputValues={inputValues}
                            forcedSubmissionAllowed={false}
                            callback={successCallback}
                        />
                    }))
                } else if (err?.response?.data?.error === 'similar patient already registered in health_place') {
                    setSecondaryModalInfo(prev => ({
                        ...prev,
                        open: true,
                        content: <PatientExistentHandlerSameHealthPlace 
                            patient_name={err?.response?.data?.patient_name} 
                            patient_id={err?.response?.data?.patient_id}
                            document_type={err?.response?.data?.patient_document_type}
                            document={err?.response?.data?.patient_document}
                            patient_birthday={err?.response?.data?.patient_birthday}
                            patient_mother_name={err?.response?.data?.patient_mother_name}
                            setSecondaryModalInfo={setSecondaryModalInfo}
                            handlePatientSubmission={handlePatientSubmission}
                            inputValues={inputValues}
                            forcedSubmissionAllowed={true}
                            callback={successCallback}
                        />
                    }))
                } else {
                    if (err?.response?.data?.field){
                        setErrors(prevErrors => ({...prevErrors, [err?.response?.data?.field]: {error: true, message: err?.response?.data?.str}}))
                    } else {
                        dispatch(AddAlert('Novo paciente', err?.response?.data?.str || 'Falha ao criar novo paciente, revise os dados', AlertType.ERROR));
                        console.error('addNewBird', err.response.request);
                    }
                }
            }
            dispatch(loaded());
        } else {
            let errors = payload.getErrors();
            setErrors(prev => ({...prev, ...errors}));
        }
    }


    const handleSubmit = async () => {
        handlePatientSubmission(inputValues, errors, (res) => {
            setModalInfo(MODAL_INIT_PROPS);
            history.push(`pacientes/${res?.data?.new_patient_id}/detalhar`)
        });
    };
    
    const handleSubmitAndOpenAddress = async () => {
        handlePatientSubmission(inputValues, errors, (res) => {
            setModalInfo({
                open: true,
                title: 'Endereço do Novo Paciente',
                content: <EditAddress setModalOrigin={setModalInfo} initHoldingPatient={{ id: res?.data?.new_patient_id }} fetchExtraAction={() => history.push(`pacientes/${res?.data?.new_patient_id}/detalhar`)}/>,
                confirmText: ''
            });
        });
    };

    const handleSelect = (event) => {
        setInputValues(prevData => ({ ...prevData, [`${event.target.id}`]: event.target.selected }))

        let error = validateDataBeforeSubmit({...inputValues, [`${event.target.id}`]: event.target.selected}, errors)
        setErrors(error);
    }

    const handleSelectCareer = (event) => {
        let error = validateDataBeforeSubmit(inputValues)
        setErrors(prevData => ({ ...prevData, ...error }));        
        setInputValues(prevData => ({ ...prevData, career: careerOptions?.find(career => career?.id === event?.target?.selected) }))
        fetchCareerOptions({ offset: 0 })
    }

    const handleChange = (event) => {
        setInputValues(prevData => ({ ...prevData, [event.target.name]: event.target.value }))
        let error = validateDataBeforeSubmit({...inputValues, [event.target.name]: event.target.value}, errors)
        setErrors(error);

    }

    const handleSelectRadioButton = (event) => {
        if (event.target.name === 'document_type') {
            setInputValues(prev => ({ ...prev, document: '', [event.target.name]: event?.target?.value?.id }))
        } else {
            setInputValues(prev => ({ ...prev, [event.target.name]: event?.target?.value?.id }))
        }
        let error = validateDataBeforeSubmit({...inputValues, [event.target.name]: event?.target?.value?.id }, errors)
        setErrors(error);
    }

    const fetchCareerOptions = async (params={}) => {
        params = { ...params, limit: 50 }
    
        try {
          let res = await getCareersList(params)
    
          setCareerOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const handleSearchCarrer = (event) => {
        if (searchTimeoutCareer.current) clearTimeout(searchTimeoutCareer.current)
    
        searchTimeoutCareer.current = setTimeout(() => {
            fetchCareerOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }
    
    return <div className='NewPatient'>
        <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)} />
        <div className='NewPatient-PersonalInfo'>
            <div className='NewPatient-Subtitle-Header'>
                <div className='NewPatient-Subtitle-Header-Icon NewPatient-Subtitle-Header-Icon-Blue'>D</div>
                <div className='NewPatient-Subtitle-Header-Title'>Dados Pessoais</div>
            </div>
            <div className='NewPatient-PersonalInfo-NameBirthdayCPFContainer'>
                <div className='NewPatient-Input'>
                    <span><b className='NewPatinet-Asterisk'>*</b> Nome Completo</span>
                    <Input
                        name='name'
                        value={inputValues?.name}
                        maxLength='60'
                        action={handleChange}
                        errors={{
                            error: {name: errors?.name?.error},
                            message: errors?.name?.message,
                          }}
                    />
                </div>
                <div>
                    <span>Mesmo nome social?</span>
                    <RadioButton
						name='is_it_the_same_social_name'
						action={handleSelectRadioButton}
						checked={inputValues?.is_it_the_same_social_name}
						options={defaultOptions.ONLY_YES_OR_NO}
					/>
                </div>
                <div>
                    <span><b className='NewPatinet-Asterisk'>*</b> Data de Nascimento</span>
                    <div className='NewPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect NewPatient-Select'>
                        <NewSelect
                            id='birthday_day'
                            defaultText='Dia'
                            options={defaultOptions.days}
                            onSelect={handleSelect}
                            selected={inputValues?.birthday_day}
                            error={errors?.birthday_day?.error}
                        />
                        <NewSelect
                            id='birthday_month'
                            defaultText='Mês'
                            options={defaultOptions.months}
                            onSelect={handleSelect}
                            selected={inputValues?.birthday_month}
                            error={errors?.birthday_month?.error}
                        />
                        <NewSelect 
                            id='birthday_year'
                            defaultText='Ano'
                            options={getYearsStart1900()}
                            onSelect={handleSelect}
                            selected={inputValues?.birthday_year}
                            error={errors.birthday_year?.error}
                        />
                    </div>
                </div>
                <div>
                    <span>Tipo de Documento</span>
                    <RadioButton
						name='document_type'
						action={handleSelectRadioButton}
						checked={inputValues?.document_type}
						options={defaultOptions.DOCUMENT_TYPE_CHOICES}
					/>
                </div>
                <div className='NewPatient-Input'>
                    {
                        inputValues?.birthday_day && 
                        inputValues?.birthday_month && 
                        inputValues?.birthday_year && 
                        !isOfLegalAge(`${inputValues?.birthday_year}-${inputValues?.birthday_month}-${inputValues?.birthday_day}`)
                        ? (
                            inputValues?.document_type === 'CPF'
                            ? <span>CPF</span>
                            : <span>RG</span>
                        )
                        : (
                            inputValues?.document_type === 'CPF'
                            ? <span><b className='NewPatinet-Asterisk'>*</b> CPF</span>
                            : <span><b className='NewPatinet-Asterisk'>*</b> RG</span>
                        )
                    }
                    <Input 
                        name='document'
                        value={inputValues.document_type === 'RG' ? inputValues?.document : MaskCpf(inputValues?.document)}
                        maxLength={inputValues.document_type === 'RG' ? '20' :'14'}
                        action={handleChange}
                        errors={{
                            error: {document: errors?.document?.error},
                            message: errors?.document?.message,
                          }}                    
                    />
                </div>
            </div>
            <div className={inputValues?.is_it_the_same_social_name === 'NO' ? 'NewPatient-PersonalInfo-SocialNameCNSMotherNameEmailContact' : 'NewPatient-PersonalInfo-CNSMotherNameEmailContact'}>
                {
                    inputValues?.is_it_the_same_social_name === 'NO'
                    ? <div className='NewPatient-Input'>
                        <span>Nome Social</span>
                        <Input
                            name='social_name'
                            maxLength='60'
                            value={inputValues?.social_name}
                            action={handleChange}
                        />
                    </div>
                    : null
                }
                <div className='NewPatient-Input'>
                    <span>Cartão Nac. de Saúde (CNS)</span>
                    <Input
                        name='sus_card_number'
                        value={inputValues?.sus_card_number ? MaskSUSCard(inputValues.sus_card_number) : ''}
                        maxLength='18' 
                        action={handleChange}
                    />
                </div>
                <div className='NewPatient-Input'>
                    <span>Nome da Mãe</span>
                    <Input
                        name='mother_name'
                        value={inputValues?.mother_name}
                        maxLength='60'
                        action={handleChange}
                    />
                </div>
                <div>
                    <span>Tipo de Telefone</span>
                    <RadioButton
						name='phone_type'
						action={handleSelectRadioButton}
						checked={inputValues?.phone_type}
						options={defaultOptions.PHONE_TYPES_CHOICES}
					/>
                </div>
                <div className='NewPatient-Input'>
                    <span>Telefone {defaultOptions?.PHONE_TYPES_CHOICES?.find(instance => instance?.id === inputValues?.phone_type)?.name}</span>
                    <Input
                        name='contact_phone'
                        value={MaskTelefone(inputValues?.contact_phone)}
                        maxLength='15'
                        action={handleChange}
                        errors={{
                            error: {contact_phone: errors?.contact_phone?.error},
                            message: errors?.contact_phone?.message,
                        }}
                    />
                </div>
            </div>
            <div className='NewPatient-PersonalInfo-NationalitySexGenderMaritalStatusGender'>
                <div className='NewPatient-Select'>
                    <span><b className='NewPatinet-Asterisk'>*</b> Nacionalidade</span>
                    <NewSelect
                        id='nationality'
                        options={NATIONALITY_CHOICES}
                        onSelect={handleSelect}
                        selected={inputValues?.nationality}
                        error={errors?.nationality?.error}
                        canBeEmpty={false}
                    />
                </div>
                <div className='NewPatient-Select'>
                    <span><b className='NewPatinet-Asterisk'>*</b> Sexo</span>
                    <NewSelect
                        id='sex'
                        options={SEX_CHOICES}
                        onSelect={handleSelect}
                        selected={inputValues?.sex}
                        error={errors?.sex?.error}
                        canBeEmpty={false}
                    />
                </div>
                <div className='NewPatient-Select'>
                    <span>Gênero</span>
                    <NewSelect 
                        id='gender'
                        options={GENDER_CHOICES}
                        onSelect={handleSelect}
                        selected={inputValues?.gender}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='NewPatient-Select'>
                    <span>Raça</span>
                    <NewSelect 
                        id='race'
                        options={RACES_CHOICES}
                        onSelect={handleSelect}
                        selected={inputValues?.race}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='NewPatient-Select'>
                    <span>Estado Civil</span>
                    <NewSelect 
                        id='marital_status'
                        options={MARITAL_STATUS_CHOICES}
                        onSelect={handleSelect}
                        selected={inputValues?.marital_status}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='NewPatient-Input'>
                    <span>E-mail</span>
                    <Input
                        name='email'
                        value={inputValues?.email}
                        action={handleChange}
                        errors={{
                            error: {email: errors?.email?.error},
                            message: errors?.email?.message,
                          }}                    
                    />
                </div>
            </div>
            {
                inputValues?.nationality === 'FOREIGNER'
                ? <div>
                    <div className='NewPatient-PersonalInfo-NationalityInfo-FirstRow'>
                        <div className='NewPatient-Select'>
                            <span>Data de Naturalização</span>
                            <div className='NewPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect'>
                                <NewSelect
                                    id='passport_naturalization_day'
                                    defaultText='Dia'
                                    options={defaultOptions.days}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_naturalization_day}
                                    error={errors?.passport_naturalization_day?.error}
                                />
                                <NewSelect
                                    id='passport_naturalization_month'
                                    defaultText='Mês'
                                    options={defaultOptions.months}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_naturalization_month}
                                    error={errors?.passport_naturalization_month?.error}
                                />
                                <NewSelect 
                                    id='passport_naturalization_year'
                                    defaultText='Ano'
                                    options={getYearsStart1900()}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_naturalization_year}
                                    error={errors?.passport_naturalization_day_year?.error}
                                />
                            </div>
                        </div>
                        <div className='NewPatient-Select'>
                            <span>País de Nascimento</span>
                            <NewSelect 
                                id='passport_country_of_birth'
                                options={COUNTRY_CHOICES}
                                onSelect={handleSelect}
                                selected={inputValues?.passport_country_of_birth}
                            />
                        </div>
                        <div className='NewPatient-Input'>
                            <span>Nº de Passaporte</span>
                            <Input
                                name='passport_number'
                                value={inputValues?.passport_number}
                                maxLength='15'
                                action={handleChange}
                            />
                        </div>
                    </div>
                    <div className='NewPatient-PersonalInfo-NationalityInfo-SecondRow'>
                        <div className='NewPatient-Select'>
                            <span>País Emissor</span>
                            <NewSelect 
                                id='passport_issuing_country'
                                options={COUNTRY_CHOICES}
                                onSelect={handleSelect}
                                selected={inputValues?.passport_issuing_country}
                            />
                        </div>
                        <div className='NewPatient-Select'>
                            <span>Data de Emissão</span>
                            <div className='NewPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect'>
                                <NewSelect
                                    id='passport_issuance_day'
                                    defaultText='Dia'
                                    options={defaultOptions.days}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_issuance_day}
                                    error={errors?.passport_issuance_day?.error}
                                />
                                <NewSelect
                                    id='passport_issuance_month'
                                    defaultText='Mês'
                                    options={defaultOptions.months}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_issuance_month}
                                    error={errors?.passport_issuance_month?.error}
                                />
                                <NewSelect 
                                    id='passport_issuance_year'
                                    defaultText='Ano'
                                    options={getYearsStart1900()}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_issuance_year}
                                    error={errors?.passport_issuance_year?.error}
                                />
                            </div>
                        </div>
                        <div className='NewPatient-Select'>
                            <span>Data de Validade</span>
                            <div className='NewPatient-PersonalInfo-NameBirthdayCPFContainer-BirthdaySelect'>
                                <NewSelect
                                    id='passport_expiration_day'
                                    defaultText='Dia'
                                    options={defaultOptions.days}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_expiration_day}
                                />
                                <NewSelect
                                    id='passport_expiration_month'
                                    defaultText='Mês'
                                    options={defaultOptions.months}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_expiration_month}
                                />
                                <NewSelect 
                                    id='passport_expiration_year'
                                    defaultText='Ano'
                                    options={getYearsStart1900Plus30()}
                                    onSelect={handleSelect}
                                    selected={inputValues?.passport_expiration_year}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
        <div className='NewPatient-FamilyInfo'>
            <div className='NewPatient-Subtitle-Header'>
                <div className='NewPatient-Subtitle-Header-Icon'>R</div>
                <div className='NewPatient-Subtitle-Header-Title'>Informações do responsável / Contato de Emergência</div>
            </div>
            <div className='NewPatient-FamilyInfo-RowInfo'>
                <div className='NewPatient-Input'>
                {
                        inputValues?.birthday_day && 
                        inputValues?.birthday_month && 
                        inputValues?.birthday_year && 
                        !isOfLegalAge(`${inputValues?.birthday_year}-${inputValues?.birthday_month}-${inputValues?.birthday_day}`)
                        ? <span><b className='NewPatinet-Asterisk'>*</b> Nome Completo</span>
                        : <span>Nome Completo</span>
                    }
                    <Input
                        name='person_responsible_name'
                        value={inputValues?.person_responsible_name}
                        maxLength='60'
                        action={handleChange}
                        errors={{
                            error: {person_responsible_name: errors?.person_responsible_name?.error},
                            message: errors?.person_responsible_name?.message,
                          }}                    
                    />
                </div>
                <div className='NewPatient-Input'>
                    <span>Telefone</span>
                    <Input
                        name='person_responsible_contact_phone'
                        value={MaskTelefone(inputValues?.person_responsible_contact_phone)}
                        maxLength='15'
                        action={handleChange}
                    />
                </div>
                <div className='NewPatient-Select'>
                    <span>Parentesco</span>
                    <NewSelect 
                        id='person_responsible_kinship'
                        options={KINSHIP_CHOICES}
                        onSelect={handleSelect}
                        selected={inputValues?.person_responsible_kinship}
                        defaultText='Sem informação'
                    />
                </div>
                <div className='NewPatient-Input'>
                    {
                        inputValues?.birthday_day && 
                        inputValues?.birthday_month && 
                        inputValues?.birthday_year && 
                        !isOfLegalAge(`${inputValues?.birthday_year}-${inputValues?.birthday_month}-${inputValues?.birthday_day}`)
                        ? <span><b className='NewPatinet-Asterisk'>*</b> CPF do Responsável</span>
                        : <span>CPF do Responsável</span>
                    }
                    <Input 
                        name='person_responsible_cpf'
                        value={MaskCpf(inputValues?.person_responsible_cpf)}
                        maxLength='14'
                        action={handleChange}
                        errors={{
                            error: {person_responsible_cpf: errors?.person_responsible_cpf?.error},
                            message: errors?.person_responsible_cpf?.message,
                          }}                    
                    />
                </div>
            </div>
        </div>

        <div className='NewPatient-Btn-Box'>
            <div>
                <p><span className='NewPatient-Btn-Box-SpanError'><b className='NewPatinet-Asterisk'>*</b> Preenchimento Obrigatório</span></p>
            </div>
            <div className='NewPatient-BtnBox-RightBox'>
                {
                    Object.keys(errors).length > 0 ? 
                    <div>
                        <p><span className='NewPatient-Btn-Box-SpanError'>Para poder salvar, favor corrigir os campos em vermelho!</span></p>
                    </div>
                    : null
                }
                <Button 
                    onClick={handleSubmit}
                    model={ButtonModel.SECONDARY}
                    label='Salvar'
                    disabled={Object.keys(errors).length > 0 ? true : false}
                />
                <Button 
                    onClick={handleSubmitAndOpenAddress}
                    model={ButtonModel.PRIMARY}
                    label='Salvar e cadastrar endereço'
                    disabled={Object.keys(errors).length === 0 ? false : true}
                />
            </div>
        </div>
        <span className='NewPatient-Info-Message'>Obs: Contato, CPF e Endereço são necessários para emissão de receitas digitais.</span>
    </div>
}
