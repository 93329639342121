import './menu.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { history } from '../../helpers'
//import { IcoEstetoscopio } from '../icon/stethoscope'
//import { IcoConfigurar } from '../icon/configurar'
//import { PatientIcon } from '../icon/patient'
//import IcoSchedule from '../icon/schedule'
//import PregnantIcon from '../icon/pregnant'
//import { NotesIcon } from '../icon/notes'
//import { HomeIcon } from '../icon/home'
//import IcoTeam from '../icon/team'

//const menuIcons = {
//  "Home": <HomeIcon style={{ fontSize: '16px' }} />,
//  "Histórico": <IcoEstetoscopio style={{ fontSize: '16px' }} />,
//  "Ajustes": <IcoConfigurar style={{ fontSize: '16px' }}  />,
//  "Paciente": <PatientIcon style={{ width: '20px', fill: 'white' }} />,
//  "Agenda": <IcoSchedule style={{ fontSize: '18px' }} />,
//  "Gestação": <PregnantIcon style={{ fontSize: '28px' }} />,
//  "Preceptoria": <NotesIcon style={{ fontSize: '16px' }} />,
//  "Equipe": <IcoTeam style={{ fontSize: '16px' }} />,
//}

export function Menu({ children, data, action }) {
  const dispatch = useDispatch()
  const location = useLocation()

  const isActive = (address) => {
    if (address.includes('/atendimentos')) {
      return location.pathname.includes('/atendimentos') || (location.pathname.includes('consultation') && !location.pathname.includes('preceptoria/consultation'))
    }
    return location.pathname.includes(address)
  } 

  const actionMenu = (e) => {
    action ? action(e) : history.push(e.go)
  }

  return (
    <div className='Menu'>
      {data && data.length
        ? data.map(item => {

            if (item.label === 'Finanças' && window.location.hostname.includes("app.salutho")) return null

            return (
              <div key={item.id} className={`${isActive(item.go) ? 'MenuActive' : ''}`} onClick={() => {
                actionMenu(item)
              }}>
                <h5>{item.label}</h5>
              </div>
            )
          })
        : null}

      {children}
    </div>
  )
}
