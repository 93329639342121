import React, { useEffect, useState } from 'react'
import Loading from '../../newcomponents/loading/loading'
import { Input } from '../../component'
import { getBRLCurrency } from '../../helpers/numbers'
import FormButtons from './form-buttons'

export default function CostsForm({ cost, index, onSave, onClose }) {

    const [localCost, setLocalCost] = useState(cost)

    useEffect(() => {
        init()

    }, [cost])

    const init = () => {
        setLocalCost(cost ? cost : {type: '', cost: 0})
    }

    const getTitle = () => `${cost ? 'Editar' : 'Adicionar'} tipo de cobrança`

    const handleCost = ({ target }) => {
        const value =  target.value.replaceAll(/[RS$,.]/g, '')

        if (isNaN(value)) return;

        setLocalCost({...localCost, cost: Number(value)})
    }


    if (!localCost) return <Loading></Loading> 

    return <div className='costs-form'>

        <div className='subtitle'>{getTitle()}</div>

        <form onSubmit={e => e.preventDefault()}>
            <Input
                label='Tipo'
                value={localCost.type}
                action={e => setLocalCost({...localCost, type: e.target.value})}
            ></Input>
            <Input
                label='Valor'
                value={getBRLCurrency(localCost.cost)}
                action={handleCost}
            ></Input>

            <FormButtons
                onClose={onClose}
                onSave={() => onSave(localCost, index)}
                saveText={cost ? 'Editar' : 'Adicionar'}
            ></FormButtons>
        </form>
    </div>

}