import './ChronicConditionChart.css'
import React from "react";
import {
    Chart,
    LinearScale,
    CategoryScale,
    LineController,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { MaskDataHora } from '../../../../../../../../../../component';

Chart.register(
    LinearScale,
    CategoryScale,
    LineController,
    PointElement,
    LineElement,
    Title
);

export default function ChronicConditionChart({ condition, data, setModalInfo=() => null }) {
    const categoryMapping = {
      "NOT_EVALUATED": 0,
      "NO": 1,
      "YES": 2,
    };
  
    const reverseCategoryMapping = {
      0: "Não avaliado",
      1: "Não",
      2: "Sim",
    };

    const labels = data.map(entry => MaskDataHora(entry?.date)?.data || entry?.date);
    const numericData = data.map(entry => categoryMapping[entry.value]);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: condition.label,
          data: numericData,
          fill: false,
          borderColor: condition.color,
          tension: 0.1,
          pointBackgroundColor: numericData.map(value => {
            if (value === 2) return 'green'; // Sim
            if (value === 1) return 'red';   // Não
            if (value === 0) return 'gray';  // Não avaliado
            return 'transparent';            // Valor nulo
          }),
          spanGaps: true, // Ignora gaps nos dados
        },
      ],
    };
  
    const options = {
      responsive: true,
      scales: {
        y: {
          ticks: {
            stepSize: 1,
            callback: function(value) {
              return reverseCategoryMapping[value];
            },
          },
          title: {
            display: true,
            text: condition.label,
          },
          min: 0,
          max: 2,
        },
        x: {
          title: {
            display: true,
            text: 'Data',
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              const value = context.parsed.y;
              return `${condition.label}: ${reverseCategoryMapping[value]}`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
    };
    
    const handleShowDetailsModal = (chartConfig) => {
      setModalInfo((prev) => ({
        ...prev,
        open: true,
        title: 'Mostrar Detalhes',
        content: <div className='ChronicConditionChart-Modal'>
          <Line data={chartConfig} options={options} />
      </div>,
      }));
    };

    return (
      <div className='ChronicConditionChart-Grid-Chart-Container'
        onClick={() => handleShowDetailsModal(chartData)}
      >
        <div className='ChronicConditionChart-ChartOverlay'>
          <p>Mostrar Detalhes</p>
        </div>
        <div className='ChronicConditionChart'>
          <Line data={chartData} options={options} />
        </div>
      </div>

    );
}
