import './DynamicRefraction.css'
import React from 'react'
import { Input, maskSnellenChart } from '../../../../../../../../../../component'
import { handleAxis, handleCDegree, handleSignal } from '../helpers'


export default function DynamicRefraction({
    handleChange, 
    holdingOphthalmology, 
    holdingOphthalmologyErr,
    isEditable=true,
}) {
    return <div>
        <p><b>Refração Dinâmica</b></p>
        <div className='DynamicRefraction-Header'>
            <b></b>
            <span>OD</span>
            <span>OE</span>
        </div>
        <div className='DynamicRefraction-Grid'>
            <span>Grau E</span>
            <Input
                name='right_eye_dynamic_refraction_e_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleSignal(holdingOphthalmology?.right_eye_dynamic_refraction_e_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_dynamic_refraction_e_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleSignal(holdingOphthalmology?.left_eye_dynamic_refraction_e_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <span>Grau C</span>
            <Input
                name='right_eye_dynamic_refraction_c_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleCDegree(holdingOphthalmology?.right_eye_dynamic_refraction_c_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_dynamic_refraction_c_degree'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleCDegree(holdingOphthalmology?.left_eye_dynamic_refraction_c_degree)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <span>Eixo</span>
            <Input
                name='right_eye_dynamic_refraction_axis'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleAxis(holdingOphthalmology?.right_eye_dynamic_refraction_axis)}
                type='Number'
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_dynamic_refraction_axis'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={handleAxis(holdingOphthalmology?.left_eye_dynamic_refraction_axis)}
                type='Number'
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <span>Visão</span>
            <Input
                name='right_eye_dynamic_refraction_vision'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.right_eye_dynamic_refraction_vision)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
            <Input
                name='left_eye_dynamic_refraction_vision'
                action={event => handleChange(event)}
                actionBlur={event => handleChange(event)}
                value={maskSnellenChart(holdingOphthalmology?.left_eye_dynamic_refraction_vision)}
                errors={{error: holdingOphthalmologyErr, message: 'Campo inválido'}}
                disabled={!isEditable}
            />
        </div>
    </div>
}