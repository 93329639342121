import './CreateGenericCouncil.css'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, Button, IcoBin, IcoPencil, Input, List } from '../../../../../component'
import NewSelect from '../../../../../newcomponents/form/select/select'
import NewButton, { ButtonModel } from '../../../../../newcomponents/button/button'
import api from '../../../../../helpers/api'
import { loaded, loading } from '../../../../../layout/redux/AppActions'
import { useApp } from '../../../../../layout/App'
import { strpCouncilData } from '../../../../configuration/subcomponents/HealthProfessionalConfig/PersonalDataMenu/subcomponents/PersonalDataCouncilManager/helpers'
import { getSpecialties } from '../../../../agenda/SelectScheduleHealthPlaceUserManager/http'
import { uniqueIDs } from '../../../../../utils/uniqueIDs'
import NewMultiSelect from '../../../../../newcomponents/form/select/multiselect'

const councilHead = [
    { colunm: 'h_nu_crm', text: 'Número' },
    { colunm: 'h_uf_crm', text: 'UF' },
    { colunm: 'h_associated_specialties', text: 'Especialidades' },
    { colunm: 'h_actions', text: '' }
]

export default function CreateGenericCouncil() {
    const dispatch = useDispatch()
    const searchTimeOut = useRef(null)
    const { listas } = useSelector(state => state.appState)
    const [councils, setCouncils] = useState([])
    const [holdingCouncil, setHoldingCouncil] = useState(strpCouncilData())
    const [holdingCouncilErr, setHoldingCouncilErr] = useState({})
    const { currentHealthPlaceUser, fetchUserCouncils, loged } = useApp()
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])

    useEffect(() => {
        fetchSpecialtiesOptions()
    }, [])

    const handleSearchSpecialties = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    let profile = currentHealthPlaceUser?.profile

    const councilNames = {
        'DENTIST': 'CRO',
        'PSYCHOLOGIST': 'CRP',
        'NUTRITIONIST': 'CRN',
        'NURSE': 'COREN',
        'DOCTOR': 'CRM'
    }

    const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    let selected_council_by_profile = councilNames[profile]

    const handleChange = (event) => {
        setHoldingCouncilErr({})
        setHoldingCouncil(prev => ({
            ...prev,
            [event.target.name]: event.target.value[0] === 't' ? event.target.value : event.target.value.replace(/\D/g, '')
        }))
    }

    const handleSelect = (event) => {
        setHoldingCouncilErr({})
        setHoldingCouncil(prev => ({ ...prev, [event.target.id]: event.target.selected }))
    }

    const handleAddCouncilData = () => {
        let errors = {}
        if (!holdingCouncil.nu_crm) errors['nu_crm'] = 'Inscrição obrigatória'
        if (!holdingCouncil.uf_crm) errors['uf_crm'] = 'UF obrigatória'
        if (Object.keys(errors).length) return setHoldingCouncilErr(errors)
        
        let payload = {
            ...holdingCouncil, 
            "council": selected_council_by_profile,
            "associated_specialties": holdingCouncil?.associated_specialties,
        }
        setCouncils(prev => [...prev.filter(instance => instance.uf_crm !== holdingCouncil.uf_crm), payload])
        setHoldingCouncil(strpCouncilData())
    }

    const handleSaveCouncil = async () => {
        const payload = councils.map(council => {
            delete council.h_actions
            council['associated_specialties'] = council.associated_specialties.map(instance => instance?.id)
            return council
        })
        dispatch(loading())
        try {
            await api.post('/health_place_user/registration/user/create_councils/', { councils: payload  })
            dispatch([
                loaded(),
                AddAlert(`Cadastro de ${selected_council_by_profile}`, `${selected_council_by_profile} salvo com sucesso`, AlertType.SUCCESS)
            ])
            await loged()
            fetchUserCouncils()
        } catch (err) {
            console.error(err)
            dispatch([
                loaded(),
                AddAlert(`Cadastro de ${selected_council_by_profile}`, `Falha ao salvar ${selected_council_by_profile}, tente novamente!`, AlertType.ERROR)
            ])
        }
    }

    const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = holdingCouncil?.[loadKey].find(option => option[fieldNameRef] === optionRef);
		
		let nextState = holdingCouncil
		if (isExisting) {
			nextState = { ...holdingCouncil, [loadKey]: holdingCouncil[loadKey].filter(option => option[fieldNameRef] !== optionRef) }
		} else {
			nextState = { ...holdingCouncil, [loadKey]: [...holdingCouncil[loadKey], selectedOption] }
		}
		setHoldingCouncil(nextState)

		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchSpecialtiesOptions({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('toggleOption', err)
		}
	}

    return (
    <div>
        <div className='CreateGenericCouncil-CROCol'>
            <Input
                label='Inscrição'
                name='nu_crm'
                action={handleChange}
                value={holdingCouncil.nu_crm}
                maxLength='8'
                required={{ erro: holdingCouncilErr, message: holdingCouncilErr.nu_crm }}
                placeholder='ex: 123456'
            />
            <NewSelect
                id='uf_crm'
                options={listas.estados}
                canBeEmpty={false}
                label='UF'
                optionRefKey='sigla'
                optionStrKey='nome'
                selected={holdingCouncil.uf_crm}
                onSelect={handleSelect}
                error={holdingCouncilErr?.uf_crm}
            />
        </div>
        <NewMultiSelect
			label='Especialidades'
			filterNode={
				<div className='CreateGenericCouncil-NewSelect'>
					<Input
						placeholder='Pesquisar por nome'
						action={handleSearchSpecialties}
						actionFocus={() => fetchSpecialtiesOptions({ offset: 0 })}
					/>
				</div>
			}
			defaultText={holdingCouncil.associated_specialties?.length ? `${holdingCouncil.associated_specialties.length} selecionadas` : 'Selecione'}
			onlyDefaultText
			onSelect={({ optionRef }) => {
				toggleOption('associated_specialties', specialtiesOptions, 'id', optionRef)
			}}
			selectedOptions={holdingCouncil.associated_specialties?.map(specialty => specialty.id)}
			optionRefKey='id'
			optionStrKey='strf'
			options={uniqueIDs(holdingCouncil.associated_specialties, specialtiesOptions)}
		/>
        <div className='CreateGenericCouncil-BtnFullWidth'>
            <NewButton
                label={`Adicionar ${councilNames[profile]}`}
                model={ButtonModel.SECONDARY}
                onClick={handleAddCouncilData}
            />
        </div>
        <p className='CreateGenericCouncil-Subtitle CreateGenericCouncil-mt'>{councilNames[profile]} cadastrados</p>
        <List
            head={councilHead}
            data={councils.map(instance => ({ ...instance }))}
            noData={''}
            listCustom={(council) => {
                const clear_council = { ...council }
                const custom = council
                console.log(council)
                custom['h_nu_crm'] = council.nu_crm
                custom['h_uf_crm'] = council.uf_crm
                custom['h_associated_specialties'] = council.associated_specialties.map(instance => instance?.name).join(', ')
                custom['h_actions'] = <div className='CreateGenericCouncil-ActionRow'>
                    <div
                        title='Editar'
                        onClick={() => {
                            setCouncils(prev => prev.filter(instance => instance.nu_crm !== council.nu_crm && instance.uf_crm !== council.uf_crm))
                            setHoldingCouncil(clear_council)
                        }}
                    >
                        <IcoPencil />
                    </div>
                    <div
                        title='Remover'
                        onClick={() => setCouncils(prev => prev.filter(instance => instance.nu_crm !== council.nu_crm && instance.uf_crm !== council.uf_crm))}
                    >
                        <IcoBin />
                    </div>
                </div>
                return custom
            }}
        />
        <div className='CreateGenericCouncil-SaveCouncil'>
            <NewButton
                label={`Salvar ${councilNames[profile]}'s`}
                disabled={!councils.length}
                onClick={handleSaveCouncil}
            />
        </div>
    </div>
    )
}
