import './ChatWindow.css'
import { useEffect, useRef, useState } from 'react'
import CancelIcon from '../../../../component/icon/cancel'
import { getLastMessageRead, getUserChats } from '../../http'
import ChatListItem from './ChatListItem/ChatListItem'
import { IcoAdd } from '../../../../component'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import NewChatModal from './NewChatModal/NewChatModal'
import Conversation from './Conversation/Conversation'
import { useApp } from '../../../../layout/App'

export default function ChatWindow ({isOpen, setIsOpen}) {
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const [chats, setChats] = useState([])
    const [chatSelected, setChatSelected] = useState(null)
    const [lastReadMessages, setLastReadMessages] = useState([])
    const { currentHealthPlaceUser } = useApp()
    const pendingNotificationQueue = useRef([])
    const reconnectInterval = useRef(null)
    const intentionalDisconnect = useRef(false)
    const ws = useRef(null)

    useEffect( async () => {
        if (isOpen) {
            getChatsByUser()
            getLastMessageReadByUser()
        }
    }, [isOpen])

    useEffect(() => {

        const connectWebSocket = () => {

            if (ws.current && ws.current.readyState === WebSocket.OPEN) return;

            const wsScheme = process.env.NODE_ENV === "production" ? "wss" : "ws";
            const wsHost = process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
              ? process.env.REACT_APP_SALUTHO_WEBSOCKET_HOST
              : window.location.hostname;

            let pingInterval;
            
            if (ws.current) {
              ws.current.close();
            }
            
            if (localStorage.token) {
    
            ws.current = new WebSocket(`${wsScheme}://${wsHost}/ws/chat/notification/?authorization=Token ${localStorage.token}`)
        
            ws.current.onopen = () => {
                console.log('WebSocket connected')
    
                pingInterval = setInterval(() => {
                    if (ws.current.readyState === WebSocket.OPEN) {
                        ws.current.send(JSON.stringify({ type: 'ping' }))
                    }
                }, 10000)
    
                while (pendingNotificationQueue.current.length > 0) {
                    const notification = pendingNotificationQueue.current.shift()
                    ws.current.send(JSON.stringify(notification))
                }
            }
            
            ws.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(data)
    
                if (data.type === "send_notification") {
                    setLastReadMessages((prev) => {
                        const chatIndex = prev.findIndex((info) => info.chat_id === data.chat_id);
                        
                        const updatedInfo = [...prev]

                        if (chatIndex >= 0) {
                            updatedInfo[chatIndex] = {
                                ...prev[chatIndex],
                                unread_count: data.unread_count,
                            };
                        } else {
                            updatedInfo.push({
                                chat_id: data.chat_id,
                                unread_count: data.unread_count,
                                last_read_message: data.last_read_message
                            });
                        }

                        return updatedInfo
                    })
                } else if (data.type === 'pong') {
                    console.log('Received pong - ChatNotification')
                }
            }
    
            ws.current.onerror = (error) => {
                console.error('WebSocket error:', error)
                clearInterval(pingInterval)
            }
        
            ws.current.onclose = () => {
                console.log('WebSocket disconnected, reconnecting...')
                clearInterval(pingInterval)

                if (!intentionalDisconnect.current) {
                    reconnectInterval.current = setTimeout(connectWebSocket, 5000);
                }
            }
            }
        }

        if (isOpen) {
            connectWebSocket()
        }

        return () => {
            if (ws.current) {
                ws.current.close();
            }
            if (reconnectInterval.current) {
                clearTimeout(reconnectInterval.current);
            }
        };
    }, [isOpen])

    const disconnectUserStatusWebSocket = () => {
        intentionalDisconnect.current = true
        if (ws.current) {
          ws.current.close()
            ws.current = null
        }
      }

    const sendNotification = (notification) => {
        const payload = {
            ...notification,
            type: "send_notification",
        }

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(payload));
        } else {
            pendingNotificationQueue.current.push(payload);
        }
    }

    const getChatsByUser = async () => {
        try {
            const res = await getUserChats()
            setChats(res.data)
        } catch (error) {
            console.error("getChatsByUser ~", error);
        }
    }

    const getLastMessageReadByUser = async () => {
        try {
            const res = await getLastMessageRead(currentHealthPlaceUser.user.id)
            setLastReadMessages(res.data)
            console.log(res.data)
        } catch (error) {
            console.error("getLastMessageRead ~", error);
        }
    }

    const updateLastMessageRead = (chatId, updatedInfo) => {
        setLastReadMessages(prevMessages => {
            const chatIndex = lastReadMessages.findIndex(info => info.chat_id === chatId);
    
            const updatedMessages = [...prevMessages];
                updatedMessages[chatIndex] = {
                    ...prevMessages[chatIndex],
                    ...updatedInfo,
                    unread_count: 0,
                };

            return updatedMessages
        })
    }

    const handleAddNewChat = () => {
        setModalInfo({
            open: true,
            title: 'Novo Conversa',
            content: <NewChatModal setModalInfo={setModalInfo} getChatsByUser={getChatsByUser}/>,
        })
    }

    const handleSelectChat = ( chat=null ) => {
        if (!chat) setChatSelected(null)

        if (!chatSelected) {
            setChatSelected(chat)
        } else {
            if (chatSelected.id === chat?.id) {
                setChatSelected(null)
            } else {
                setChatSelected(chat)
            }
        }
    }

    const handleCloseModal = () => {
        setIsOpen(false)
        disconnectUserStatusWebSocket()
    }

    return (
        <div className={`ChatWindow ${isOpen ? 'open' : 'close'}`}>
            <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />

            <div className='ChatWindow-CloseBtn-Box'>
                <button className='ChatWindow-CloseBtn' onClick={handleCloseModal}>
                    <CancelIcon width={20} height={20} color={'black'}/>
                </button>
            </div>

            <div className='ChatWindow-Title-Div'>
                <div className='ChatWindow-AddBtn' onClick={handleAddNewChat}>
                    <IcoAdd /> 
                </div>
                <h1 className='ChatWindow-Title'>Suas Conversas</h1>
            </div>
            <div className='ChatList'>
                {chats.length >  0
                ? chats?.map(chat => {
                    const lastReadMessage = lastReadMessages?.find(item => item.chat_id === chat.id)

                    return <div key={chat.id} onClick={() => handleSelectChat(chat)}> <ChatListItem chat={chat} lastReadMessage={lastReadMessage}/> </div>
                })
                : <p className='ChatLis-NoMessages'>Sem conversas para exibir</p>
                }
            </div>

            {chatSelected && (
                <Conversation 
                    chat={chatSelected}
                    handleSelectChat={handleSelectChat}
                    messagesInfo={lastReadMessages.find(item => item.chat_id === chatSelected.id)} 
                    updateLastMessageRead={updateLastMessageRead}
                    sendNotification={sendNotification}
                />  
            )}
        </div>
    )
}