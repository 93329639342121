import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../button/button'
import { adicionaZero } from '../form/Mask'
import { IcoArrowBack } from '../icon/arrow-back'
import { IcoArrowForward } from '../icon/arrow-forward'
import './calendario.css'
import { getDayClassName } from './helpers'
import NewButton, { ButtonModel } from '../../newcomponents/button/button'
import Loading from '../../newcomponents/loading/loading'

const dataInfo = {
  nomesDias: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  numeroSemanas: [1, 2, 3, 4, 5, 6],
  numeroDiasSemana: [1, 2, 3, 4, 5, 6, 7],
  nomesMeses: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  anos: () => {
    const anos = [];
    for (let ano = 1900; ano <= 2100; ano++) {
      anos.push(ano);
    }
    return anos;
  }
}

export function Calendar({ name, calendarCursor, loading = false, setCalendarCursor = () => null, renderNextSelectedDay = () => null, renderNextSelectedMonth = () => null, value = {} }) {
  let selectedDateCursorRef = useRef(new Date())
  const [calendarioMesAno, setcalendarioMesAno] = useState(false)

  useEffect(() => {
    selectedDateCursorRef.current.setMonth(calendarCursor.monthSearchCursor)
    selectedDateCursorRef.current.setFullYear(calendarCursor.yearSearchCursor)
    selectedDateCursorRef.current.setDate(1)
    selectedDateCursorRef.current.setDate(1 - selectedDateCursorRef.current.getDay())
  }, [])

  useEffect(() => {
    statusbtn(value)
  }, [value])

  const statusbtn = e => {
    const ver = Object.keys(e)
    const filtroano = ver.filter(fa => Number(fa) === calendarCursor.yearSearchCursor)
    if (filtroano.length) {
      const verMes = Object.keys(e[Number(calendarCursor.yearSearchCursor)])
      const filtroMes = verMes.filter(fm => Number(fm) === (calendarCursor.monthSearchCursor + 1))
      if (filtroMes.length) {
        Object.keys(e[filtroano[0]][filtroMes[0]]).map(d => {
          const jsondoDia = e[filtroano[0]][filtroMes[0]][d]
          const day = `${name}-${adicionaZero(d)}-${adicionaZero(calendarCursor.monthSearchCursor + 1)}-${calendarCursor.yearSearchCursor}`
          const item = document.getElementById(day)
          if (item) {
            if (jsondoDia.events === 0) {
              item.className = ''
            } else if (jsondoDia.events && jsondoDia.events === jsondoDia.ocupado) {
              item.className = 'ocupado'
            } else if (jsondoDia.events && jsondoDia.events === jsondoDia.indisponivel) {
              item.className = 'fechado'
            } else if (jsondoDia.events) {
              item.className = 'disponivel'
            }
          }
        })
      }
    }
  }

  const selectDay = (newSelectedDay) => {
    setCalendarCursor((prevCursor) => ({ ...prevCursor, selectedDay: newSelectedDay }));
  };

  const mudarMes = (tipo) => {
    let mesNovo = tipo === '<' ? calendarCursor.monthSearchCursor - 1 : tipo === '>' ? calendarCursor.monthSearchCursor + 1 : tipo
    let anoNovo = calendarCursor.yearSearchCursor
    if (calendarCursor.monthSearchCursor === 0 && tipo === '<') {
      mesNovo = 11
      anoNovo = calendarCursor.yearSearchCursor - 1
    } else if (calendarCursor.monthSearchCursor === 11 && tipo === '>') {
      mesNovo = 0
      anoNovo = calendarCursor.yearSearchCursor + 1
    }

    selectedDateCursorRef.current.setFullYear(anoNovo, mesNovo, 1)
    selectedDateCursorRef.current.setDate(1 - selectedDateCursorRef.current.getDay())

    setCalendarCursor({ ...calendarCursor, monthSearchCursor: mesNovo, yearSearchCursor: anoNovo })
    renderNextSelectedMonth({ monthSearchCursor: mesNovo + 1, yearSearchCursor: anoNovo })
  }

  const loadYearCursor = (newYear) => {

    selectedDateCursorRef.current.setFullYear(newYear, calendarCursor.monthSearchCursor, 1)
    selectedDateCursorRef.current.setDate(1 - selectedDateCursorRef.current.getDay())

    setCalendarCursor({ ...calendarCursor, yearSearchCursor: newYear })
  }
  console.log()
  if (loading) {
    return <div className='box-calendario-loading'>
      <Loading />
    </div>
  }

  return (
    <div className='box-calendario'>
      {calendarioMesAno ? <div><table>
        <thead>
          <tr>
            <th colSpan='7'>
              <div className='Calendar-Header'>
                <b
                  className={`Calendar-Header-Btn ${calendarioMesAno ? 'active' : ''}`}
                  onClick={() => setcalendarioMesAno(false)}
                >
                  {dataInfo.nomesMeses[calendarCursor.monthSearchCursor] + ' ' + calendarCursor.yearSearchCursor} <IcoArrowBack />
                </b>
              </div>
            </th>
          </tr>
        </thead>
      </table>
        <div className='Calendar-YearGrid'>
          {dataInfo.anos().map((nA, i) => {
            return <div
              className={nA === calendarCursor.yearSearchCursor ? 'active' : ''}
              key={i}
              onClick={() => {
                loadYearCursor(nA)
                setcalendarioMesAno(false)
              }}
            >
              {nA}
            </div>
          })}
        </div>
      </div>
        :
        <table className='box-calendario-table'>
          <thead>
            <tr>
              <th colSpan='7'>
                <div className='Calendar-Header'>
                  <b
                    className={`Calendar-Header-Btn ${calendarioMesAno ? 'active' : ''}`}
                    onClick={() => setcalendarioMesAno(true)}
                  >
                    {dataInfo.nomesMeses[calendarCursor.monthSearchCursor] + ' ' + calendarCursor.yearSearchCursor} <IcoArrowBack />
                  </b>
                  <div className='Calendar-Header-BtnBox'>
                    <div onClick={() => mudarMes('<')}>
                      <IcoArrowBack />
                    </div>
                    <div onClick={() => mudarMes('>')}>
                      <IcoArrowForward />
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              {dataInfo.nomesDias.map((dia, i) => {
                return <td key={`dia-semana-${dia}`}> {dia} </td>
              })}
            </tr>
          </thead>
          <tbody>
            <CalendarDaysGrid
              name={name}
              calendarCursor={calendarCursor}
              selectedDateCursorRef={selectedDateCursorRef}
              renderNextSelectedDay={renderNextSelectedDay}
              selectDay={selectDay}
            />
          </tbody>
        </table>
      }
    </div>
  )
}

export function CalendarDaysGrid({ name, calendarCursor, renderNextSelectedDay, selectDay }) {
  const iteratorSelectedDateCursor = new Date(`${calendarCursor.yearSearchCursor}-${adicionaZero(calendarCursor.monthSearchCursor + 1)}-15`)
  iteratorSelectedDateCursor.setDate(1)
  iteratorSelectedDateCursor.setDate(1 - iteratorSelectedDateCursor.getDay())

  return (<>
    {dataInfo.numeroSemanas.map(s => {
      return <tr key={`semana-${s}`}>
        {
          dataInfo.numeroDiasSemana.map((d) => {
            const dayInteratorCursor = iteratorSelectedDateCursor.getDate();
            const monthInteratorCursor = iteratorSelectedDateCursor.getMonth();
            const selectedYear = iteratorSelectedDateCursor.getFullYear();

            const cellDate = new Date(selectedYear, monthInteratorCursor, dayInteratorCursor);

            iteratorSelectedDateCursor.setDate(dayInteratorCursor + 1);

            return (
              <td
                key={`dia-${dayInteratorCursor}`}
                className={getDayClassName(cellDate, calendarCursor)}
              >
                <button
                  onClick={() => {
                    renderNextSelectedDay({
                      yearSearchCursor: calendarCursor.yearSearchCursor,
                      monthSearchCursor: adicionaZero(monthInteratorCursor + 1),
                      daySearchCursor: adicionaZero(dayInteratorCursor),
                      name: name,
                    })
                    selectDay(new Date(selectedYear, monthInteratorCursor, dayInteratorCursor))
                  }}
                  title={`${adicionaZero(dayInteratorCursor)} de ${dataInfo.nomesMeses[monthInteratorCursor]} de ${calendarCursor.yearSearchCursor}`}
                  id={`${name}-${adicionaZero(dayInteratorCursor)}-${adicionaZero(monthInteratorCursor + 1)}-${calendarCursor.yearSearchCursor}`}
                >
                  {dayInteratorCursor}
                </button>
              </td>
            )
          })
        }
      </tr>
    })}
  </>)
}